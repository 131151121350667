import { ReactElement } from "react"

import Tooltip from "@mui/material/Tooltip"
import Chip from "@mui/material/Chip"

import { GmtUser } from "server/model"

export default function UserChip({ user }: { readonly user: GmtUser }): ReactElement {
  const { name, mail } = user
  return (
    <Tooltip title={mail}>
      <Chip label={name} variant="outlined" />
    </Tooltip>
  )
}
