import { Dispatch, Fragment, ReactElement, SetStateAction, useCallback, useEffect, useState } from "react"
import { useConfirm } from "material-ui-confirm"

import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import LoadingButton from "@mui/lab/LoadingButton"

import CheckIcon from "@mui/icons-material/Check"
import RemoveDoneIcon from "@mui/icons-material/RemoveDone"

import { createPromiseTerminator, IErrors, useStore } from "core"
import { GmtCompetition, GmtWinnermail, ICompetitionData } from "server/model"
import { validateCompetitionDataForActivation, validateCompetitionForActivation } from "server/logic/competitions"
import ErrorsDialog, { useErrorDialog } from "../../../components/shared/ErrorsDialog"
import { useMessages } from "../../../components/LayoutContext"
import format from "date-fns/format"
import de from "date-fns/locale/de"
import { isPast } from "date-fns"
import DetailsTable from "../../../components/shared/DetailsTable"
import { getPriceLabel } from "../../../components/shared/WinChip"
import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"

export default function CompetitionButtons({
  comp,
  setErrors,
}: {
  readonly comp: GmtCompetition
  readonly setErrors: Dispatch<SetStateAction<IErrors<ICompetitionData> | undefined>>
}): ReactElement | null {
  const isCreated = useStore(comp.baseStore, (state) => !state.gameStatus || state.gameStatus === "Entwurf")
  const isActive = useStore(comp.baseStore, (state) => state.gameStatus === "Aktiv")
  const [activating, setActivating] = useState(false)
  const setMessage = useMessages()
  const confirm = useConfirm()

  const { handleOpen, dialogProps, setDialogErrors } = useErrorDialog()

  const onActivate = useCallback(async () => {
    setActivating(true)
    const validation = validateCompetitionForActivation(comp)
    setDialogErrors(validation)
    if (validation.length) {
      handleOpen()
      setActivating(false)
      setErrors(validateCompetitionDataForActivation(comp.dataStore.state))
    } else {
      setErrors(undefined)
      try {
        if (
          await confirm({
            title: "Gewinnspiel aktivieren?",
            content: <ConfirmationContent comp={comp} />,
            cancellationText: "Abbrechen",
            confirmationText: "Aktivieren",
          }).then(
            () => true,
            () => false
          )
        ) {
          comp.dataStore.update({ gameStatus: { $set: "Aktiv" } })
          await comp.save()
          setMessage({
            message: "Gewinnspiel wurde erfolgreich aktiviert",
            severity: "success",
          })
        }
        setActivating(false)
      } catch (error) {
        setActivating(false)
        comp.dataStore.update({ gameStatus: { $set: "Entwurf" } })
        setMessage({
          message: "Beim Aktivieren des Gewinnspieles ist ein Fehler aufgetreten",
          severity: "error",
        })
        console.error(error)
      }
    }
  }, [comp, confirm, handleOpen, setDialogErrors, setErrors, setMessage])

  const onDeactivate = useCallback(async () => {
    const { participationStartDate } = comp.dataStore.state
    if (participationStartDate && isPast(participationStartDate)) return
    setActivating(true)
    try {
      comp.dataStore.update({ gameStatus: { $set: "Entwurf" } })
      await comp.save()
      setActivating(false)
    } catch (error) {
      setActivating(false)
      comp.dataStore.update({ gameStatus: { $set: "Aktiv" } })
      setMessage({
        message: "Beim Deaktivieren des Gewinnspieles ist ein Fehler aufgetreten",
        severity: "error",
      })
      console.error(error)
    }
  }, [comp, setMessage])

  return isCreated ? (
    <Fragment>
      <Stack direction="row" spacing={3}>
        <Box sx={{ flexGrow: 1 }} />
        <LoadingButton
          variant="contained"
          onClick={onActivate}
          loading={activating}
          loadingPosition="start"
          startIcon={<CheckIcon />}
        >
          Aktivieren
        </LoadingButton>
      </Stack>
      <ErrorsDialog
        {...dialogProps}
        title={"Aktivierung nicht möglich"}
        description={"Die Aktivierung ist aus folgenden Gründen nicht möglich:"}
      />
    </Fragment>
  ) : isActive ? (
    <Stack direction="row" spacing={3}>
      <Box sx={{ flexGrow: 1 }} />
      <LoadingButton
        variant="outlined"
        color="warning"
        onClick={onDeactivate}
        loading={activating}
        loadingPosition="start"
        startIcon={<RemoveDoneIcon />}
      >
        Deaktivieren
      </LoadingButton>
    </Stack>
  ) : null
}

function ConfirmationContent({ comp }: { readonly comp: GmtCompetition }): ReactElement {
  const data = useStore(comp.dataStore)
  const [winnerMail, setWinnerMail] = useState("...")

  useEffect(() => {
    return createPromiseTerminator(GmtWinnermail.first(), (result) => {
      setWinnerMail(result.getData()?.name.join(", ") || "k.A.")
    })
  }, [])

  return (
    <Fragment>
      <span>Hiermit bestätige ich die Richtigkeit der Angaben.</span>
      <br />
      <br />
      <TableContainer component={Paper}>
        <DetailsTable
          small
          data={[
            { title: "Titel", value: data.title },
            {
              title: "Zeitraum",
              value:
                (data.participationStartDate ? format(data.participationStartDate, "P, p", { locale: de }) : "k.A.") +
                " - " +
                (data.participationEndDate ? format(data.participationEndDate, "P, p", { locale: de }) : "k.A."),
            },
            { title: "Gewinnermail", value: winnerMail },
            ...(data.wins || []).map((win, idx) => ({
              title: win.dataStore.state.title || "Gewinn " + idx,
              value: win.dataStore.state.prices?.map(getPriceLabel).join(" + "),
            })),
            ...(data.notes ? [{ title: "Notizen", value: data.notes }] : []),
          ]}
        />
      </TableContainer>
    </Fragment>
  )
}
