import { GmtProject } from "server/model"
import { ReactElement } from "react"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Toolbar from "@mui/material/Toolbar"
import { useStateStore } from "../../../core"
import { IFetchCompetitionsFilter } from "../../../server/logic/competitions"
import CompetitionsList from "../competitions/CompetitionsList"

export default function ProjectCompetitionsPaper({ project }: { readonly project: GmtProject }): ReactElement {
  const [filter] = useStateStore<IFetchCompetitionsFilter>({
    text: "",
    minStart: null,
    maxStart: null,
    minEnd: null,
    maxEnd: null,
    project: project,
  })
  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Gewinnspiele
        </Typography>
      </Toolbar>
      <CompetitionsList filter={filter} />
    </Paper>
  )
}
