import { GmtModel } from "./core"
import { ToManyRelation, ToOneRelation } from "../../coloquent"
import { GmtCashPrice } from "./GmtCashPrice"
import { GmtPrice } from "./GmtPrice"
import { GmtMaterialPrice } from "./GmtMaterialPrice"
import { GmtCompetition } from "./GmtCompetition"
import { GmtWinner } from "./GmtWinner"

export interface IWinData {
  readonly title: string
  readonly competition: GmtCompetition | null
  readonly prices: ReadonlyArray<GmtPrice>
  readonly winner: GmtWinner | null
}

export class GmtWin extends GmtModel<IWinData> {
  static jsonApiType = "node--win"
  static endpoint = "node/win"

  static attMapping: Readonly<Record<string, keyof IWinData>> = {
    title: "title",
  }
  static relMapping: Readonly<Record<string, keyof IWinData>> = {
    field_price: "prices",
    field_ref_competition: "competition",
    field_winner: "winner",
  }

  getNodeId(): number {
    return this.getAttribute("drupal_internal__nid")
  }

  field_price(): ToManyRelation<GmtPrice, this> {
    return this.hasMany([GmtCashPrice, GmtMaterialPrice])
  }

  field_ref_competition(): ToOneRelation<GmtCompetition, this> {
    return this.hasOne(GmtCompetition)
  }

  field_winner(): ToOneRelation<GmtWinner, this> {
    return this.hasOne(GmtWinner)
  }
}
