import { ReactElement, useMemo } from "react"

import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import { useStore } from "core"
import { GmtCompetition } from "server/model"
import ListTable from "../shared/ListTable"
import CompetitionListRow, { CColumnsUnsortable } from "./CompetitionListRow"

export interface ICompetitionPanelProps {
  readonly competition: GmtCompetition
}

export default function CompetitionPanel(props: ICompetitionPanelProps): ReactElement {
  const { competition } = props
  const list = useMemo(() => [competition], [competition])
  const keywordsEmpty = useStore(competition.dataStore, (data) => !data.smsKeywords?.length)
  const excluded = useMemo(() => (keywordsEmpty ? ["wins", "field_sms_keyword"] : ["wins"]), [keywordsEmpty])
  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Gewinnspiel
        </Typography>
      </Toolbar>
      <ListTable
        data={list}
        columns={CColumnsUnsortable}
        className="marginBottom"
        exclude={excluded}
        renderRow={(entry) => <CompetitionListRow key={entry.getApiId()} comp={entry} exclude={excluded} />}
      />
    </Paper>
  )
}
