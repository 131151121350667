import { ReactElement } from "react"
import { GmtCashPrice, GmtMaterialPrice, GmtPrice, GmtWin } from "../../../server/model"
import { useStore } from "../../../core"
import Chip from "@mui/material/Chip"
import { formatCurrency } from "./formatCurrency"

export interface IWinChipProps {
  readonly win: GmtWin
  readonly onClick?: () => void
}

export default function WinChip(props: IWinChipProps): ReactElement {
  const { win, onClick } = props
  const { prices } = useStore(win.dataStore)
  return <Chip label={prices?.map(getPriceLabel).join(" + ") || "k.A."} onClick={onClick} className="WinChip" />
}

export function getPriceType(price: GmtPrice): string {
  return price instanceof GmtCashPrice ? "Geldpreis" : price instanceof GmtMaterialPrice ? "Sachpreis" : "unbekannt"
}

export function getPriceLabel(price: GmtPrice): string {
  return price instanceof GmtCashPrice
    ? formatCurrency(price.dataStore.state.winningAmount)
    : price instanceof GmtMaterialPrice
    ? price.dataStore.state.product?.dataStore.state.title || price.dataStore.state.title || "n.A."
    : "unbekannt"
}
