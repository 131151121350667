import { ReactElement } from "react"

import Stack from "@mui/material/Stack"
import SearchIcon from "@mui/icons-material/Search"

import { Store } from "core"
import { CParticipationTypeNames, CParticipationTypes, CProjectStateNames, CProjectStates } from "server/model"
import { IFetchProjectsFilter } from "server/logic/projects"
import { StoreDelayedTextField, StoreSimpleSelect } from "../forms"

export interface IProjectsFilterProps {
  readonly filterStore: Store<IFetchProjectsFilter>
}

export default function ProjectsFilter(props: IProjectsFilterProps): ReactElement {
  const { filterStore } = props
  return (
    <Stack direction="row" spacing={2} marginBottom={2} marginTop={2}>
      <StoreDelayedTextField label="Filter" store={filterStore} property="text" icon={<SearchIcon />} size="small" />
      <StoreSimpleSelect
        label="Teilnahmeweg"
        store={filterStore}
        property="participationType"
        list={CParticipationTypes}
        labels={CParticipationTypeNames}
        style={{ width: 120 }}
        emptyLabel="alle"
        small
      />
      <StoreSimpleSelect
        label="Status"
        store={filterStore}
        property="state"
        list={CProjectStates}
        labels={CProjectStateNames}
        style={{ width: 120 }}
        emptyLabel="alle"
        small
      />
      <StoreDelayedTextField
        label="Projektverantwortliche(r)"
        store={filterStore}
        property="projectManager"
        icon={<SearchIcon />}
        size="small"
      />
    </Stack>
  )
}
