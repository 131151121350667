import { ReactElement, useCallback } from "react"
import { useNavigate } from "react-router-dom"

import Stack from "@mui/material/Stack"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"

import { CRouteCompetitions, CRouteProjects } from "../../routes"
import { CompetitionIcon } from "../../components/icons/CompetitionIcon"
import ProjectIcon from "../../components/icons/ProjectIcon"
import SponsorIcon from "../../components/icons/SponsorIcon"
import { CRouteProjectsCreate } from "../projects/routes"
import { CRouteCompetitionsCreate } from "../competitions/routes"

import "./AddButtons.scss"
import Grid from "@mui/material/Grid"

export default function AddButtons(): ReactElement {
  return (
    <Grid container spacing={3} id="AddButtons">
      <AddButton
        title="Gewinnspiel anlegen"
        icon={<CompetitionIcon className="widget-icon" />}
        route={"/" + CRouteCompetitions + "/" + CRouteCompetitionsCreate}
      />
      <AddButton
        title="Projekt anlegen"
        icon={<ProjectIcon className="widget-icon" />}
        route={"/" + CRouteProjects + "/" + CRouteProjectsCreate}
      />
      <AddButton title="Sponsor anlegen" icon={<SponsorIcon className="widget-icon" />} route={"/todo"} />
    </Grid>
  )
}

function AddButton(props: {
  readonly title: string
  readonly icon: ReactElement
  readonly route: string
}): ReactElement {
  const { title, icon, route } = props
  const navigate = useNavigate()
  const onClick = useCallback(() => navigate(route), [navigate, route])

  // TODO das sollte besser klassisch mit css styling formatiert werden (ohne <Stack> oder <Grid>)
  return (
    <Grid item xs={4} xl={12}>
      <Paper onClick={onClick}>
        <Stack direction="row" spacing={2} paddingTop={2} paddingBottom={2}>
          {icon}
          <Typography color={"#ffffff"} textTransform={"uppercase"} fontSize={"16px"} variant="h6" component="div">
            {title}
          </Typography>
        </Stack>
      </Paper>
    </Grid>
  )
}
