import { ReactElement, useCallback, useMemo } from "react"
import { Spec } from "immutability-helper"

import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"

import { FilteredTypeByPropType, useStore } from "core"
import { GmtModel } from "server/model"
import { getErrorText, ISimpleInputProps } from "./core"
import { IInlineEditingProps, useInlineEditing } from "./useInlineEditing"
import { FormHelperText } from "@mui/material"

export interface IStoreRelationSelectProps<V extends object, T extends object, K extends keyof T>
  extends ISimpleInputProps<T, K>,
    IInlineEditingProps<T>,
    Omit<SelectProps<string | string[]>, "property" | "onChange"> {
  readonly list: ReadonlyArray<V>
  readonly titleKey: keyof FilteredTypeByPropType<V, string>
  readonly emptyLabel?: string
  readonly disabledOptions?: ReadonlyArray<V>
  readonly small?: boolean
}

let idSequence = 1

const CEmptyValueID = "__NA__"

export function StoreRelationSelect<V extends GmtModel, T extends object, K extends keyof T>(
  props: IStoreRelationSelectProps<V, T, K>
): ReactElement {
  const {
    store,
    list,
    property,
    label,
    labelId,
    fullWidth,
    titleKey,
    emptyLabel,
    required,
    disabledOptions,
    baseStore,
    doSave,
    errors,
    small,
    ...rest
  } = props
  const value = useStore(store, (state) => state[property] as unknown as V | null | undefined, [property])
  const { endAdornment, error, isInline } = useInlineEditing(
    "object",
    store,
    property,
    required,
    baseStore,
    doSave,
    errors
  )

  const onChange = useCallback(
    (event: SelectChangeEvent<string | string[]>) => {
      const value = event.target.value
      const objectValue = Array.isArray(value)
        ? value.map((valueEntry) => list.find((entry) => entry.getApiId() === valueEntry))
        : value === CEmptyValueID
        ? null
        : list.find((entry) => entry.getApiId() === value)
      store.update({
        [property]: { $set: objectValue },
      } as Spec<T>)
    },
    [list, property, store]
  )
  const id = useMemo(() => labelId || property.toString() + "Sel" + idSequence++, [labelId, property])
  const selectValue = Array.isArray(value)
    ? value.map((entry) => entry.getApiId() as string)
    : (list.length && value?.getApiId()) || CEmptyValueID

  return (
    <FormControl fullWidth={fullWidth} error={!!error} size={small ? "small" : undefined}>
      <InputLabel id={id} required={required} shrink className={isInline ? "inlineMode" : undefined}>
        {label}
      </InputLabel>
      <Select
        readOnly={!list.length}
        color={required && !value ? "error" : undefined}
        {...rest}
        label={label}
        labelId={id}
        value={selectValue}
        onChange={onChange}
        required={required}
        endAdornment={endAdornment}
        variant={isInline ? "filled" : undefined}
        notched
      >
        <MenuItem value={CEmptyValueID}>
          <em>{emptyLabel || "k.A."}</em>
        </MenuItem>
        {list.map((option) => (
          <MenuItem
            key={option.getApiId()}
            value={option.getApiId()}
            disabled={
              !!disabledOptions?.length &&
              disabledOptions.findIndex((entry) => entry.getApiId() === option.getApiId()) >= 0
            }
          >
            {option[titleKey] as unknown as string}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{getErrorText(error)}</FormHelperText>
    </FormControl>
  )
}
