import { GmtWinner } from "../../../../server/model"
import { ReactElement } from "react"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import { useStore } from "../../../../core"
import Grid from "@mui/material/Grid"
import AudioButton from "../DrawWinner/AudioButton"
import TextField from "@mui/material/TextField"

export interface IWinnerDrawProps {
  readonly winner: GmtWinner
}

export const CDrawTypeVoice = "Voice"

export default function WinnerDraw(props: IWinnerDrawProps): ReactElement | null {
  const { winner } = props
  const { baseStore } = winner
  const drawNode = useStore(baseStore, (state) => state.drawingNode)?.dataStore.state
  const voice = drawNode?.type === CDrawTypeVoice ? drawNode?.audio : undefined
  const text = drawNode?.type !== CDrawTypeVoice ? drawNode?.answer : undefined
  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Ziehungsdaten
        </Typography>
      </Toolbar>
      <Grid container spacing={2} paddingY={2}>
        <Grid item xs={12} md={6}>
          {voice &&
            voice.map((file) => (
              <div key={file.getApiId()}>
                <AudioButton src={file.url || ""} />
              </div>
            ))}
          {text && (
            <TextField
              label="Antwort"
              value={text || ""}
              sx={{ flexGrow: 1 }}
              InputLabelProps={{ shrink: true }}
              disabled
              fullWidth
            />
          )}
          {!voice && !text && <Typography sx={{ flexGrow: 1 }}>Keine Daten vorhanden</Typography>}
        </Grid>
      </Grid>
    </Paper>
  )
}
