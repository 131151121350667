import { ChangeEvent, Fragment, ReactElement, useCallback, useEffect, useState } from "react"
import format from "date-fns/format"
import de from "date-fns/locale/de"

import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Stack from "@mui/material/Stack"
import FormControl from "@mui/material/FormControl"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import LoadingButton from "@mui/lab/LoadingButton"
import SendIcon from "@mui/icons-material/Send"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import { createPromiseTerminator, useCreateStore, useStore } from "core"
import { GmtCommunication, GmtWinner, TComType } from "server/model"
import { StoreTextField } from "../../../components/forms"
import { useMessages } from "../../../components/LayoutContext"
import CircularProgress from "@mui/material/CircularProgress"
import { fetchCommunication, fetchReminderTemplate } from "../../../../server/logic/winners"

export interface IWinnerCommunicationsProps {
  readonly winner: GmtWinner
}

export default function WinnerCommunications(props: IWinnerCommunicationsProps): ReactElement | null {
  const { winner } = props
  const { baseStore } = winner
  const [sending, setSending] = useState(false)
  const [communications, setCommunications] = useState<ReadonlyArray<GmtCommunication> | null>()
  const isMail = useStore(baseStore, (state) => !!state.mail?.trim())
  const isMobilePhone = useStore(baseStore, (state) => !!state.mobileNumber?.trim())
  const isComChannel = (isMail || isMobilePhone) && !!communications
  const isFirstCom = !communications?.length
  const setMessage = useMessages()

  // fetch the communications
  useEffect(() => {
    return createPromiseTerminator(fetchCommunication(winner), setCommunications, (error) => {
      setCommunications(null)
      console.error(error)
    })
  }, [winner])

  const store = useCreateStore<{
    readonly text: string
    readonly type: TComType | ""
  }>({ text: "", type: "" })

  useEffect(() => {
    store.update({ type: { $set: isMail ? "mail" : isMobilePhone ? "sms" : "" } })
  }, [isMail, isMobilePhone, store])

  const doSend = useCallback(async () => {
    const { text, type } = store.state
    if (!winner || !type || !(isFirstCom || text?.trim())) return
    setSending(true)
    const newCom = new GmtCommunication().initialize({
      type,
      winner,
      title: winner.baseStore.state.title + " | " + format(new Date(), "P, p", { locale: de }) + ": " + type,
      isReminder: !isFirstCom,
    })
    if (!isFirstCom) {
      newCom.dataStore.update({
        body: { $set: { format: "plain_text", value: text } },
      })
    }
    try {
      await newCom.save()
      setCommunications(await fetchCommunication(winner))
      store.set({ text: "", type })
      setSending(false)
      setMessage({ message: "Nachricht wurde erfolgreich verschickt", severity: "success" })
    } catch (error) {
      console.error(error)
      setSending(false)
      setMessage({ message: "Es ist ein unerwarteter Fehler aufgetreten", severity: "error" })
    }
  }, [isFirstCom, setMessage, store, winner])

  const type = useStore(store, (state) => state.type)
  const isText = useStore(store, (state) => !!state.text?.trim())

  useEffect(() => {
    if (type) {
      return createPromiseTerminator(fetchReminderTemplate(type), (template) => {
        store.update({ text: { $set: template } })
      })
    }
  }, [store, type])

  const handleRadioChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      store.update({ type: { $set: event.target.value as TComType } })
    },
    [store]
  )

  return isMail || isMobilePhone || communications?.length ? (
    <Paper>
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Kommunikation
        </Typography>
        {!communications && <CircularProgress />}
        {isComChannel && (
          <LoadingButton
            variant="outlined"
            loading={sending}
            onClick={doSend}
            loadingPosition="start"
            startIcon={<SendIcon />}
            disabled={!(type && (isFirstCom || isText))}
          >
            {isFirstCom ? "Benachrichtigen" : "Abschicken"}
          </LoadingButton>
        )}
      </Toolbar>
      <Stack spacing={2} paddingBottom={2}>
        {isComChannel && (
          <Fragment>
            <FormControl>
              <RadioGroup row value={type} onChange={handleRadioChange}>
                <FormControlLabel value="mail" control={<Radio size="small" />} label="Email" disabled={!isMail} />
                <FormControlLabel value="sms" control={<Radio size="small" />} label="SMS" disabled={!isMobilePhone} />
              </RadioGroup>
            </FormControl>
            {!isFirstCom && (
              <Fragment>
                <StoreTextField label="Nachricht" store={store} property="text" required fullWidth multiline rows={4} />
              </Fragment>
            )}
          </Fragment>
        )}
        {communications?.map((entry) => (
          <CommunicationEntry key={entry.getApiId()} communication={entry} />
        ))}
      </Stack>
    </Paper>
  ) : null
}

function CommunicationEntry({ communication }: { readonly communication: GmtCommunication }): ReactElement {
  const { body, createdAt, type, author } = useStore(communication.dataStore)
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography>
          {(createdAt ? format(createdAt, "P, p", { locale: de }) + ": " : "") + type?.toUpperCase()}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th">Text</TableCell>
              <TableCell>
                {body?.processed ? (
                  <div dangerouslySetInnerHTML={{ __html: body?.processed }} />
                ) : (
                  body?.value || "[Leere Nachricht]"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Sender</TableCell>
              <TableCell>{author?.name || "[unbekannt]"}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}
