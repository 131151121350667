import { addMinutes, formatRFC3339 } from "date-fns"

/**
 * Typed wrapper of Object.getOwnPropertyNames
 *
 * @param object
 */
export function getObjectProps<T>(object: T): ReadonlyArray<keyof T> {
  return Object.getOwnPropertyNames(object) as unknown as ReadonlyArray<keyof T>
}

export function createPromiseTerminator<T>(
  promise: Promise<T>,
  callback: (value: T) => void,
  errorFn?: (error: unknown) => void,
  onCanceled?: () => void
): () => void {
  let canceled = false
  promise.then((value) => !canceled && callback(value), errorFn && ((error) => !canceled && errorFn(error)))
  return () => {
    canceled = true
    onCanceled && onCanceled()
  }
}

export type FilteredTypeByPropType<T extends object, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never
}

export function dateToUTC(date: Date): string {
  return formatRFC3339(addMinutes(date, date.getTimezoneOffset())).split("+")[0] + "+00:00"
}

export function concatArrays<T>(
  array1: ReadonlyArray<T> | undefined | null,
  array2: ReadonlyArray<T> | undefined | null,
  isEqualFn?: (a: T, b: T) => boolean,
  compareFn?: (a: T, b: T) => number
): ReadonlyArray<T> {
  const rv = !array1?.length
    ? [...(array2 || [])]
    : !array2?.length
    ? [...array1]
    : [...array1, ...(isEqualFn ? array2.filter((e2) => array1.findIndex((e1) => isEqualFn(e1, e2)) < 0) : array2)]
  if (typeof compareFn === "function") rv.sort(compareFn)
  return rv
}
