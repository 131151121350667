import { GmtModel } from "./core"
import { GmtSponsor } from "./GmtSponsor"
import { GmtProduct } from "./GmtProduct"
import { GmtWps } from "./GmtWps"

export interface IPriceData {
  readonly title: string
  readonly sponsor: GmtSponsor | null
  readonly product: GmtProduct | null
  readonly wpsNumber: GmtWps | null
}

const CPrefetchedRelations = ["field_sponsor", "field_product", "field_wps_number_ref"]

export function getPrefetchRelationsForPrice(prefix: string): ReadonlyArray<string> {
  return CPrefetchedRelations.map((entry) => prefix + entry)
}

export abstract class GmtPrice<T extends IPriceData = IPriceData> extends GmtModel<T> {}
