import { GmtModel } from "./core"

export interface IServiceNumberData {
  readonly name: string
  readonly locked: boolean
}

export class GmtServiceNumber extends GmtModel<IServiceNumberData> {
  static jsonApiType = "taxonomy_term--gmt_service_number"
  static endpoint = "taxonomy_term/gmt_service_number"

  static attMapping: Readonly<Record<string, keyof IServiceNumberData>> = {
    name: "name",
    field_locked: "locked",
  }
}
