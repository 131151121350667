import { Spec } from "immutability-helper"
import { FilteredTypeByPropType, getObjectProps, Store } from "core"
import { CBaseUrl } from "../constants"
import { Model, SaveResponse } from "../../coloquent"
import { arraysNotEqual } from "../../ui/components/forms/core"

let localId = 1

// noinspection SuspiciousTypeOfGuard
export abstract class GmtModel<DataType extends object = object> extends Model {
  static jsonApiBaseUrl = CBaseUrl + "/jsonapi"

  static attMapping?: Readonly<Record<string, string>>
  static relMapping?: Readonly<Record<string, string>>

  static setPageSize(size: number): void {
    this.pageSize = size
  }

  readonly localId = (localId++).toString()

  readonly dataStore = Store.create<Partial<DataType>>({} as Partial<DataType>)
  readonly baseStore = Store.create<Partial<DataType>>({} as Partial<DataType>)

  initialize(data: Partial<DataType>): this {
    this.dataStore.set(data)
    this.resetBaseStore()
    return this
  }

  private isResetting = false // prevent endless loops on cyclic references
  resetBaseStore(): void {
    if (this.isResetting) return
    this.isResetting = true
    const data = this.dataStore.state
    this.baseStore.set(data)
    getObjectProps(data).forEach((key) => {
      const value = data[key]
      if (typeof value === "object") {
        if (Array.isArray(value)) {
          value.forEach((entry) => {
            if (entry instanceof GmtModel) entry.resetBaseStore()
          })
        } else if (value instanceof GmtModel) {
          value.resetBaseStore()
        }
      }
    })
    this.isResetting = false
  }

  hasChanges(): boolean {
    const data = this.dataStore.state
    const baseData = this.baseStore.state
    if (data === baseData) return false
    const clazz = this.constructor as typeof GmtModel
    const attributes = clazz.attMapping
    if (attributes) {
      if (
        getObjectProps(attributes).find((key) => {
          const value = data[attributes[key] as keyof typeof data]
          const baseValue = baseData[attributes[key] as keyof typeof data]
          return value !== baseValue
        })
      ) {
        return true
      }
    }
    const relations = clazz.relMapping
    if (relations) {
      if (
        getObjectProps(relations).find((key) => {
          const value = data[relations[key] as keyof typeof data]
          const baseValue = baseData[relations[key] as keyof typeof data]
          if (value === baseValue) return false
          if (Array.isArray(value) || Array.isArray(baseValue)) {
            return !Array.isArray(value) || !Array.isArray(baseValue) || arraysNotEqual(value, baseValue)
          }
          if (value instanceof GmtModel || baseValue instanceof GmtModel) {
            return (value as GmtModel)?.getApiId() !== (baseValue as GmtModel)?.getApiId()
          }
        })
      ) {
        return true
      }
    }
    return false
  }

  async save(): Promise<SaveResponse<this>> {
    const clazz = this.constructor as typeof GmtModel
    const data = this.dataStore.state
    const attributes = clazz.attMapping
    if (attributes) {
      getObjectProps(attributes).forEach((key) => {
        super.setAttribute(key, data[attributes[key] as keyof typeof data])
      })
    }
    const relations = clazz.relMapping
    if (relations) {
      getObjectProps(relations).forEach((key) => {
        super.setRelation(key, data[relations[key] as keyof typeof data])
      })
    }
    const resp = await super.save()
    this.resetBaseStore()
    return resp
  }

  protected setAttribute(attributeName: string, value: unknown): void {
    value = super.setAttribute(attributeName, value)
    const clazz = this.constructor as typeof GmtModel
    const dataAttributeName = clazz.attMapping && clazz.attMapping[attributeName]
    if (dataAttributeName) {
      this.dataStore.update({ [dataAttributeName]: { $set: value } } as Spec<Partial<DataType>>)
    }
  }
  setRelation(relationName: string, value: unknown): void {
    super.setRelation(relationName, value)
    const clazz = this.constructor as typeof GmtModel
    const dataAttributeName = clazz.relMapping && clazz.relMapping[relationName]
    if (dataAttributeName) {
      this.dataStore.update({ [dataAttributeName]: { $set: value } } as Spec<Partial<DataType>>)
    }
  }

  addToRelation<KT extends Model, K extends keyof FilteredTypeByPropType<DataType, ReadonlyArray<KT>>>(
    key: K,
    value: KT
  ): void {
    this.dataStore.update({ [key]: { $push: [value] } } as unknown as Spec<Partial<DataType>>)
  }
  removeFromRelation<KT extends Model, K extends keyof FilteredTypeByPropType<DataType, ReadonlyArray<KT>>>(
    key: K,
    value: KT
  ): void {
    const list = this.dataStore.state[key]
    if (!Array.isArray(list) || !list.length) return
    const index = list.findIndex((item: Model) => item === value || item.getApiId() === value.getApiId())
    if (index < 0) return
    this.dataStore.update({ [key]: { $splice: [[index, 1]] } } as unknown as Spec<Partial<DataType>>)
  }
}

export interface IJsonApiResponse {
  readonly meta?: {
    readonly links?: {
      readonly me?: {
        readonly meta?: {
          readonly id?: string
        }
      }
    }
    readonly count?: string
  }
}
