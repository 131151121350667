import { Fragment, ReactElement, useCallback } from "react"

import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"

import { useStore } from "core"
import { GmtProject } from "server/model"
import ProjectDetailsPaper from "../../../components/projects/ProjectDetailsPaper"

export interface IStepOneProps {
  readonly project: GmtProject
  readonly goNext: () => void
}

export default function StepOne(props: IStepOneProps): ReactElement {
  const { project, goNext } = props
  const validateAndGoNext = useCallback(() => {
    goNext()
  }, [goNext])

  const nextBtnDisabled = useStore(
    project.dataStore,
    (data) =>
      !(
        data.sender &&
        data.gameMechanics &&
        data.title?.trim() &&
        (data.sendingId || data.participationType === "online") &&
        data.participationType &&
        data.projectManager?.length
      )
  )

  return (
    <Fragment>
      <ProjectDetailsPaper project={project} />
      <Stack direction="row">
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="contained" onClick={validateAndGoNext} disabled={nextBtnDisabled}>
          Weiter
        </Button>
      </Stack>
    </Fragment>
  )
}
