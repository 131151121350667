import { GmtProject, IProjectData, isLineGame } from "../../model"
import { IErrors, stripErrors } from "../../../core"
import { fetchServiceNumbers } from "../fetchServiceNumbers"
import { refreshKeywords } from "../keywords/saving"
import { concatSets } from "../core"

export function validateProjectDataForReservation(data: Partial<IProjectData>): IErrors<IProjectData> | undefined {
  return stripErrors<IProjectData>({
    title: data.title?.trim() ? "" : "missing",
    sender: data.sender ? "" : "missing",
    participationType: data.participationType ? "" : "missing",
    gameMechanics: data.gameMechanics ? "" : "missing",
    sendingId: data.sendingId || !data.participationType || data.participationType === "online" ? "" : "missing",
  })
}

export function validateProjectDataForActivation(data: Partial<IProjectData>): IErrors<IProjectData> | undefined {
  return stripErrors<IProjectData>({
    ...validateProjectDataForReservation(data),
    promamsTitle: data.promamsTitle?.trim() || data.promamsId?.trim() ? "" : "missing",
    promamsId: data.promamsTitle?.trim() || data.promamsId?.trim() ? "" : "missing",
    projectManager: data.projectManager?.length ? "" : "missing",
    creditorKey: data.creditorKey?.trim() ? "" : "missing",
    probability: data.probability?.trim() ? "" : "missing",
    broadcastWindow: data.broadcastWindow?.trim() ? "" : "missing",
    numberOfLines: !isLineGame(data.gameMechanics) || data.numberOfLines ? "" : "missing",
    winningLine: !isLineGame(data.gameMechanics) || data.winningLine ? "" : "missing",
    statistics: data.statistics?.trim() ? "" : "missing",
  })
}

export async function validateProjectForReservation(project: GmtProject): Promise<ReadonlyArray<string>> {
  const rv: Array<string> = []
  if (project.hasChanges()) {
    rv.push("Es gibt ungespeicherte Änderungen")
    return rv // quick exit
  }
  const data = project.dataStore.state
  if (!data.title?.trim()) rv.push("Titel fehlt")
  if (!data.sender) rv.push("Sender fehlt")
  if (!data.sendingId && data.participationType && data.participationType !== "online") rv.push("Service-ID fehlt")
  if (
    data.participationType &&
    ["phone", "hybrid"].includes(data.participationType) &&
    !data.serviceNumbersTv?.length
  ) {
    rv.push("Servicenummern fehlen")
  }
  if (rv.length) return rv // quick exit
  try {
    if (
      (await checkServiceNumbers(project, "serviceNumbersOnline")) ||
      (await checkServiceNumbers(project, "serviceNumbersTv"))
    ) {
      rv.push("Nicht alle Servicenummern sind frei")
    }
    if (await checkKeywords(project)) {
      rv.push("Nicht alle SMS-Keyword(s) sind frei")
    }
  } catch (error) {
    rv.push("Es ist ein unerwarteter Fehler aufgetreten")
    console.error(error)
  }
  return rv
}

export async function validateProjectForActivation(project: GmtProject): Promise<ReadonlyArray<string>> {
  const rv: Array<string> = []
  if (project.hasChanges()) {
    rv.push("Es gibt ungespeicherte Änderungen")
    return rv // quick exit
  }
  const data = project.dataStore.state
  if (!data.title?.trim()) rv.push("Titel fehlt")
  if (!data.sender) rv.push("Sender fehlt")
  if (!data.participationType) rv.push("Teilnahmeweg fehlt")
  if (!data.gameMechanics) rv.push("Spielmechanik fehlt")
  if (!data.sendingId && data.participationType && data.participationType !== "online") rv.push("Service-ID fehlt")
  if (!data.promamsTitle?.trim() && !data.promamsId?.trim()) rv.push("Promams Titel oder ID fehlt")
  if (!data.projectManager?.length) rv.push("Projektverantwortliche fehlen")
  if (!data.creditorKey?.trim()) rv.push("Kreditorenschlüssel fehlt")
  if (!data.probability?.trim()) rv.push("Wahrscheinlichkeit fehlt")
  if (!data.broadcastWindow?.trim()) rv.push("Sendefenster fehlt")
  if (isLineGame(data.gameMechanics)) {
    if (!data.numberOfLines) rv.push("Anzahl Leitungen fehlt")
    if (!data.winningLine) rv.push("Gewinnerleitung fehlt")
  }
  if (!data.statistics?.trim()) rv.push("Statistik fehlt")
  return rv
}

async function checkServiceNumbers(
  project: GmtProject,
  key: "serviceNumbersOnline" | "serviceNumbersTv"
): Promise<boolean> {
  const list = project.dataStore.state[key]
  if (list?.length) {
    const reloadedNumbers = await fetchServiceNumbers({
      filter: { include: list },
      pager: { pageSize: list.length, page: 0 },
    })
    if (reloadedNumbers.data.find((entry) => entry.dataStore.state.locked)) {
      project.dataStore.update({ [key]: { $set: reloadedNumbers.data } })
      project.baseStore.set(project.dataStore.state)
      return true
    }
  }
  return false
}

async function checkKeywords(project: GmtProject): Promise<boolean> {
  const { keywordsOnline, keywordsTv } = project.dataStore.state
  const list = concatSets(keywordsOnline, keywordsTv)
  await refreshKeywords(list)
  return !!list.find((entry) => entry.dataStore.state.locked)
}
