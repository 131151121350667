import { ReactElement } from "react"
import SearchIcon from "@mui/icons-material/Search"

import { IFetchWinnersFilter } from "server/logic/winners"

import "./WinnersFilter.scss"
import { CWinnerStatus, CWinnerStatusNames } from "server/model"
import { StoreDatePicker, StoreDelayedTextField, StoreSimpleSelect } from "../../../components/forms"
import { Store, useStore } from "../../../../core"
import Stack from "@mui/material/Stack"

export interface IWinnersFilterProps {
  readonly filterStore: Store<IFetchWinnersFilter>
}

export default function WinnersFilter(props: IWinnersFilterProps): ReactElement {
  const { filterStore } = props
  const filter = useStore(filterStore)

  return (
    <Stack direction="row" spacing={2} marginBottom={2} marginTop={2}>
      <StoreDelayedTextField label="Suche" store={filterStore} property="text" icon={<SearchIcon />} size="small" />
      <div>
        <StoreDatePicker
          label="Teilnahmeschluss"
          maxDate={filter.maxEnd || undefined}
          store={filterStore}
          property="minEnd"
          size="small"
          disableFuture
        />
        <span className="separator">-</span>
        <StoreDatePicker store={filterStore} property="maxEnd" size="small" disableFuture />
      </div>
      <StoreSimpleSelect
        label="Status"
        store={filterStore}
        property="winnerStatus"
        list={CWinnerStatus}
        labels={CWinnerStatusNames}
        emptyLabel="alle"
        small
      />
      <StoreDelayedTextField
        label="Projektverantwortliche(r)"
        store={filterStore}
        property="projectManager"
        icon={<SearchIcon />}
        size="small"
      />
    </Stack>
  )
}
