import { ReactElement } from "react"

import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"

import { IPagerInfo } from "server/logic"
import { CPageSizes } from "../../constants"
import CompetitionsList from "../../components/competitions/CompetitionsList"
import WinnersList from "../../components/winners/WinnersList"
import { IWidgetDef } from "./TopWidgets"

const CPager: IPagerInfo = {
  page: 0,
  pageSize: CPageSizes[0],
}

const CWidgetDef: IWidgetDef = { title: "Aktive Gewinnspiele", compFilter: {} }

export default function CompetitionsWidget({ widgetDef }: { readonly widgetDef?: IWidgetDef }): ReactElement {
  const { title, compFilter, winnerFilter } = widgetDef || CWidgetDef
  return (
    <Paper id="CompetitionsWidget">
      <Toolbar>
        <Typography variant="h6" component="h6">
          {(compFilter ? "Gewinnspiele: " : "Gewinner: ") + title}
        </Typography>
      </Toolbar>
      {compFilter && <CompetitionsList pager={CPager} filter={compFilter} />}
      {winnerFilter && <WinnersList pager={CPager} filter={winnerFilter} />}
    </Paper>
  )
}
