import axios, { AxiosError } from "axios"
import { CBaseUrl } from "../constants"

export async function login(data: ILoginRequest): Promise<void> {
  try {
    await axios.post(CBaseUrl + "/user/login?_format=json", data)
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response?.status === 400) throw new Error("invalidLoginOrPassword")
      if (error.response?.status === 403) throw new Error("sessionAuthenticated")
    }
    throw new Error((error as object).toString())
  }
}

export interface ILoginRequest {
  readonly name: string
  readonly pass: string
}

export interface ILoginResponse {
  readonly csrf_token: string
}
