import { addSeconds, endOfDay, startOfDay } from "date-fns"
import { dateToUTC } from "core"
import { ConditionFilterSpec } from "../../../coloquent/filter/ConditionFilterSpec"
import { GmtCompetition, GmtProject, GmtSender, GmtUser, TGameStatus } from "../../model"
import { fetchModel, IFetchResult, IFetchSpec } from "../core"
import { GroupFilterSpec } from "../../../coloquent/filter/GroupFilterSpec"
import { isNumber } from "../shared"
import { parseCurrency } from "../../../ui/components/shared/parseCurrency"

// https://www.drupal.org/docs/core-modules-and-themes/core-modules/jsonapi-module/filtering

const CListIncludedRelations = [
  "field_win.field_price.field_product",
  "field_win.field_winner",
  "field_sms_keyword",
  "field_project_data.field_sender.field_station_logo",
  "field_project_data.field_game_mechanics",
  "field_project_data.field_project_manager",
]

const CDetailsIncludedRelations = [
  ...CListIncludedRelations,
  "field_win.field_price.field_sponsor",
  "field_win.field_price.field_wps_number_ref",
  "field_project_data.field_service_number_online",
  "field_project_data.field_service_number_tv",
  "field_project_data.field_sms_keywords_tv",
  "field_project_data.field_sms_keywords_online",
  "field_more_projects.field_sender.field_station_logo",
  "field_more_projects.field_game_mechanics",
  "field_more_projects.field_service_number_online",
  "field_more_projects.field_service_number_tv",
  "field_more_projects.field_sms_keywords_tv",
  "field_more_projects.field_sms_keywords_online",
  "field_documents",
]

export async function fetchCompetition(id: string): Promise<GmtCompetition | null> {
  const result = await GmtCompetition.find(id, CDetailsIncludedRelations)
  const rv = result.getData()
  if (rv) {
    // cleanup any missing back - relationships
    const { wins } = rv.dataStore.state
    const filteredWins = wins?.filter((entry) => !entry.dataStore.state.competition)
    if (filteredWins?.length) {
      await Promise.all(
        filteredWins.map((entry) => {
          entry.dataStore.update({ competition: { $set: rv } })
          return entry.save().catch((error) => {
            console.error(error)
          })
        })
      )
    }
    rv.resetBaseStore()
  }
  return rv
}

export async function fetchCompetitions(
  spec: IFetchSpec<IFetchCompetitionsFilter>
): Promise<IFetchResult<GmtCompetition>> {
  const {
    pager,
    filter: {
      gameStates,
      gameStatus,
      gameStatusIsNot,
      gameStatusIsNull,
      text,
      sender,
      minStart,
      minEnd,
      maxEnd,
      maxStart,
      user,
      notExported,
      project,
      projectManager,
      projectUser,
    },
    sort,
  } = spec
  GmtCompetition.setPageSize(pager.pageSize)
  let query = GmtCompetition.with(CListIncludedRelations)
  const finalStatus =
    gameStates?.length === 1 && !gameStatus ? gameStates[0] : gameStates?.length ? undefined : gameStatus
  if (finalStatus) {
    query = query.where("field_game_status", finalStatus)
  }
  const finalStates = gameStates?.length ? (gameStatus ? [...gameStates, gameStatus] : gameStates) : []
  if (finalStates.length > 1) {
    query = query.filter(
      new GroupFilterSpec(
        "OR",
        finalStates.map((state) =>
          state
            ? new ConditionFilterSpec("field_game_status", "=", state)
            : new ConditionFilterSpec("field_game_status", "IS NULL")
        )
      )
    )
  }
  if (gameStatusIsNot) {
    query = query.filter(new ConditionFilterSpec("field_game_status", "<>", gameStatusIsNot))
  }
  if (gameStatusIsNull) {
    query = query.filter(new ConditionFilterSpec("field_game_status", "IS NULL"))
  }
  if (text && isNumber(text)) {
    query = query.where("drupal_internal__nid", text)
  }
  if (text) {
    query = query.filter(
      new GroupFilterSpec("OR", [
        new ConditionFilterSpec("title", "CONTAINS", text),
        new ConditionFilterSpec("drupal_internal__nid", "CONTAINS", text),
        new ConditionFilterSpec("field_win.field_price.title", "CONTAINS", text),
        new ConditionFilterSpec("field_win.field_price.field_value_in_euro", "CONTAINS", parseCurrency(text)),
        new ConditionFilterSpec("field_win.field_price.field_price_in_euro", "CONTAINS", parseCurrency(text)),
        new ConditionFilterSpec("field_win.field_price.field_winning_amount", "CONTAINS", parseCurrency(text)),
      ])
    )
  }
  if (minStart) {
    query = query.filter(
      new ConditionFilterSpec("field_participation_start_date", ">", dateToUTC(addSeconds(startOfDay(minStart), -1)))
    )
  }
  if (maxStart) {
    query = query.filter(new ConditionFilterSpec("field_participation_start_date", "<", dateToUTC(endOfDay(maxStart))))
  }
  if (minEnd) {
    query = query.filter(
      new ConditionFilterSpec("field_participation_end_date", ">", dateToUTC(addSeconds(startOfDay(minEnd), -1)))
    )
  }
  if (maxEnd) {
    query = query.filter(new ConditionFilterSpec("field_participation_end_date", "<", dateToUTC(endOfDay(maxEnd))))
  }
  if (sender) {
    query = query.where(
      "field_project_data.field_sender.id",
      typeof sender === "string" ? sender : sender.getApiId() || "_na_"
    )
  }
  if (notExported) {
    query = query.filter(new ConditionFilterSpec("field_export_gewinnarena", "IS NULL"))
  }
  if (user) {
    query = query.where("uid.id", user.getApiId() || "_na_")
  }
  if (project) {
    query = query.where("field_project_data.id", project.getApiId() || "_na_")
  }
  if (projectManager) {
    query = query.filter(
      new ConditionFilterSpec("field_project_data.field_project_manager.name", "CONTAINS", projectManager)
    )
  }
  if (projectUser) {
    query = query.where("field_project_data.field_project_manager.id", projectUser.getApiId() || "_na_")
  }
  return await fetchModel(query, pager, sort)
}

export interface IFetchCompetitionsFilter {
  readonly gameStatus?: TGameStatus
  readonly gameStates?: ReadonlyArray<TGameStatus | null>
  readonly gameStatusIsNot?: TGameStatus
  readonly gameStatusIsNull?: boolean
  readonly text?: string
  readonly minStart?: Date | null
  readonly maxStart?: Date | null
  readonly minEnd?: Date | null
  readonly maxEnd?: Date | null
  readonly sender?: string | GmtSender
  readonly user?: GmtUser | null
  readonly notExported?: boolean
  readonly project?: GmtProject | null
  readonly projectManager?: string
  readonly projectUser?: GmtUser | null
}
