import { AxiosError } from "axios"
import { Builder, PluralResponse, SortDirection } from "../../coloquent"
import { getObjectProps, IErrors } from "../../core"
import { GmtModel, IJsonApiResponse } from "../model"

export interface IFetchResult<T extends GmtModel> {
  readonly count: number
  readonly pager: IPagerInfo
  readonly data: ReadonlyArray<T>
}

export interface IPagerInfo {
  readonly page: number
  readonly pageSize: number
}

export interface IFetchSpec<FilterType = unknown> {
  readonly pager: IPagerInfo
  readonly filter: FilterType
  readonly sort?: ISortSpec
}

export interface ISortSpec {
  readonly key: string
  readonly desc?: boolean
}

export async function fetchModel<T extends GmtModel, M extends T & { new (): T }>(
  query: Builder<InstanceType<M>, PluralResponse<InstanceType<M>>>,
  pager: IPagerInfo,
  sort: ISortSpec | undefined
): Promise<IFetchResult<T>> {
  if (sort?.key) {
    query = query.orderBy(sort.key, sort.desc ? SortDirection.DESC : SortDirection.ASC)
  }
  const response = await query.get(pager.page + 1)
  const rawResponse = response.getHttpClientResponse().getData() as IJsonApiResponse
  return {
    pager,
    count: parseInt(rawResponse.meta?.count || "0"),
    data: response.getData() as ReadonlyArray<T>,
  }
}

export function entitiesEqual<T extends GmtModel>(a: T, b: T): boolean {
  return a === b || (!!a?.getApiId() && !!b?.getApiId() && a?.getApiId() === b?.getApiId())
}

export function concatSets<T extends GmtModel>(
  a: ReadonlyArray<T> | undefined,
  b: ReadonlyArray<T> | undefined
): ReadonlyArray<T> {
  return !b?.length
    ? a || []
    : !a?.length
    ? b || []
    : [...a, ...b.filter((be) => !a.find((ae) => ae === be || (ae.getApiId() && ae.getApiId() === be.getApiId())))]
}

export function extractSets<T extends GmtModel>(
  a: ReadonlyArray<T> | undefined,
  b: ReadonlyArray<T> | undefined
): ReadonlyArray<T> {
  return !b?.length
    ? a || []
    : !a?.length
    ? []
    : a.filter((ae) => !b.find((be) => ae === be || (ae.getApiId() && ae.getApiId() === be.getApiId())))
}

export function getObjectErrors<T extends object, M extends typeof GmtModel<T>>(model: M, error: unknown): IErrors<T> {
  console.error(error)
  const myErrors: IErrors<T> = {}
  if (error instanceof AxiosError) {
    const errors = error.response?.data.errors
    if (Array.isArray(errors) && errors?.length) {
      const attMapping = model.attMapping
      const relMapping = model.relMapping
      errors.forEach((singleError) => {
        if (typeof singleError.detail === "string") {
          const splitted = singleError.detail.split(": ")
          if (
            splitted.length === 2 &&
            // eslint-disable-next-line no-prototype-builtins
            (attMapping?.hasOwnProperty(splitted[0]) || relMapping?.hasOwnProperty(splitted[0]))
          ) {
            const key = attMapping?.[splitted[0]] || relMapping?.[splitted[0]]
            myErrors[key as keyof T] = splitted[1]
          }
        }
      })
    }
  }
  if (!getObjectProps(myErrors).length) {
    myErrors._ = "Es ist ein unbekannter Fehler aufgetreten"
  }
  return myErrors
}
