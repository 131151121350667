import { IFilterSpec } from "../IFilterSpec"
import { QueryParam } from "../QueryParam"

export class ConditionFilterSpec implements IFilterSpec {
  readonly path: string
  readonly operator: string
  readonly value?: string | ReadonlyArray<string>

  constructor(path: string, operator: string, value?: string | ReadonlyArray<string>) {
    this.path = path
    this.operator = operator
    this.value = value
  }

  getQueryParams(idx: string, group?: string): ReadonlyArray<QueryParam> {
    const rv = [
      new QueryParam(`filter[${idx}][condition][path]`, this.path),
      new QueryParam(`filter[${idx}][condition][operator]`, this.operator),
    ]
    if (typeof this.value === "string") {
      rv.push(new QueryParam(`filter[${idx}][condition][value]`, this.value))
    } else if (Array.isArray(this.value)) {
      this.value.forEach((value, index) => {
        rv.push(new QueryParam(`filter[${idx}][condition][value][${index}]`, value))
      })
    }
    if (group) {
      rv.push(new QueryParam(`filter[${idx}][condition][memberOf]`, group))
    }
    return rv
  }
}
