import { Fragment, ReactElement, useCallback, useEffect, useRef } from "react"

import Button from "@mui/material/Button"

import StopCircleIcon from "@mui/icons-material/StopCircle"
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled"

import { useStateStore } from "core"

export interface IAudioButtonProps {
  readonly src: string
}

export default function AudioButton(props: IAudioButtonProps): ReactElement {
  const { src } = props
  const audioRef = useRef<HTMLAudioElement>(null)
  const [{ playing, duration, position }, playStateStore] = useStateStore<IPlayState>({
    playing: false,
    duration: 0,
    position: 0,
  })

  useEffect(() => {
    const audio = audioRef.current
    if (!audio) return
    let timer = 0
    audio.addEventListener("ended", () => {
      playStateStore.update({ playing: { $set: false }, position: { $set: 0 } })
      window.clearInterval(timer)
    })
    audio.addEventListener("loadedmetadata", () => {
      playStateStore.update({ duration: { $set: audio.duration } })
    })
    audio.addEventListener("playing", () => {
      window.clearInterval(timer)
      timer = window.setInterval(() => {
        playStateStore.update({ position: { $set: audio.currentTime } })
      }, 100)
    })
    audio.addEventListener("pause", () => {
      window.clearInterval(timer)
    })
    audio.addEventListener("error", () => {
      window.clearInterval(timer)
    })
    return () => {
      window.clearInterval(timer)
    }
  }, [playStateStore])

  const onAudioClick = useCallback(() => {
    const audio = audioRef.current
    if (!audio) return
    if (playStateStore.state.playing) {
      audio.pause()
      audio.currentTime = 0
      playStateStore.update({ playing: { $set: false }, position: { $set: 0 } })
    } else {
      audio.play().then(
        () => {
          playStateStore.update({ playing: { $set: true } })
        },
        (error) => {
          console.error(error)
        }
      )
    }
  }, [playStateStore])

  return (
    <Fragment>
      <Button
        variant={playing ? "contained" : "outlined"}
        color={playing ? "success" : "primary"}
        size="large"
        fullWidth
        startIcon={playing ? <StopCircleIcon /> : <PlayCircleFilledIcon />}
        onClick={onAudioClick}
      >
        {formatTime(position)} / {formatTime(duration)}
      </Button>
      <audio ref={audioRef} src={src} preload="metadata" />
    </Fragment>
  )
}

function formatTime(time: number): string {
  const seconds = Math.floor(time)
  const minutes = Math.floor(seconds / 60)
  return formatNumber(minutes) + ":" + formatNumber(seconds - minutes * 60)
}

function formatNumber(value: number): string {
  return value > 9 ? value.toString() : "0" + value.toString()
}

interface IPlayState {
  readonly playing: boolean
  readonly duration: number
  readonly position: number
}
