import { ReactElement } from "react"
import { useFormState } from "react-final-form"

import Alert from "@mui/material/Alert"

export function GmtFormError(): ReactElement | null {
  const formState = useFormState({ subscription: { submitError: true, error: true } })
  const error = formState.submitError || formState.error
  return error ? <Alert severity="error">{error}</Alert> : null
}
