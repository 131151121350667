import Grid from "@mui/material/Grid"
import { ReactElement } from "react"
import {
  CDrawResponseTypeData,
  CDrawResponseTypeManual,
  CDrawResponseTypeSms,
  CDrawResponseTypeVoice,
  IDrawResponseWinnerBase,
  IDrawResponseWinnerData,
  IDrawResponseWinnerSms,
  IDrawResponseWinnerVoice,
} from "server/logic/winners"
import AudioButton from "./AudioButton"
import TextField from "@mui/material/TextField"
import Paper from "@mui/material/Paper"

export interface IResponseInfoProps {
  readonly response: IDrawResponseWinnerBase
}

export default function ResponseInfo(props: IResponseInfoProps): ReactElement | null {
  const { response } = props

  const smsResponse = response?.recordtype === CDrawResponseTypeSms ? (response as IDrawResponseWinnerSms) : undefined
  const voiceResponse =
    response?.recordtype === CDrawResponseTypeVoice ? (response as IDrawResponseWinnerVoice) : undefined
  const dataResponse =
    response?.recordtype === CDrawResponseTypeData ? (response as IDrawResponseWinnerData) : undefined

  return response?.recordtype === CDrawResponseTypeManual ? null : (
    <Paper>
      <Grid container spacing={2} paddingY={2}>
        <Grid item xs={12} md={6}>
          {voiceResponse ? (
            <AudioButton src={voiceResponse?.answerAudio || ""} />
          ) : (
            <TextField
              label="Antwort"
              value={smsResponse?.answerText || dataResponse?.answer || ""}
              sx={{ flexGrow: 1 }}
              InputLabelProps={{ shrink: true }}
              disabled
              fullWidth
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}
