import { ReactElement, useMemo } from "react"

import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"

import { IErrors, useStore } from "core"
import { CGameMechanicsConfig, GmtCompetition, ICompetitionData } from "server/model"
import { IInlineEditingProps, StoreDatePicker, StoreTextField } from "../forms"
import SelectKeywords from "../../pages/competitions/NewCompetition/SelectKeywords"

export default function CompetitionDetailsPaper({
  competition,
  doSave,
  errors,
}: {
  readonly competition: GmtCompetition
  readonly doSave?: () => Promise<unknown>
  readonly errors?: IErrors<ICompetitionData>
}): ReactElement {
  const { baseStore, dataStore } = competition
  const project = useStore(dataStore, (state) => state.project)
  const participationStartDate = useStore(dataStore, (state) => state.participationStartDate)
  const participationEndDate = useStore(dataStore, (state) => state.participationEndDate)
  const isActive = useStore(dataStore, (state) => !!state.gameStatus && state.gameStatus !== "Entwurf")
  const { question, smsKeyword, textKeyword, answers } =
    CGameMechanicsConfig[project?.dataStore.state.gameMechanics?.tid || 0] || {}

  const inlineMode = typeof doSave === "function"
  const inlineProps = useMemo<IInlineEditingProps<Partial<ICompetitionData>>>(
    () => (inlineMode ? { baseStore, doSave } : {}),
    [inlineMode, doSave, baseStore]
  )

  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Details (id: {competition.nid()})
        </Typography>
      </Toolbar>
      <Grid container spacing={2} paddingBottom={2}>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            label="Titel"
            store={dataStore}
            errors={errors}
            property="title"
            disabled={isActive}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {smsKeyword && <SelectKeywords competition={competition} doSave={doSave} disabled={isActive} />}
          {textKeyword && (
            <StoreTextField
              {...inlineProps}
              label="Antwort"
              store={dataStore}
              errors={errors}
              property="answer"
              disabled={isActive}
              fullWidth
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreDatePicker
            {...inlineProps}
            withTime
            label="Teilnahmestart"
            store={dataStore}
            errors={errors}
            property="participationStartDate"
            maxDate={participationEndDate}
            disabled={isActive}
            required={inlineMode}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreDatePicker
            {...inlineProps}
            withTime
            label="Teilnahmeschluss"
            store={dataStore}
            errors={errors}
            property="participationEndDate"
            minDate={participationStartDate}
            disabled={isActive}
            required={inlineMode}
            fullWidth
          />
        </Grid>
        {question && (
          <Grid item xs={12} md={6}>
            <StoreTextField
              {...inlineProps}
              label="Frage"
              store={dataStore}
              errors={errors}
              property="question"
              disabled={isActive}
              multiline
              fullWidth
              rows={3}
            />
          </Grid>
        )}
        {answers && (
          <Grid item xs={12} md={6}>
            <StoreTextField
              {...inlineProps}
              label="Antwortoptionen"
              store={dataStore}
              errors={errors}
              property="answers"
              arraySplitter={"\n" /* do not remove curly arrows! */}
              disabled={isActive}
              multiline
              fullWidth
              rows={3}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            label="Notizen"
            store={dataStore}
            errors={errors}
            property="notes"
            multiline
            fullWidth
            rows={3}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
