import { Fragment, ReactElement, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useConfirm } from "material-ui-confirm"

import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import LoadingButton from "@mui/lab/LoadingButton"

import ClearIcon from "@mui/icons-material/Clear"
import SaveIcon from "@mui/icons-material/Save"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"

import { createPromiseTerminator, Store, useStateStore, useStore } from "core"
import { GmtCompetition, GmtWin } from "server/model"
import {
  CDrawResponseTypeManual,
  createDrawResponse,
  createWinner,
  endDrawing,
  IDrawingResponse,
  IDrawResponseWinnerManual,
  LockedError,
  startDrawing,
  unblockDrawing,
  withdraw,
} from "server/logic/winners"
import { fetchCompetition } from "server/logic/competitions"
import { CRouteWinners } from "../../../routes"
import { useMessages } from "../../../components/LayoutContext"
import CompetitionPanel from "../../../components/competitions/CompetitionPanel"
import ProjectsListPaper from "../../../components/competitions/ProjectsListPaper"
import WinnerPanel from "../../../components/winners/WinnerPanel"
import { CRouteWinnerDetails, CRouteWinnerDrawing } from "../routes"
import WinsPanel from "./WinsPanel"
import ResponseInfo from "./ResponseInfo"

export default function DrawWinner(): ReactElement {
  const { compId } = useParams()
  const setMessage = useMessages()
  const confirm = useConfirm()

  const [comp, setComp] = useState<GmtCompetition | null>()
  useEffect(() => {
    if (compId) {
      return createPromiseTerminator(fetchCompetition(compId), setComp)
    }
  }, [compId])

  const navigate = useNavigate()
  const goBack = useCallback(() => {
    navigate("/" + CRouteWinners + "/" + CRouteWinnerDrawing)
  }, [navigate])

  const [{ drawResponse, saving }, drawStateStore] = useStateStore<IDrawState>({})

  const resetDrawState = useCallback((): void => {
    drawStateStore.update({ drawResponse: { $set: undefined }, selected: { $set: undefined } })
  }, [drawStateStore])

  const onStartDrawingClick = useCallback(
    (win: GmtWin, manual?: boolean): void => {
      if (manual) {
        const manResp: IDrawResponseWinnerManual = {
          recordtype: CDrawResponseTypeManual,
          phone: "",
        }
        drawStateStore.update({
          selected: { $set: win },
          drawResponse: {
            $set: createDrawResponse(win, manResp),
          },
        })
      } else {
        drawStateStore.update({ selected: { $set: win }, drawResponse: { $set: null } })
        startDrawing(win).then(
          (response) => {
            drawStateStore.update({ drawResponse: { $set: response } })
          },
          (error) => {
            if (error instanceof LockedError) {
              confirm({
                title: "Gewinnerziehung ist aktiv",
                description: "Soll die aktive Gewinnerziehung abgebrochen werden?",
                confirmationText: "Ja",
                cancellationText: "Nein",
              }).then(() => {
                unblockDrawing(error.config).then(
                  () => {
                    setMessage({ message: "Gewinnerziehung wurde abgebrochen.", severity: "success" })
                    resetDrawState()
                  },
                  () => {
                    setMessage({ message: "Gewinnerziehung konnte nicht abgebrochen werden!", severity: "error" })
                    resetDrawState()
                  }
                )
              }, resetDrawState)
            } else {
              console.error(error)
              setMessage({ message: "Fehler: " + error, severity: "error" })
              resetDrawState()
            }
          }
        )
      }
    },
    [confirm, drawStateStore, resetDrawState, setMessage]
  )

  const winActionCell = useCallback(
    (win: GmtWin) => <WinActionCell win={win} drawStateStore={drawStateStore} startDrawing={onStartDrawingClick} />,
    [drawStateStore, onStartDrawingClick]
  )

  const saveWinner = useCallback(() => {
    const { drawResponse, selected } = drawStateStore.state
    if (!drawResponse || !selected) return
    const { winner, response } = drawResponse
    drawStateStore.update({ saving: { $set: true } })
    createWinner(winner).then(
      (winner) => {
        if (winner) {
          endDrawing(selected, response).then(
            () => {
              drawStateStore.set({})
            },
            (error) => {
              drawStateStore.update({ saving: { $set: false } })
              setMessage({ message: "Fehler beim Beenden der Ziehung: " + error, severity: "error" })
              console.error(error)
            }
          )
        } else {
          drawStateStore.update({ saving: { $set: false } })
          setMessage({ message: "Änderungen konnten nicht gespeichert werden", severity: "error" })
        }
      },
      (error) => {
        drawStateStore.update({ saving: { $set: false } })
        setMessage({ message: "Änderungen konnten nicht gespeichert werden", severity: "error" })
        console.error(error)
      }
    )
  }, [drawStateStore, setMessage])

  const withdrawWinner = useCallback(() => {
    const { drawResponse, selected } = drawStateStore.state
    if (!drawResponse || !selected) return
    const { response } = drawResponse
    if (response.recordtype === CDrawResponseTypeManual) {
      resetDrawState()
    } else {
      drawStateStore.update({ saving: { $set: true } })
      withdraw(selected, response).then(
        (response) => {
          drawStateStore.update({ drawResponse: { $set: response }, saving: { $set: false } })
        },
        (error) => {
          drawStateStore.update({ saving: { $set: false } })
          setMessage({ message: "Gewinner konnte nicht verworfen werden: " + error, severity: "error" })
          console.error(error)
        }
      )
    }
  }, [drawStateStore, resetDrawState, setMessage])

  return (
    <Container maxWidth={false}>
      {comp && (
        <Stack spacing={3} paddingBottom={3}>
          <CompetitionPanel competition={comp} />
          <ProjectsListPaper competition={comp} readOnly title="Ziehung erfolgt aus folgenden Projekt(en)" />
          <WinsPanel competition={comp} actionCell={winActionCell} selectedStore={drawStateStore} />
          {drawResponse && (
            <Fragment>
              <ResponseInfo response={drawResponse.response} />
              <WinnerPanel winner={drawResponse.winner} />
            </Fragment>
          )}
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" onClick={goBack}>
              Zurück
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            {drawResponse && (
              <Fragment>
                <LoadingButton
                  variant="outlined"
                  loading={saving}
                  onClick={withdrawWinner}
                  color="error"
                  loadingPosition="start"
                  startIcon={<ClearIcon />}
                >
                  Gewinner verwerfen
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  loading={saving}
                  onClick={saveWinner}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                >
                  Gewinner speichern
                </LoadingButton>
              </Fragment>
            )}
          </Stack>
        </Stack>
      )}
    </Container>
  )
}

function WinActionCell(props: {
  readonly win: GmtWin
  readonly drawStateStore: Store<IDrawState>
  readonly startDrawing: (win: GmtWin, manual?: boolean) => void
}): ReactElement {
  const { drawStateStore, startDrawing, win } = props
  const { selected, drawResponse } = useStore(drawStateStore)
  const winner = useStore(win.dataStore, (state) => state.winner)
  const navigate = useNavigate()

  const onManualClick = useCallback(() => startDrawing(win, true), [startDrawing, win])
  const onStartDrawingClick = useCallback(() => startDrawing(win), [startDrawing, win])
  const onWinnerClick = useCallback(() => {
    if (winner) navigate("/" + CRouteWinners + "/" + CRouteWinnerDetails + "/" + winner.getApiId())
  }, [navigate, winner])
  return winner ? (
    <Button variant="outlined" color="success" startIcon={<CheckCircleIcon />} onClick={onWinnerClick}>
      Gewinner
    </Button>
  ) : (
    <Fragment>
      {(selected !== win || !drawResponse) && (
        <Button variant="outlined" onClick={onManualClick} sx={{ mr: 2 }}>
          Manuell
        </Button>
      )}
      <LoadingButton
        variant="contained"
        onClick={onStartDrawingClick}
        loading={selected === win && drawResponse === null}
        disabled={!!drawResponse || (drawResponse === null && selected !== win)}
        startIcon={<AutoFixHighIcon />}
      >
        {selected === win && drawResponse ? "In Bearbeitung..." : "Ziehen"}
      </LoadingButton>
    </Fragment>
  )
}

interface IDrawState {
  readonly selected?: GmtWin
  readonly drawResponse?: IDrawingResponse | null
  readonly saving?: boolean
}
