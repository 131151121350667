import { ReactElement, useCallback, useMemo } from "react"
import { Spec } from "immutability-helper"

import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { FormHelperText } from "@mui/material"

import { FilteredTypeByPropType, useStore } from "core"
import { getErrorText, ISimpleInputProps } from "./core"
import { IInlineEditingProps, useInlineEditing } from "./useInlineEditing"

export interface IStoreSimpleSelectProps<T extends object, K extends keyof FilteredTypeByPropType<T, string>>
  extends ISimpleInputProps<T, K>,
    IInlineEditingProps<T>,
    Omit<SelectProps<string>, "property" | "onChange"> {
  readonly list: ReadonlyArray<string>
  readonly labels?: Readonly<Record<string, string>>
  readonly emptyLabel?: string
  readonly small?: boolean
  readonly disabledOptions?: ReadonlyArray<string>
}

let idSequence = 1

export function StoreSimpleSelect<T extends object, K extends keyof FilteredTypeByPropType<T, string>>({
  store,
  labels,
  list,
  property,
  label,
  labelId,
  fullWidth,
  emptyLabel,
  required,
  small,
  baseStore,
  doSave,
  errors,
  disabledOptions,
  ...rest
}: IStoreSimpleSelectProps<T, K>): ReactElement {
  const value = useStore(store, (state) => state[property] as unknown as string | undefined, [property])

  const { endAdornment, error, isInline } = useInlineEditing(
    "string",
    store,
    property,
    required,
    baseStore,
    doSave,
    errors
  )

  const onChange = useCallback(
    (event: SelectChangeEvent) => {
      const value = event.target.value
      store.update({
        [property]: { $set: value === "all" ? undefined : value },
      } as Spec<T>)
    },
    [property, store]
  )

  const id = useMemo(() => labelId || property.toString() + "Sel" + idSequence++, [labelId, property])
  return (
    <FormControl fullWidth={fullWidth} error={!!error} size={small ? "small" : undefined}>
      <InputLabel id={id} required={required} shrink className={isInline ? "inlineMode" : undefined}>
        {label}
      </InputLabel>
      <Select
        color={required && !value ? "error" : undefined}
        {...rest}
        required={required}
        label={label}
        labelId={id}
        value={value || "all"}
        onChange={onChange}
        notched
        endAdornment={endAdornment}
        variant={isInline ? "filled" : undefined}
      >
        {(!required || !value) && (
          <MenuItem value={"all"}>
            <em>{emptyLabel || "k.A."}</em>
          </MenuItem>
        )}
        {list.map((option) => (
          <MenuItem key={option} value={option} disabled={disabledOptions?.includes(option)}>
            {(labels && labels[option]) || option}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{getErrorText(error)}</FormHelperText>
    </FormControl>
  )
}
