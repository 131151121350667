import { ReactElement } from "react"

import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import Stack from "@mui/material/Stack"

import { useStore } from "core"
import { GmtSponsor } from "server/model"

export interface ISponsorDetailsProps {
  sponsor: GmtSponsor
}

export default function SponsorDetails(props: ISponsorDetailsProps): ReactElement {
  const { sponsor } = props
  const data = useStore(sponsor.dataStore)
  return (
    <Stack>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Adresse:</TableCell>
              <TableCell>
                {data.address?.address_line1}
                <br />
                {data.address?.address_line2 && data.address.address_line2 + "<br />"}
                {data.address?.postal_code} {data.address?.locality}
              </TableCell>
            </TableRow>
            {data.ustId && (
              <TableRow>
                <TableCell>USt.-ID</TableCell>
                <TableCell>{data.ustId}</TableCell>
              </TableRow>
            )}
            {data.tax && (
              <TableRow>
                <TableCell>Steuernummer</TableCell>
                <TableCell>{data.tax}</TableCell>
              </TableRow>
            )}
            {data.name && (
              <TableRow>
                <TableCell>Kontaktname</TableCell>
                <TableCell>{data.name}</TableCell>
              </TableRow>
            )}
            {data.mail && (
              <TableRow>
                <TableCell>Kontakt E-Mail</TableCell>
                <TableCell>{data.mail}</TableCell>
              </TableRow>
            )}
            {data.phone && (
              <TableRow>
                <TableCell>Kontakttelefon</TableCell>
                <TableCell>{data.phone}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
