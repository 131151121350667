import {
  ChangeEventHandler,
  Dispatch,
  Fragment,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import update from "immutability-helper"

import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import TablePagination from "@mui/material/TablePagination"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import { TableCellProps } from "@mui/material/TableCell/TableCell"

import { createPromiseTerminator, useStore } from "core"
import { GmtModel } from "server/model"
import { IFetchResult, IFetchSpec, IPagerInfo, ISortSpec } from "server/logic"
import { CTablePaginationProps } from "../../utils"
import { CSessionState } from "../../state"
import { CPageSizes } from "../../constants"
import ListTableHead from "./ListTableHead"

import "./ListTable.scss"

export interface IListTableProps<ModelType extends GmtModel, FilterType> extends IListTableBaseProps<ModelType> {
  readonly fetchSpec: IFetchSpec<FilterType>
  readonly setFetchSpec: Dispatch<SetStateAction<IFetchSpec<FilterType>>>
  readonly fetchResult: IFetchResult<ModelType> | undefined
  readonly setFetchResult: Dispatch<SetStateAction<IFetchResult<ModelType> | undefined>>
  readonly doFetch?: (fetchSpec: IFetchSpec<FilterType>) => Promise<IFetchResult<ModelType>>
}

export interface IListTableBaseProps<ModelType> {
  readonly columns: ReadonlyArray<IColumnSpec>
  readonly className?: string
  readonly renderRow: (entry: ModelType) => ReactNode
  readonly exclude?: ReadonlyArray<string>
  readonly data?: ReadonlyArray<ModelType>
}

export interface IColumnSpec extends TableCellProps {
  readonly key: string
  readonly title?: string
  readonly sortKey?: string
}

export default function ListTable<ModelType extends GmtModel, FilterType>(
  props: IListTableProps<ModelType, FilterType> | IListTableBaseProps<ModelType>
): ReactElement {
  const { columns, renderRow, className, exclude, data } = props
  const { fetchSpec, setFetchSpec, fetchResult, setFetchResult, doFetch } = props as Partial<
    IListTableProps<ModelType, FilterType>
  >
  const [reloading, setReloading] = useState(false)
  const isUser = useStore(CSessionState, (state) => !!state.user)

  const onRowsPerPageChange = useCallback<ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>>(
    (event) => {
      if (setFetchSpec) {
        setFetchSpec((spec) =>
          update(spec, { pager: { pageSize: { $set: parseInt(event.target.value) }, page: { $set: 0 } } })
        )
      }
    },
    [setFetchSpec]
  )
  const onPageChange = useCallback(
    (event: unknown, page: number) => {
      if (setFetchSpec) {
        setFetchSpec((spec) => update(spec, { pager: { page: { $set: page } } }))
      }
    },
    [setFetchSpec]
  )

  // fetch when fetch spec changes
  useEffect(() => {
    if (!isUser || !doFetch || !setFetchResult || !fetchSpec) return
    setReloading(true)
    return createPromiseTerminator(doFetch(fetchSpec), (result) => {
      setFetchResult(result)
      setReloading(false)
    })
  }, [fetchSpec, isUser, doFetch, setFetchResult])

  const finalPager = fetchResult?.pager || fetchSpec?.pager
  const finalColumns = useMemo(
    () => (exclude?.length ? columns.filter((entry) => !exclude.includes(entry.key)) : columns),
    [columns, exclude]
  )

  const finalData = useMemo(
    () => (data ? (fetchResult?.data ? [...data, ...fetchResult.data] : data) : fetchResult?.data),
    [data, fetchResult?.data]
  )
  return (
    <Fragment>
      <TableContainer className={"ListTable " + className}>
        <Table size="small">
          <ListTableHead setFetchSpec={setFetchSpec} columns={finalColumns} sort={fetchSpec?.sort} />
          <TableBody>
            {finalData?.length ? (
              <Fragment>{finalData.map(renderRow)}</Fragment>
            ) : (
              <TableRow>
                <TableCell colSpan={finalColumns.length}>
                  {finalData ? "Keine Ergebnisse" : "Wird geladen..."}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {reloading && (
          <Backdrop open>
            <CircularProgress />
          </Backdrop>
        )}
      </TableContainer>
      {!!doFetch && finalPager && (
        <TablePagination
          {...CTablePaginationProps}
          rowsPerPageOptions={CPageSizes}
          component="div"
          count={fetchResult?.count || 0}
          rowsPerPage={finalPager.pageSize}
          page={finalPager.page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
    </Fragment>
  )
}

const CDefaultPager: IPagerInfo = {
  page: 0,
  pageSize: CPageSizes[1],
}

const CDefaultFilter = {}

export function useFetchSpec<FilterType>(
  filterArg: FilterType | undefined,
  pager = CDefaultPager,
  sort?: ISortSpec
): [IFetchSpec<FilterType>, Dispatch<SetStateAction<IFetchSpec<FilterType>>>] {
  const filter = filterArg || (CDefaultFilter as FilterType)
  const [fetchSpec, setFetchSpec] = useState<IFetchSpec<FilterType>>({ filter, pager, sort })
  useEffect(() => {
    setFetchSpec((fs) => update(fs, { filter: { $set: filter }, pager: { $set: pager }, sort: { $set: sort } }))
  }, [filter, pager, sort])
  return [fetchSpec, setFetchSpec]
}
