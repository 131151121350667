import { GmtModel } from "./core"
import { ToManyRelation } from "../../coloquent"

export class GmtCategory extends GmtModel {
  static jsonApiType = "taxonomy_term--1conceptscategories"
  static endpoint = "taxonomy_term/1conceptscategories"

  get name(): string {
    return this.getAttribute("name")
  }

  get code(): string {
    return this.getAttribute("field_1cpncepts_category_code")
  }

  get categoryId(): number {
    return this.getAttribute("field_1concepts_category_id")
  }

  get parent(): string {
    return this.getRelation("field_1concepts_category_parent")
  }

  field_1concepts_category_parent(): ToManyRelation<GmtCategory> {
    return this.hasMany(GmtCategory)
  }
}
