import { Fragment, ReactElement, useCallback, useMemo, useState } from "react"

import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Typography from "@mui/material/Typography"
import AccordionDetails from "@mui/material/AccordionDetails"

import { Store, useStore } from "core"
import { GmtCompetition } from "server/model"
import { IFetchHistoryFilter } from "server/logic/fetchHistory"
import HistoriesList from "ui/components/histories/HistoryList"
import HistoryFilter from "ui/components/histories/HistoryFilter"

export default function CompetitionHistory({ comp: competition }: { readonly comp: GmtCompetition }): ReactElement {
  const [expanded, setExpanded] = useState(false)
  const handleChange = useCallback(() => setExpanded((expanded) => !expanded), [])
  const baseData = useStore(competition.baseStore)
  const filterStore = useMemo(
    () =>
      Store.create<IFetchHistoryFilter>({
        parentId: competition.nid(),
        createdMin: null,
        createdMax: null,
        label: "",
        oldValue: "",
        newValue: "",
      }),
    [competition]
  )
  const preFilter = useStore(filterStore)

  // this is a hack to update the history on change of project data
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filter = useMemo(() => ({ ...preFilter }), [preFilter, baseData])

  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" component="h6">
          Änderungshistorie
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {expanded && (
          <Fragment>
            <HistoryFilter filterStore={filterStore} />
            <HistoriesList filter={filter} />
          </Fragment>
        )}
      </AccordionDetails>
    </Accordion>
  )
}
