import { ReactElement, useEffect, useMemo } from "react"
import { FilteredTypeByPropType, Store, useStore } from "../../../core"
import { ISimpleTextFieldProps, StoreTextField } from "./StoreTextField"
import { Spec } from "immutability-helper"

export interface IStoreDelayedTextFieldProps<T extends object, K extends keyof FilteredTypeByPropType<T, string>>
  extends ISimpleTextFieldProps<T, K> {
  readonly delay?: number
}

export function StoreDelayedTextField<T extends object, K extends keyof FilteredTypeByPropType<T, string>>(
  props: IStoreDelayedTextFieldProps<T, K>
): ReactElement {
  const { store, property, delay, baseStore, ...rest } = props
  const delayedStore = useMemo(
    () => Store.create({ value: store.state[property] as unknown as string | undefined }),
    [property, store]
  )
  const storeValue = useStore(store, (state) => state[property] as unknown as string | undefined, [property])
  const delayedValue = useStore(delayedStore, (state) => state.value)
  // sync store value
  useEffect(() => {
    delayedStore.update({ value: { $set: storeValue } })
  }, [delayedStore, storeValue])
  // delayed sync changed value
  useEffect(() => {
    const timeout = window.setTimeout(() => {
      store.update({ [property]: { $set: delayedValue } } as Spec<T>)
    }, delay || 500)
    return () => {
      window.clearTimeout(timeout)
    }
  }, [delay, delayedValue, property, store])
  return <StoreTextField {...rest} store={delayedStore} property="value" />
}
