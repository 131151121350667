import { GmtBrand, GmtProduct } from "../../model"
import { ConditionFilterSpec } from "../../../coloquent/filter/ConditionFilterSpec"
import { fetchModel, IFetchResult, IFetchSpec } from "../core"
import { GroupFilterSpec } from "../../../coloquent/filter/GroupFilterSpec"

const CRelsIncluded = ["field_1concepts_product_category", "field_1concepts_product_brand"]

export async function fetchProduct(id: string): Promise<GmtProduct> {
  const result = await GmtProduct.find(id, CRelsIncluded)
  const rv = result.getData()
  if (!rv) throw "Not found"
  return rv
}

export async function fetchProducts(spec: IFetchSpec<IFetchProductFilter>): Promise<IFetchResult<GmtProduct>> {
  const { pager, filter, sort } = spec
  GmtProduct.setPageSize(pager.pageSize)
  let query = GmtProduct.with(CRelsIncluded)

  if (filter.text) {
    query = query.filter(
      new GroupFilterSpec("OR", [
        new ConditionFilterSpec("title", "CONTAINS", filter.text),
        new ConditionFilterSpec("field_1concepts_product_brand.name", "CONTAINS", filter.text),
        new ConditionFilterSpec("field_1concepts_product_sku", "CONTAINS", filter.text),
        new ConditionFilterSpec("field_1concepts_product_id", "CONTAINS", filter.text),
      ])
    )
  }
  if (filter.brand) {
    query = query.where("field_1concepts_product_brand.id", filter.brand.getApiId() || "_na_")
  }
  if (filter.favorite) {
    query = query.where("field_1concepts_product_favorite", "1")
  }

  const finalSort = sort || { key: "field_1concepts_product_favorite", desc: true }
  return await fetchModel(query, pager, finalSort)
}

export interface IFetchProductFilter {
  readonly text?: string
  readonly brand?: GmtBrand
  readonly favorite?: boolean
}
