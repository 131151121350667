import { ReactElement, ReactNode } from "react"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"

export interface IDetailsEntry {
  readonly title: string
  readonly value: ReactNode
}

export default function DetailsTable({
  data,
  small,
}: {
  readonly data: ReadonlyArray<IDetailsEntry>
  readonly small?: boolean
}): ReactElement {
  return (
    <Table size={small ? "small" : undefined}>
      <TableBody>
        {data.map(({ title, value }, idx) => (
          <TableRow key={idx}>
            <TableCell component="th">{title}</TableCell>
            <TableCell>{value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
