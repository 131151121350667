export function parseCurrency(input: string | undefined): string | undefined {
  if (!input) {
    return undefined
  }
  let replaced = input.replace(".", "").replace(",", ".")
  if (!replaced.includes(".") && !replaced.includes(",")) {
    replaced += ".00"
  }

  return replaced
}
