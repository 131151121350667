import { ReactElement } from "react"

import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import WinnersSearch from "./WinnersSearch"

export default function WinnersOverview(): ReactElement {
  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <WinnersSearch />
        </Grid>
      </Grid>
    </Container>
  )
}
