import { Model } from "../Model"
import { Response } from "./Response"
import { JsonApiResponseBody } from "../JsonApiResponseBody"
import { HttpClientResponse } from "../httpclient/HttpClientResponse"
import { Resource } from "../Resource"

export class SaveResponse<M extends Model = Model> extends Response {
  protected readonly model: M | null

  constructor(httpClientResponse: HttpClientResponse, modelType: { new (): M }, responseBody: JsonApiResponseBody) {
    super(undefined, httpClientResponse)
    const data = responseBody.data
    if (data) {
      const model = new modelType()
      model.populateFromResource(data as Resource)
      this.model = model
    } else {
      this.model = null
    }
  }

  public getModel(): M | null {
    return this.model
  }

  public getModelId(): string | undefined {
    return this.model !== null ? this.model.getApiId() : undefined
  }
}
