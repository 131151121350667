import { GmtProject, GmtSender, TParticipationType, TProjectState } from "../../model"
import { fetchModel, IFetchResult, IFetchSpec } from "../core"
import { ConditionFilterSpec } from "../../../coloquent/filter/ConditionFilterSpec"
import { SimpleFilterSpec } from "../../../coloquent/filter/SimpleFilterSpec"
import { getAllSenders } from "../fetchSender"
import { getSharedKeywordInstances } from "../keywords"
import { isNumber } from "../shared"

const CPrefetchedRelations = [
  "field_game_mechanics",
  "field_project_manager",
  "field_sender.field_station_logo",
  "field_service_number_online",
  "field_service_number_tv",
  "field_sms_keywords_tv",
  "field_sms_keywords_online",
  "field_ucp_shortcode",
  "field_documents",
  "field_client_ref",
]

export function getPrefetchRelationsForProject(prefix: string): ReadonlyArray<string> {
  return CPrefetchedRelations.map((entry) => prefix + entry)
}

export async function fetchProject(id: string): Promise<GmtProject | null> {
  const result = await GmtProject.find(id, CPrefetchedRelations)
  const rv = result.getData()
  return rv && prepareProject(rv, await getAllSenders())
}

export async function fetchProjects(spec: IFetchSpec<IFetchProjectsFilter>): Promise<IFetchResult<GmtProject>> {
  const { pager, filter, sort } = spec
  GmtProject.setPageSize(pager.pageSize)
  let query = GmtProject.with(CPrefetchedRelations)
  if (filter.text && isNumber(filter.text)) {
    query = query.where("drupal_internal__nid", filter.text)
  }
  if (filter.text && !isNumber(filter.text)) {
    query = query.filter(new ConditionFilterSpec("title", "CONTAINS", filter.text))
  }
  if (filter.state) {
    query = query.filter(new SimpleFilterSpec("field_status", filter.state))
  }
  if (filter.participationType) {
    query = query.filter(new SimpleFilterSpec("field_participation_route", filter.participationType))
  }
  if (filter.exclude?.length) {
    query = query.filter(
      new ConditionFilterSpec(
        "id",
        "NOT IN",
        filter.exclude.map((project) => (typeof project === "string" ? project : project.getApiId() || ""))
      )
    )
  }
  if (filter.include?.length) {
    query = query.filter(
      new ConditionFilterSpec(
        "id",
        "IN",
        filter.include.map((project) => (typeof project === "string" ? project : project.getApiId() || ""))
      )
    )
  }
  if (filter.sendingId) {
    query = query.filter(new ConditionFilterSpec("field_sendungs_id", "IS NOT NULL"))
  }
  if (filter.projectManager) {
    query = query.filter(new ConditionFilterSpec("field_project_manager.name", "CONTAINS", filter.projectManager))
  }
  const rv: IFetchResult<GmtProject> = await fetchModel(query, pager, sort)
  const senders = await getAllSenders()
  return { ...rv, data: rv.data.map((entry) => prepareProject(entry, senders)) }
}

export interface IFetchProjectsFilter {
  readonly text?: string
  readonly state?: TProjectState
  readonly participationType?: TParticipationType
  readonly exclude?: ReadonlyArray<GmtProject | string>
  readonly include?: ReadonlyArray<GmtProject | string>
  readonly sendingId?: boolean
  readonly projectManager?: string
}

function prepareProject(project: GmtProject, senders: ReadonlyArray<GmtSender>): GmtProject {
  const { sender, keywordsTv, keywordsOnline } = project.dataStore.state

  const senderId = sender?.getApiId()
  if (senderId && senders.length) {
    project.dataStore.update({ sender: { $set: senders.find((entry) => entry.getApiId() === senderId) } })
  }

  project.dataStore.update({
    keywordsTv: { $set: getSharedKeywordInstances(keywordsTv) },
    keywordsOnline: { $set: getSharedKeywordInstances(keywordsOnline) },
  })

  project.resetBaseStore()
  return project
}
