import { ChangeEventHandler, Dispatch, ReactElement, SetStateAction, useCallback, useState } from "react"
import update from "immutability-helper"

import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"

import SearchIcon from "@mui/icons-material/Search"
import { IFetchSponsorsFilter } from "server/logic/fetchSponsors"

import "./SponsorsFilter.scss"

export interface ISponsorsFilterProps {
  readonly filter: IFetchSponsorsFilter
  readonly setFilter: Dispatch<SetStateAction<IFetchSponsorsFilter>>
}

export default function SponsorsFilter(props: ISponsorsFilterProps): ReactElement {
  const { filter, setFilter } = props

  const [text, setText] = useState<string>(filter.text || "")
  const onTextChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const value = event.target.value
      setText(value)
      setFilter((filter) => update(filter, { text: { $set: value.trim().length < 3 ? "" : value.trim() } }))
    },
    [setFilter]
  )
  return (
    <Box id="SponsorsFilter" paddingLeft={2}>
      <Box marginRight={2} marginBottom={2} className="searchBox">
        <TextField
          label="Suche"
          value={filter.text || text}
          onChange={onTextChange}
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  )
}
