import { createTheme } from "@mui/material/styles"

export const CTheme = createTheme({
  palette: {
    primary: { main: "#1e3144" },
    secondary: { main: "#e72b37" },
    background: {
      default: "#f6f6f6",
    },
    prosieben: {
      main: "#000000",
      text: "#757575",
      blue: "#1e3144",
      red: "#e72b37",
      green: "#42BD41",
      chip: "#F0F0F0",
      active: "#1e3144",
      grey: "#8C8C8C",
      lightGrey: "#D8D8D8",
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          minHeight: "56px",
        },
      },
    },
  },
})

declare module "@mui/material/styles" {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Palette {
    prosieben: {
      light: string
      main: string
      text: string
      blue: string
      red: string
      green: string
      chip: string
      active: string
      grey: string
      lightGrey: string
      dark: string
      contrastText: string
    }
  }

  // allow configuration using `createTheme
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface PaletteOptions {
    prosieben?: {
      light?: string
      main: string
      text: string
      blue?: string
      red?: string
      green?: string
      chip?: string
      active?: string
      grey?: string
      lightGrey?: string
      dark?: string
      contrastText?: string
    }
  }
}
