import { ReactElement, useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"

import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"

import NotesIcon from "@mui/icons-material/Notes"
import IconButton from "@mui/material/IconButton"

import { Store, useStore } from "core"
import { IFetchProjectsFilter } from "server/logic/projects"
import ProjectsList from "ui/components/projects/ProjectsList"
import ProjectsFilter from "ui/components/projects/ProjectsFilter"
import { CRouteProjects } from "../../../routes"
import { CRouteProjectDetails, CRouteProjectsCreate } from "../routes"

export default function ProjectsSearch(): ReactElement {
  const navigate = useNavigate()
  const filterStore = useMemo(() => Store.create<IFetchProjectsFilter>({ text: "" }), [])
  const filter = useStore(filterStore)
  const createNew = useCallback(() => navigate(CRouteProjectsCreate), [navigate])
  return (
    <Paper id="ProjectsSearch">
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Alle Projekte
        </Typography>
        <Button variant="contained" onClick={createNew}>
          Projekt anlegen
        </Button>
      </Toolbar>
      <ProjectsFilter filterStore={filterStore} />
      <ProjectsList
        filter={filter}
        renderDetails={(project) => (
          <IconButton
            onClick={() => navigate("/" + CRouteProjects + "/" + CRouteProjectDetails + "/" + project.getApiId())}
          >
            <NotesIcon />
          </IconButton>
        )}
      />
    </Paper>
  )
}
