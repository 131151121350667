import { Store, useStore } from "../../../core"
import { IFetchHistoryFilter } from "../../../server/logic"
import { ReactElement } from "react"
import { StoreDatePicker, StoreDelayedTextField } from "../forms"
import SearchIcon from "@mui/icons-material/Search"
import { Stack } from "@mui/material"

export interface IHistoryFilterProps {
  readonly filterStore: Store<IFetchHistoryFilter>
}

export default function HistoryFilter(props: IHistoryFilterProps): ReactElement {
  const { filterStore } = props
  const createdStartDate = useStore(filterStore, (state) => state.createdMin)
  const createdEndDate = useStore(filterStore, (state) => state.createdMax)
  return (
    <Stack direction="row" mb={3} spacing={2}>
      <StoreDelayedTextField label="Feld" store={filterStore} property="label" icon={<SearchIcon />} size="small" />
      <StoreDatePicker
        label="Änderungen von"
        store={filterStore}
        property="createdMin"
        size="small"
        maxDate={createdEndDate}
        disableFuture
      />
      <StoreDatePicker
        label="Änderungen bis"
        store={filterStore}
        property="createdMax"
        size="small"
        minDate={createdStartDate}
        disableFuture
      />
      <StoreDelayedTextField
        label="Neuer Wert"
        store={filterStore}
        property="newValue"
        icon={<SearchIcon />}
        size="small"
      />
      <StoreDelayedTextField
        label="Alter Wert"
        store={filterStore}
        property="oldValue"
        icon={<SearchIcon />}
        size="small"
      />
    </Stack>
  )
}
