import { Fragment, ReactElement, useMemo } from "react"
import de from "date-fns/locale/de"
import format from "date-fns/format"

import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"

import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"

import { useStore } from "core"
import { GmtCompetition } from "server/model"

export interface ICompetitionEventsProps {
  readonly comp: GmtCompetition
}

export default function CompetitionEvents(props: ICompetitionEventsProps): ReactElement | null {
  const {
    comp: { dataStore },
  } = props
  const isActive = useStore(dataStore, (state) => !!state.gameStatus && state.gameStatus !== "Entwurf")
  const onlinePublished = useStore(dataStore, (state) => state.onlinePublished)
  const teletextPublished = useStore(dataStore, (state) => state.teletextPublished)
  const exported = useStore(dataStore, (state) => state.exported)?.at(-1)

  const events = useMemo(
    () => [
      {
        title: "Gewinnarenaexport",
        date: exported ? format(Date.parse(exported), "P, p", { locale: de }) : "",
      },
      {
        title: "Lösungsveröffentlichung online",
        date: onlinePublished ? format(onlinePublished, "P, p", { locale: de }) : "",
      },
      {
        title: "Lösungsveröffentlichung Teletext",
        date: teletextPublished ? format(teletextPublished, "P, p", { locale: de }) : "",
      },
    ],
    [exported, onlinePublished, teletextPublished]
  )

  return isActive ? (
    <Stack direction="row" mb={3} spacing={3}>
      {events.map((event, key) => (
        <Paper key={key} elevation={3} sx={{ flexGrow: 1 }}>
          <Typography variant="caption" mt={1} display="block" gutterBottom>
            {event.title}
          </Typography>
          <Typography color={"green"} variant="inherit" gutterBottom component="div" width={"100%"}>
            {event.date ? (
              <Fragment>
                {event.date}{" "}
                <CheckCircleIcon
                  sx={{ verticalAlign: "text-top", width: "0.875rem", height: "0.875rem", fontSize: "0.875rem" }}
                />
              </Fragment>
            ) : (
              <CancelIcon
                color={"warning"}
                sx={{ verticalAlign: "text-top", width: "0.875rem", height: "0.875rem", fontSize: "0.875rem" }}
              />
            )}
          </Typography>
        </Paper>
      ))}
    </Stack>
  ) : null
}
