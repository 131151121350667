import { ReactElement, useMemo } from "react"

import Box from "@mui/material/Box"

import { concatArrays } from "core"
import { GmtServiceNumber } from "server/model"
import { entitiesEqual } from "server/logic"
import ServiceNumberChip from "./ServiceNumberChip"

export default function ServiceNumbersChipList({
  tv,
  online,
  onDelete,
}: {
  readonly online?: ReadonlyArray<GmtServiceNumber>
  readonly tv?: ReadonlyArray<GmtServiceNumber>
  readonly onDelete?: (value: GmtServiceNumber) => void
}): ReactElement {
  const allEntries = useMemo(() => concatArrays(tv, online, entitiesEqual), [tv, online])
  return (
    <Box className="ChipList">
      {allEntries.map((entry) => (
        <ServiceNumberChip
          key={entry.getApiId()}
          value={entry}
          online={!!online?.includes(entry)}
          onDelete={onDelete}
        />
      ))}
    </Box>
  )
}
