import { GmtModel } from "./core"
import { TParticipationType } from "./shared"

// noinspection JSNonASCIINames
export const CGameMechanicsConfig: Readonly<
  Record<
    number,
    | Readonly<{
        participationTypes: ReadonlyArray<TParticipationType>
        question?: string | boolean // show question field with default text
        smsKeyword?: boolean // show sms-password field
        textKeyword?: boolean // show freetext-password field
        answers?: boolean // show answer options field
      }>
    | undefined
  >
> = {
  33: {
    // GWS: Gewinnspiel mit Kennwort
    participationTypes: ["hybrid", "online"],
    question: "Welche Kennwörter haben wir gesucht?",
    smsKeyword: true,
  },
  34: {
    // GWS: Gewinnspiel Kennwort/Antwort
    participationTypes: ["phone"],
    question: "Welche Kennwörter haben wir gesucht?",
    smsKeyword: true,
  },
  35: {
    // GTL: Leitungsspiel Kennwort/Antwort
    participationTypes: ["phone"],
    question: "Welche Kennwörter haben wir gesucht?",
    smsKeyword: true,
  },
  36: {
    // GTL: Leitungsspiel mit Kennwort
    participationTypes: ["hybrid", "online"],
    question: "Welche Kennwörter haben wir gesucht?",
    smsKeyword: true,
  },
  37: {
    // VOT: Voting mit Gewinnspiel
    participationTypes: ["phone", "hybrid"],
    question: "Stimme mit einem der folgenden Kennwörter für deinen Favoriten ab",
    smsKeyword: true,
  },
  38: {
    // VOG: Voting ohne Gewinnspiel
    participationTypes: ["phone"],
    smsKeyword: true,
  },
  39: {
    // SCH: Schätzspiel
    participationTypes: ["phone"],
    question: "Gewonnen hat die nächste Schätzung an dieser Zahl:",
    textKeyword: true,
  },
  40: {
    // COG: Code Gewinnspiel
    participationTypes: ["phone"],
    question: "Gib einen korrekten Code an",
    textKeyword: true,
  },
  41: {
    // SC: Surprise Call
    participationTypes: ["phone"],
    question: "Triff eines der Gewinnerzeitfenster",
  },
  2001: {
    // NET: Netigo
    participationTypes: ["online"],
    question: "Lösung: Spiele das Minispiel und erreiche genug Punkte, um in den Lostopf zu kommen.",
  },
  43: {
    // GWA: Gewinnspiel mit Freitextantwort
    participationTypes: ["online"],
    question: true,
    textKeyword: true,
  },
  44: {
    // GTL: Code Lotterie - Leitungsspiel/Netigo
    participationTypes: ["hybrid"],
    question:
      "Du musstest das Los bekommen haben, dass bei der Ziehung für den Gewinn gezogen wurde. Gewinner:innen werden über den Teilnahmeweg über einen Gewinn benachrichtigt.",
  },
  4111: {
    // PUG: Picture Upload
    participationTypes: ["online"],
    question: "Schick uns ein Bild von",
    textKeyword: true,
  },
  4110: {
    // Voting
    participationTypes: ["online"],
    question: "Welche Optionen gibt es?",
    textKeyword: true,
    answers: true,
  },
  4109: {
    // GWP: Gewinnspiel für Print
    participationTypes: ["online"],
    question: "Gib das richtige Lösungskennwort an",
    textKeyword: true,
  },
  4108: {
    // GSC: Gewinnspiel und Surprise Call
    participationTypes: ["online"],
    question: "Triff das Gewinnerzeitfenster",
    smsKeyword: true,
  },
}

export function isLineGame(gameMechanics: GmtGameMechanics | undefined | null): boolean {
  return !!gameMechanics?.tid && [35, 36].includes(gameMechanics.tid)
}

export function isSCGame(gameMechanics: GmtGameMechanics | undefined | null): boolean {
  return !!gameMechanics?.tid && [41, 48].includes(gameMechanics.tid)
}

export class GmtGameMechanics extends GmtModel {
  static jsonApiType = "taxonomy_term--game_mechanics"
  static endpoint = "taxonomy_term/game_mechanics"

  get name(): string {
    return this.getAttribute("name")
  }

  get tid(): number {
    return this.getAttribute("drupal_internal__tid")
  }
}
