import { ReactElement, useCallback, useMemo } from "react"

import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"

import { useStore } from "core"
import { GmtCashPrice, GmtWin } from "server/model"
import { createPrice } from "server/logic/competitions"
import { useDrawer, useMessages } from "../../LayoutContext"
import CashPricePanel from "../CashPricePanel"

export default function CashPriceTab({ win }: { readonly win: GmtWin }): ReactElement {
  const drawer = useDrawer()
  const setMessage = useMessages()
  const price = useMemo(
    () => new GmtCashPrice().initialize({ title: "Geldpreis: " + win.dataStore.state.title, winningAmount: "" }),
    [win]
  )
  const isNoValue = useStore(price.dataStore, (data) => !data.winningAmount?.trim())

  const onClick = useCallback(() => {
    createPrice(win, price).then((success) => {
      if (!success) {
        setMessage({ message: "Unerwarteter Fehler beim Anlegen des Geldpreises", severity: "error" })
      }
    })
    drawer()
  }, [drawer, price, setMessage, win])

  return (
    <CashPricePanel price={price}>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <Button variant="contained" onClick={onClick} disabled={isNoValue} fullWidth>
          Preis hinzufügen
        </Button>
      </Grid>
    </CashPricePanel>
  )
}
