import flyd from "flyd"
import flydObj from "flyd/module/obj"
import { dropRepeats } from "flyd/module/droprepeats"
import update, { Spec } from "immutability-helper"
import { getObjectProps } from "./utils"

export class Store<T> {
  static create<T>(): Store<T | undefined>
  static create<T>(initialState: Readonly<T>): Store<T>
  static create<T>(initialStateFactory: () => Readonly<T>): Store<T>
  static create<T>(initialState?: Readonly<T> | (() => Readonly<T>)): Store<T | undefined>
  static create<T>(initialState?: Readonly<T> | (() => Readonly<T>)): Store<T | undefined> {
    return new Store(flyd.stream<T | undefined>(typeof initialState === "function" ? initialState() : initialState))
  }

  static combine<T>(mapOb: {
    [P in keyof T]: Store<T[P]>
  }): Store<T> {
    const streamOb: { [P in keyof T]?: flyd.Stream<T[P]> } = {}
    for (const key of getObjectProps(mapOb)) streamOb[key] = mapOb[key].stream.pipe(dropRepeats)
    return new Store<T>(flydObj.stream(streamOb as { [P in keyof T]: flyd.Stream<T[P]> }) as unknown as flyd.Stream<T>)
  }

  get state(): Readonly<T> {
    return this.stream()
  }

  update(spec: Spec<T> | ((state: Readonly<T>) => Spec<T>)): Readonly<T> {
    const state = this.stream()
    const finalSpec = (typeof spec === "function" ? spec(state) : spec) as Spec<T>
    const newState = update(state, finalSpec)
    if (newState !== state) this.stream(newState)
    return newState
  }

  set(value: T | ((state: Readonly<T>) => Readonly<T>)): void {
    this.stream(typeof value === "function" ? (value as (state: Readonly<T>) => T)(this.stream()) : value)
  }

  listen<R>(mapFn: (state: Readonly<T>) => R, setFn?: (mappedState: R) => void): () => void {
    const mapListener = this.stream.pipe(dropRepeats).pipe(flyd.map(mapFn))
    const listener = setFn ? mapListener.pipe(dropRepeats).map(setFn) : mapListener
    return () => listener.end(true)
  }

  private readonly stream: flyd.Stream<T>

  private constructor(stream: flyd.Stream<T>) {
    this.stream = stream
  }
}
