import { useCallback, useState } from "react"
import { GmtProject } from "../../../server/model"
import ProjectDetailsDrawer from "./ProjectDetailsDrawer"
import { useDrawer } from "../LayoutContext"

export interface IProjectDetailsDrawer {
  readonly selectedProject: GmtProject | undefined
  readonly showProjectDrawer: (project: GmtProject) => void
}

export function useProjectDetailsDrawer(): IProjectDetailsDrawer {
  const drawer = useDrawer()
  const [selectedProject, setSelectedProject] = useState<GmtProject>()
  const showProjectDrawer = useCallback(
    (project: GmtProject) => {
      setSelectedProject(project)
      drawer({
        title: "Projekt Details",
        width: 620,
        element: <ProjectDetailsDrawer project={project} />,
        onClose: () => {
          setSelectedProject(undefined)
        },
      })
    },
    [drawer]
  )
  return { selectedProject, showProjectDrawer }
}
