import axios from "axios"
import { saveAs } from "file-saver"
import format from "date-fns/format"
import { CBaseUrl } from "../constants"
import { GmtProject } from "../model"

export interface ICreateBriefingResponse {
  readonly receivers: ReadonlyArray<string>
  readonly subject: string
  readonly body: string
  readonly uri: string
}

export async function createBriefing(project: GmtProject): Promise<ICreateBriefingResponse> {
  const nid = project.nid()
  if (!nid) throw "Missing nid in project: " + project.getApiId()
  const response = await axios.get(CBaseUrl + "/jsonapi/briefing/" + nid)
  return response.data
}

export async function downloadBriefing(project: GmtProject, { uri }: ICreateBriefingResponse): Promise<void> {
  const { data } = await axios({
    url: uri,
    method: "GET",
    responseType: "blob",
  })
  const title = (project.dataStore.state.title || "").replace(/[^a-zA-Z0-9äöüÄÖÜß]/, "_").replace(/_+/, "_")
  const ts = format(new Date(), "yyMMdd-HHmmss")
  saveAs(data, ts + "-" + title + ".pdf")
}
