import { ReactElement, useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"

import { CGameMechanicsConfig, GmtCompetition, GmtProject, GmtWin } from "server/model"
import { saveCompetition } from "server/logic/competitions"
import { CRouteCompetitions } from "../../../routes"
import { useMessages } from "../../../components/LayoutContext"
import { CRouteCompetitionDetails, CRouteCompetitionsCreate } from "../routes"
import StepOne from "./StepOne"
import StepTwo from "./StepTwo"
import StepThree from "./StepThree"
import { fetchProject } from "../../../../server/logic/projects"
import { createPromiseTerminator } from "../../../../core"
import LoadingIndicator from "../../../components/shared/LoadingIndicator"

const steps = ["Projekt", "Gewinnspieldetails", "Gewinn(e)"]

export default function NewCompetition(): ReactElement {
  const projectId = useSearchParams()[0].get("project")
  const [step, setStep] = useState(0)
  const [update, forceUpdate] = useState(0)
  const navigate = useNavigate()
  const setMessage = useMessages()
  const competition = useMemo(() => {
    const win = new GmtWin().initialize({
      title: "Gewinn Nº1",
      prices: [],
    })
    return new GmtCompetition().initialize({
      gameStatus: "Entwurf",
      title: "",
      participationStartDate: null,
      participationEndDate: null,
      project: null,
      additionalProjects: [],
      wins: [win],
      smsKeywords: [],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update])

  const goBack = useCallback(() => {
    setStep((step) => Math.max(0, step - 1))
  }, [])
  const goNext = useCallback(() => {
    setStep((step) => Math.min(steps.length - 1, step + 1))
  }, [])
  const onSelectProject = useCallback(
    (project: GmtProject) => {
      competition.dataStore.update({ project: { $set: project } })
      const { question } = CGameMechanicsConfig[project.dataStore.state.gameMechanics?.tid || 0] || {}
      if (typeof question === "string") {
        competition.dataStore.update({ question: { $set: question } })
      }
      setStep(1)
    },
    [competition]
  )
  const onSave = useCallback(
    (again?: boolean) =>
      saveCompetition(competition).then(
        () => {
          const id = competition.getApiId()
          if (id && again) {
            const projectId = competition.dataStore.state.project?.getApiId()
            if (projectId) {
              forceUpdate((v) => v + 1)
              navigate(
                "/" + CRouteCompetitions + "/" + CRouteCompetitionsCreate + "?project=" + encodeURIComponent(projectId)
              )
            }
          } else if (id && !again) {
            navigate("/" + CRouteCompetitions + "/" + CRouteCompetitionDetails + "/" + id)
          } else {
            setMessage({ message: "Gewinnspiel konnte nicht gespeichert werden", severity: "error" })
          }
        },
        () => {
          setMessage({ message: "Gewinnspiel konnte nicht gespeichert werden", severity: "error" })
        }
      ),
    [competition, navigate, setMessage]
  )

  const [isFetchingProject, setIsFetchingProject] = useState(!!projectId)

  useEffect(() => {
    if (!projectId) {
      setIsFetchingProject(false)
      return
    }
    return createPromiseTerminator(
      fetchProject(projectId),
      (project) => {
        if (project && competition.dataStore.state.project?.getApiId() !== project.getApiId()) onSelectProject(project)
        else setIsFetchingProject(false)
      },
      (error) => {
        setIsFetchingProject(false)
        console.error(error)
      }
    )
  }, [competition, onSelectProject, projectId])

  return (
    <Container maxWidth={false} disableGutters>
      <Stack spacing={2} padding={2}>
        <Stepper activeStep={step} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {step === 0 ? (
          isFetchingProject ? (
            <LoadingIndicator />
          ) : (
            <StepOne onSelectProject={onSelectProject} />
          )
        ) : step === 1 ? (
          <StepTwo goBack={goBack} goNext={goNext} competition={competition} />
        ) : step === 2 ? (
          <StepThree goBack={goBack} onSave={onSave} competition={competition} />
        ) : null}
      </Stack>
    </Container>
  )
}
