import { ChangeEventHandler, ReactElement, useCallback, useEffect, useMemo, useState } from "react"
import { Spec } from "immutability-helper"

import TextField from "@mui/material/TextField"
import { TextFieldProps } from "@mui/material/TextField/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import { InputProps as StandardInputProps } from "@mui/material/Input/Input"

import { createPromiseTerminator, FilteredTypeByPropType, useStore } from "core"
import { getErrorText, ISimpleInputProps } from "./core"
import { IInlineEditingProps, useInlineEditing } from "./useInlineEditing"

export interface ISimpleTextFieldProps<T extends object, K extends keyof FilteredTypeByPropType<T, string>>
  extends ISimpleInputProps<T, K>,
    IInlineEditingProps<T>,
    Omit<TextFieldProps, "value" | "onChange" | "sx" | "InputLabelProps" | "property" | "defaultValue"> {
  readonly icon?: ReactElement
  readonly asyncValidate?: () => Promise<string>
  readonly arraySplitter?: string
  readonly inputTransformer?: (value: string, fromInput: boolean) => string
}

export function StoreTextField<T extends object, K extends keyof FilteredTypeByPropType<T, string>>(
  props: ISimpleTextFieldProps<T, K>
): ReactElement {
  const {
    store,
    property,
    icon,
    errors,
    helperText,
    InputProps,
    doSave,
    baseStore,
    required,
    asyncValidate,
    arraySplitter,
    inputTransformer,
    ...rest
  } = props
  const value = useStore(
    store,
    (state) => {
      let value = state[property] as string | ReadonlyArray<string> | { uri: string }
      if (Array.isArray(value) && arraySplitter) {
        value = value.join(arraySplitter)
      }
      if (property === "link" && value) {
        value = (value as { uri: string }).uri
      }
      return value || ""
    },
    [property]
  )
  const displayValue = inputTransformer && typeof value === "string" ? inputTransformer(value, false) : value

  const [asyncValidationError, setAsyncValidationError] = useState("")

  const { endAdornment, error, isInline } = useInlineEditing(
    rest.type === "number" ? "number" : property === "link" ? "link" : "string",
    store,
    property,
    required,
    baseStore,
    doSave,
    errors,
    asyncValidationError
  )

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      let value = event.target.value as string | number | ReadonlyArray<string>
      if (inputTransformer && typeof value === "string") value = inputTransformer(value, true)
      if (typeof value === "string" && arraySplitter) {
        value = value
          .split(arraySplitter)
          .map((value, idx, ar) => (idx === ar.length - 1 ? value.trimStart() : value.trim()))
          .filter((value, idx, ar) => value || idx === ar.length - 1)
      }
      if (rest.type === "number" && typeof value === "string") {
        value = parseInt(value.trim())
      }
      store.update({
        [property]: {
          $set:
            typeof value === "string"
              ? property === "link"
                ? value.trimStart()
                  ? { uri: value.trimStart() }
                  : null
                : value.trimStart()
              : value,
        },
      } as Spec<T>)
    },
    [arraySplitter, inputTransformer, property, rest.type, store]
  )

  const inputProps = useMemo<Partial<StandardInputProps>>(
    () => ({
      ...InputProps,
      startAdornment: icon ? <InputAdornment position="start">{icon}</InputAdornment> : InputProps?.startAdornment,
      endAdornment: endAdornment || InputProps?.endAdornment,
    }),
    [InputProps, endAdornment, icon]
  )

  useEffect(() => {
    if (!asyncValidate) return
    setAsyncValidationError("")
    return createPromiseTerminator(asyncValidate(), setAsyncValidationError)
  }, [asyncValidate, value])

  return (
    <TextField
      color={error ? "error" : undefined}
      error={!!error}
      variant={isInline ? "filled" : undefined}
      required={required}
      {...rest}
      helperText={getErrorText(error) || helperText}
      value={displayValue}
      onChange={onChange}
      sx={{ flexGrow: 1 }}
      InputLabelProps={{ shrink: true }}
      InputProps={inputProps}
    />
  )
}
