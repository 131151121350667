import { GmtModel } from "./core"

export interface IKeywordData {
  readonly name: string
  readonly locked: boolean
}

export class GmtKeyword extends GmtModel<IKeywordData> {
  static jsonApiType = "taxonomy_term--sms_keywords"
  static endpoint = "taxonomy_term/sms_keywords"

  static attMapping: Readonly<Record<string, keyof IKeywordData>> = {
    name: "name",
    field_locked: "locked",
  }

  get name(): string {
    return this.getAttribute("name")
  }
}
