import { GmtKeyword } from "../../model"
import { fetchKeywords } from "./fetching"

export async function saveNewKeywords(list: ReadonlyArray<GmtKeyword> | undefined): Promise<void> {
  const newKeywords = list?.filter((entry) => !entry.getApiId())
  if (newKeywords?.length) {
    await Promise.all(newKeywords.map((entry) => entry.save()))
  }
}

export async function refreshKeywords(list: ReadonlyArray<GmtKeyword> | undefined): Promise<void> {
  const existingKeywords = list?.filter((entry) => entry.getApiId())
  if (existingKeywords?.length) {
    await fetchKeywords({
      filter: { include: existingKeywords },
      pager: { pageSize: existingKeywords.length, page: 0 },
    })
  }
}
