import { ReactElement } from "react"

import Tooltip from "@mui/material/Tooltip"
import Chip from "@mui/material/Chip"

import { GmtKeyword } from "server/model"
import { useStore } from "core"

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"
import SmsIcon from "@mui/icons-material/Sms"
import CloudIcon from "@mui/icons-material/Cloud"

export interface IKeywordChipProps {
  readonly value: GmtKeyword
  readonly online: boolean
  readonly tv: boolean
}

export function getKeywordIcon(online: boolean, tv: boolean): ReactElement | undefined {
  return online && tv ? (
    <AutoAwesomeIcon fontSize="small" />
  ) : tv ? (
    <SmsIcon fontSize="small" />
  ) : online ? (
    <CloudIcon fontSize="small" />
  ) : undefined
}

export default function KeywordChip(props: IKeywordChipProps): ReactElement {
  const { value, online, tv } = props
  const { name, locked } = useStore(value.dataStore)
  return (
    <Tooltip title={name || "k.A."}>
      <Chip
        label={name || "k.A."}
        className={"KeywordChip " + (locked ? "red" : "green")}
        icon={getKeywordIcon(online, tv)}
      />
    </Tooltip>
  )
}
