import { ToOneRelation } from "../../coloquent"
import { GmtModel } from "./core"
import { GmtUser } from "./GmtUser"
import { GmtWinner } from "./GmtWinner"

export interface ICommunicationData {
  readonly createdAt: Date
  readonly title: string
  readonly subject: string
  readonly isReminder: boolean
  readonly type: TComType
  readonly body: IComBody
  readonly author: GmtUser | null
  readonly winner: GmtWinner | null
}

export type TComType = "sms" | "mail"

export interface IComBody {
  readonly format: TComBodyType
  readonly processed?: string
  readonly value: string
}

export type TComBodyType = "plain_text"

export class GmtCommunication extends GmtModel<ICommunicationData> {
  static jsonApiType = "node--gmt_communication"
  static endpoint = "node/gmt_communication"

  static attMapping: Readonly<Record<string, keyof ICommunicationData>> = {
    created: "createdAt",
    title: "title",
    body: "body",
    field_message_reminder: "isReminder",
    field_message_type: "type",
    field_subject: "subject",
  }
  static relMapping: Readonly<Record<string, keyof ICommunicationData>> = {
    uid: "author",
    field_ref_winner: "winner",
  }

  static dates = ["created"]

  uid(): ToOneRelation<GmtUser, this> {
    return this.hasOne(GmtUser)
  }

  field_ref_winner(): ToOneRelation<GmtWinner, this> {
    return this.hasOne(GmtWinner)
  }
}
