import { DragEventHandler, ReactElement, useCallback, useRef, useState } from "react"
import axios from "axios"

import Paper from "@mui/material/Paper"
import LoadingButton from "@mui/lab/LoadingButton"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"

import { useStore } from "core"
import { GmtFile, GmtModel } from "server/model"
import Stack from "@mui/material/Stack"

export default function DocumentsPanel({
  comp,
  doReload,
  endpoint,
}: {
  comp: GmtModel<{ documents: ReadonlyArray<GmtFile> }>
  doReload: () => void
  endpoint: string
}): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null)
  const documents = useStore(comp.dataStore, (data) => data.documents)
  const [loading, setLoading] = useState(false)

  const startUpload = useCallback(() => inputRef.current?.click(), [])

  const endUpload = useCallback(() => {
    setLoading(false)
    if (inputRef.current) inputRef.current.value = ""
  }, [])

  const doUpload = useCallback(
    (file: File) => {
      setLoading(true)
      const reader = new FileReader()
      reader.onload = (event) => {
        const data = event.target?.result
        if (data) {
          axios({
            method: "post",
            url: GmtModel.jsonApiBaseUrl + "/" + endpoint + "/" + comp.getApiId() + "/field_documents",
            headers: {
              "Accept": "application/vnd.api+json",
              "Content-Type": "application/octet-stream",
              "Content-Disposition": 'file; filename="' + file.name + '"',
            },
            data: data,
          }).then(
            () => {
              endUpload()
              doReload()
            },
            () => {
              endUpload()
            }
          )
        } else {
          endUpload()
        }
      }
      reader.onerror = endUpload
      reader.readAsArrayBuffer(file)
    },
    [comp, doReload, endUpload, endpoint]
  )

  const onChange = useCallback(() => {
    const files = inputRef.current?.files
    if (files?.length) doUpload(files[0])
  }, [doUpload])

  const deleteDocument = useCallback(
    (file: GmtFile) => {
      setLoading(true)
      comp.removeFromRelation("documents", file)
      comp.save().then(endUpload, endUpload)
    },
    [comp, endUpload]
  )

  const onDragOver = useCallback<DragEventHandler<HTMLDivElement>>((event) => {
    event.preventDefault()
  }, [])
  const onFileDrop = useCallback<DragEventHandler<HTMLDivElement>>(
    (event) => {
      event.preventDefault()
      const { items, files } = event.dataTransfer
      const droppedFiles: File[] = []
      if (items?.length) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].kind === "file") {
            const file = items[i].getAsFile()
            if (file) droppedFiles.push(file)
          }
        }
      } else {
        for (let i = 0; i < files.length; i++) {
          droppedFiles.push(files[i])
        }
      }
      if (droppedFiles.length) doUpload(droppedFiles[0])
    },
    [doUpload]
  )

  return (
    <Paper sx={{ py: 2, textAlign: "center" }} onDrop={onFileDrop} onDragOver={onDragOver}>
      <input ref={inputRef} type="file" hidden accept=".pdf,application/pdf" onChange={onChange} />
      <Stack spacing={1}>
        {documents?.map((file) => (
          <div key={file.getApiId()}>
            <IconButton onClick={() => deleteDocument(file)}>
              <DeleteIcon />
            </IconButton>
            <a href={file.url} target="_blank" rel="noreferrer">
              {file.filename}
            </a>
          </div>
        ))}
        <div>
          <LoadingButton variant="outlined" onClick={startUpload} loading={loading}>
            Datei hochladen
          </LoadingButton>
        </div>
      </Stack>
    </Paper>
  )
}
