import axios, { AxiosResponse } from "axios"
import { CBaseUrl } from "../../constants"
import { GmtWin, GmtWinner, IGmtWinnerData } from "../../model"
import { isBefore, isPast } from "date-fns"
import format from "date-fns/format"
import de from "date-fns/locale/de"

const CGAUrl = CBaseUrl + "/api/ga/draw"
const CGASetUrl = CBaseUrl + "/api/ga/set-winner"
const CDTUrl = CBaseUrl + "/api/dtag/draw"

export interface IDrawingResponse {
  readonly response: IDrawResponseWinnerBase
  readonly winner: GmtWinner
}

const CErrorMessages: Record<number, string> = {
  400: "API key fehlt",
  401: "Authorisierung fehlgeschlagen",
  402: "Benutzer muss bereits andere Gewinnerziehung abschließen",
  403: "Kombination aus date_from und time_from nicht korrekt",
  404: "Kombination aus date_to und time_to nicht korrekt",
  405: "Startzeitpunkt ist größer als der Endzeitpunkt",
  406: "Startzeitpunkt liegt mehr als 180 Tage in der Vergangenheit",
  407: "Kombination aus s_shortcut und s_sendingid nicht gefunden",
  408: "Keine Gewinner für die Filterkriterien ermittelt, Gewinnerziehung beendet und Nutzer entsperrt",
  411: "Potenzieller Gewinner darf nicht mehr geändert werden",
  412: "winstatusid existiert nicht",
  413: "Das Feld freetext muss gesetzt sein, da winstatusid=10 und darf nicht mehr als 30 Zeichen enthalten",
  414: "Typ ist nicht 1, 2 oder leer",
  500: "Anderer Fehler",
  501: "Übergabeparameter unvollständig",
}

export async function startDrawing(win: GmtWin): Promise<IDrawingResponse> {
  const { competition } = win.dataStore.state
  if (!competition) throw "Missing competition in win!"
  const { project, additionalProjects, participationStartDate, participationEndDate, gameStatus } =
    competition.dataStore.state
  if (!project) throw "Missing project in competition!"
  if (!participationStartDate) throw "No start date given!"
  else if (!isPast(participationStartDate)) throw "Start date not in the past!"
  if (!participationEndDate) throw "No end date given!"
  else if (!isPast(participationEndDate)) throw "End date not in the past!"
  else if (participationStartDate && isBefore(participationEndDate, participationStartDate)) {
    throw "End date is before start date!"
  }
  if (gameStatus !== "Beendet") throw "Wrong state of competition: " + gameStatus + "!"
  const { participationType } = project.dataStore.state
  if (!participationType) throw "Missing participation type in project!"
  else if (participationType === "online") {
    const postResponse = await axios.post<IDrawResponse, AxiosResponse<IDrawResponse>, IStartGARequest>(CGAUrl, {
      winNodeId: win.getNodeId(),
    })
    const { code, winner, messages } = postResponse.data
    if (code !== 200 || !winner) {
      if (code === 500 && Array.isArray(messages?.error) && messages?.error.length) {
        throw messages.error[0]
      }
      throw CErrorMessages[code || 0] || "Unbekannter Fehler"
    }
    return createDrawResponse(win, { ...winner, recordtype: 4 })
  } else {
    const projects = [project, ...(additionalProjects || [])]
    const postResponse = await axios.post<IDrawResponse, AxiosResponse<IDrawResponse>, IStartDTRequest>(CDTUrl, {
      action: "startWinselection",
      date_from: format(participationStartDate, "dd.MM.yyyy", { locale: de }),
      time_from: format(participationStartDate, "HH:mm", { locale: de }),
      date_to: format(participationEndDate, "dd.MM.yyyy", { locale: de }),
      time_to: format(participationEndDate, "HH:mm", { locale: de }),
      type: 2,
      win_shows: projects.map((entry) => ({
        s_shortcut: entry.dataStore.state.serviceCode || "",
        s_sendingid: "" + entry.dataStore.state.sendingId,
      })),
    })
    const { winner, code, messages } = postResponse.data
    if (!winner || code !== 200) {
      if (messages?.error?.length) {
        const errorString = messages.error[0]
        if (typeof errorString === "string") {
          const match = errorString.match(/winselectionid: ([0-9]+), winid: ([0-9]+)/)
          if (match?.length) {
            throw new LockedError({
              winselectionid: parseInt(match[1]),
              winid: parseInt(match[2]),
            })
          }
        }
      }
      throw CErrorMessages[code || 0] || "Unbekannter Fehler"
    }
    /*
  const response = Math.floor(Math.random() * 2)
    ? testResponse
    : ({
        recordtype: "Voice",
        phone: "004910888889",
        winid: 21,
        winselectionid: 10457,
        answerAudio: "https://www.kozco.com/tech/piano2.wav",
      } as IDrawResponseWinnerVoice)
   */
    return createDrawResponse(win, winner)
  }
}

export async function endDrawing(
  win: GmtWin,
  { winid, winselectionid, potEntryId, recordtype }: IDrawResponseWinnerBase
): Promise<void> {
  if (recordtype === CDrawResponseTypeManual) return
  const response = potEntryId
    ? await axios.post<IDrawResponse, AxiosResponse<IDrawResponse>, IMarkGARequest>(CGASetUrl, {
        atxJobId: win.getNodeId(),
        potEntryId,
      })
    : await axios.post<IDrawResponse, AxiosResponse<IDrawResponse>, IMarkRequest>(CDTUrl, {
        action: "markWinner",
        winid,
        winselectionid,
        winstatusid: 2,
      })
  const { code } = response.data
  if (code !== 200) throw "Unbekannter Fehler"
}

export async function unblockDrawing({ winid, winselectionid }: IDrawResponseWinBase): Promise<void> {
  const postResponse = await axios.post<IDrawResponse, AxiosResponse<IDrawResponse>, IUnblockRequest>(CDTUrl, {
    action: "unblockUser",
    winid,
    winselectionid,
  })
  const { code } = postResponse.data
  if (code !== 200) throw CErrorMessages[code || 0] || "Unbekannter Fehler"
}

export async function withdraw(
  win: GmtWin,
  { winid, winselectionid, potEntryId }: IDrawResponseWinBase
): Promise<IDrawingResponse> {
  if (potEntryId) return startDrawing(win)
  const postResponse = await axios.post<IDrawResponse, AxiosResponse<IDrawResponse>, IMarkRequest>(CDTUrl, {
    action: "markWinner",
    winid,
    winselectionid,
    winstatusid: 1,
  })
  const { winner: response, code } = postResponse.data
  if (!response || code !== 201) throw CErrorMessages[code || 0] || "Unbekannter Fehler"
  return createDrawResponse(win, response)
}

export function createDrawResponse(win: GmtWin, response: IDrawResponseWinnerBase): IDrawingResponse {
  const { competition, title: winTitle } = win.dataStore.state
  if (!competition) throw "Missing competition in win"
  const { title: compTitle } = competition.dataStore.state
  const baseData: Partial<IGmtWinnerData> = {
    win,
    title: compTitle + " | " + winTitle,
    mobileNumber: response.phone,
    winnerStatus: "Gezogen",
  }
  let extraData: Partial<IGmtWinnerData> = {
    address: {
      country_code: "DE",
    },
  }
  if (response.recordtype === CDrawResponseTypeData) {
    const dataResponse = response as IDrawResponseWinnerData
    extraData = {
      mail: dataResponse.email,
      salutation: dataResponse.gender === "male" ? "1" : dataResponse.gender === "female" ? "2" : undefined,
      address: {
        given_name: dataResponse.firstName,
        family_name: dataResponse.lastName,
        address_line1: dataResponse.streetAddress,
        postal_code: dataResponse.postalCode,
        country_code: dataResponse.country || "DE",
        locality: dataResponse.city,
      },
    }
  }
  const winner = new GmtWinner().initialize({ ...baseData, ...extraData })
  return { response, winner }
}

export class LockedError extends Error {
  readonly config: IDrawResponseWinBase
  constructor(config: IDrawResponseWinBase) {
    super()
    this.config = config
  }
}

interface IMarkRequest extends IDrawResponseWinBase {
  readonly action: "markWinner"
  readonly winstatusid: 1 | 2
}

interface IMarkGARequest extends IDrawResponseWinBase {
  readonly atxJobId: number
}

interface IStartGARequest {
  readonly winNodeId: number
}

interface IStartDTRequest {
  readonly action: "startWinselection"
  readonly date_from: string
  readonly time_from: string
  readonly date_to: string
  readonly time_to: string
  readonly type: 2
  readonly win_shows: ReadonlyArray<{
    readonly s_shortcut: string
    readonly s_sendingid: string
  }>
}

interface IUnblockRequest extends IDrawResponseWinBase {
  action: "unblockUser"
}

export interface IDrawResponse {
  readonly code: number
  readonly winner?: IDrawResponseWinnerBase
  readonly messages?: {
    readonly error?: ReadonlyArray<string | undefined>
  }
}

export interface IDrawResponseWinnerBase extends IDrawResponseWinBase {
  readonly recordtype: number
  readonly phone: string
}

export interface IDrawResponseWinBase {
  readonly winselectionid?: number
  readonly winid?: number
  readonly potEntryId?: string
}

export const CDrawResponseTypeManual = 0
export interface IDrawResponseWinnerManual extends IDrawResponseWinnerBase {
  readonly recordtype: typeof CDrawResponseTypeManual
}

export const CDrawResponseTypeSms = 3
export interface IDrawResponseWinnerSms extends IDrawResponseWinnerBase {
  readonly recordtype: typeof CDrawResponseTypeSms
  readonly answerText: string
}

export const CDrawResponseTypeVoice = 1
export interface IDrawResponseWinnerVoice extends IDrawResponseWinnerBase {
  readonly recordtype: typeof CDrawResponseTypeVoice
  readonly answerAudio: string
}

export const CDrawResponseTypeData = 4
export interface IDrawResponseWinnerData extends IDrawResponseWinnerBase, IUserDetails {
  readonly recordtype: typeof CDrawResponseTypeData
  readonly answer: string
}

export interface IUserDetails {
  readonly userId: string
  readonly firstName: string
  readonly lastName: string
  readonly gender: "female" | "male"
  readonly email: string
  readonly birthDate: string
  readonly phone: string
  readonly streetAddress: string
  readonly postalCode: string
  readonly city: string
  readonly country: string
}
