import { ReactElement, useCallback, useMemo } from "react"

import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import ProjectsList from "../projects/ProjectsList"
import IconButton from "@mui/material/IconButton"

import DeleteIcon from "@mui/icons-material/Delete"
import NotesIcon from "@mui/icons-material/Notes"

import { useStore } from "core"
import { GmtCompetition, GmtProject } from "server/model"
import { useDrawer } from "../LayoutContext"
import { useProjectDetailsDrawer } from "../projects/useProjectDetailsDrawer"
import ProjectsDrawer from "./ProjectsDrawer"

const CExcludedRows = ["field_sms_keywords"]

export interface IProjectsListPaperProps {
  readonly competition: GmtCompetition
  readonly doSave?: () => Promise<unknown>
  readonly readOnly?: boolean
  readonly title?: string
}

export default function ProjectsListPaper(props: IProjectsListPaperProps): ReactElement {
  const { competition, doSave, readOnly, title } = props
  const { dataStore } = competition
  const project = useStore(dataStore, (state) => state.project)
  const additionalProjects = useStore(dataStore, (state) => state.additionalProjects)
  const isActive = useStore(dataStore, (state) => !!state.gameStatus && state.gameStatus !== "Entwurf")
  const canAddProjects = useStore(
    dataStore,
    ({ gameStatus, project }) =>
      gameStatus !== "Aktiv" &&
      gameStatus !== "Gestartet" &&
      gameStatus !== "Gezogen" &&
      gameStatus !== "Abgeschlossen" &&
      project?.dataStore.state.participationType !== "online"
  )
  const { selectedProject, showProjectDrawer } = useProjectDetailsDrawer()

  const projectsList = useMemo<ReadonlyArray<GmtProject>>(
    () => (project ? [project].concat(additionalProjects || []) : additionalProjects || []),
    [additionalProjects, project]
  )
  const drawer = useDrawer()

  const addProject = useCallback(
    (project: GmtProject) => {
      competition.addToRelation("additionalProjects", project)
      drawer()
      if (doSave) {
        void doSave()
      }
    },
    [competition, drawer, doSave]
  )

  const removeProject = useCallback(
    (project: GmtProject) => {
      competition.removeFromRelation("additionalProjects", project)
      drawer()
      if (doSave) {
        void doSave()
      }
    },
    [competition, drawer, doSave]
  )

  const openDrawer = useCallback(() => {
    drawer({
      title: "Projekt hinzufügen",
      width: 620,
      element: <ProjectsDrawer addProject={addProject} exclude={projectsList} />,
    })
  }, [addProject, drawer, projectsList])

  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          {title || "Projekt(e)"}
        </Typography>
        {canAddProjects && !readOnly && (
          <Button variant="contained" onClick={openDrawer}>
            {doSave ? "Projekt hinzufügen" : "Ziehung aus mehreren Projekten"}
          </Button>
        )}
      </Toolbar>
      <ProjectsList
        data={projectsList}
        exclude={CExcludedRows}
        selected={selectedProject}
        renderDetails={(item) => (
          <div style={{ whiteSpace: "nowrap" }}>
            <IconButton onClick={() => showProjectDrawer(item)}>
              <NotesIcon />
            </IconButton>
            {!isActive && item !== project && !readOnly && (
              <IconButton onClick={() => removeProject(item)}>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        )}
      />
    </Paper>
  )
}
