import { GmtCompetition, ICompetitionData } from "../../model"
import { IErrors, stripErrors } from "core"
import { isBefore } from "date-fns"

export function validateCompetitionDataForActivation(
  data: Partial<ICompetitionData>
): IErrors<ICompetitionData> | undefined {
  return stripErrors<ICompetitionData>({
    title: data.title?.trim() ? "" : "missing",
    participationStartDate: data.participationStartDate ? "" : "missing",
    participationEndDate: data.participationEndDate
      ? data.participationStartDate && isBefore(data.participationEndDate, data.participationStartDate)
        ? "Liegt vor dem Teilnahmestart"
        : ""
      : "missing",
  })
}

export function validateCompetitionForActivation(competition: GmtCompetition): ReadonlyArray<string> {
  const rv: Array<string> = []
  if (competition.hasChanges()) {
    rv.push("Es gibt ungespeicherte Änderungen")
    return rv // quick exit
  }
  const { title, participationStartDate, participationEndDate, project, wins } = competition.baseStore.state

  if (!project) rv.push("Projekt fehlt")
  else if (project.baseStore.state.state !== "2") rv.push("Projekt ist nicht aktiv")

  if (!title?.trim()) rv.push("Titel fehlt")
  if (!participationStartDate) rv.push("Teilnahmestart fehlt")
  if (!participationEndDate) rv.push("Teilnahmeschluss fehlt")
  else if (participationStartDate && isBefore(participationEndDate, participationStartDate)) {
    rv.push("Teilnahmeschluss liegt vor dem Teilnahmestart")
  }

  if (!wins?.length) rv.push("Es wurde kein Gewinn angelegt")
  else if (wins.find((win) => !win.baseStore.state.prices?.length)) {
    rv.push("Es gibt mind. einen Gewinn ohne Preis")
  }

  return rv
}
