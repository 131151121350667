import { ReactElement, useCallback, useState } from "react"

import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"

import { useStore } from "core"
import { GmtCompetition } from "server/model"
import { useDrawer } from "../LayoutContext"
import WinDetailsBar from "../wins/WinDetailsBar"
import ProjectLabel from "../projects/ProjectLabel"
import GameStatusChip from "../shared/GameStatusChip"
import FormattedDate from "../shared/FormattedDate"
import { IColumnSpec } from "../shared/ListTable"
import WinChip from "../shared/WinChip"
import { CSenderCellProps, CSenderCellWidth } from "../sender/SenderImage"
import KeywordsChipList from "../shared/KeywordsChipList"
import UserChip from "../shared/UserChip"

export const CDayTimeFormat = "dd.MM.yy, p"

export const CColumnsUnsortable: ReadonlyArray<IColumnSpec> = [
  {
    key: "sender",
    title: "Sender",
    width: CSenderCellWidth,
  },
  {
    key: "field_game_status",
    title: "Status",
  },
  {
    key: "title",
    title: "Titel",
  },
  {
    key: "field_participation_start_date",
    title: "Teilnahmestart",
  },
  {
    key: "field_participation_end_date",
    title: "Teilnahmeschluss",
  },
  {
    key: "field_sms_keyword",
    title: "Sms Keywords",
  },
  {
    key: "field_project_manager",
    title: "Projektverantwortliche(r)",
  },
  {
    key: "wins",
    title: "Gewinn(e)",
    className: "Wins",
  },
]

export interface ICompetitionListRowProps {
  readonly comp: GmtCompetition
  readonly exclude?: ReadonlyArray<string>
  readonly details?: ReactElement
}

export default function CompetitionListRow(props: ICompetitionListRowProps): ReactElement {
  const { comp, details, exclude } = props

  const setDrawer = useDrawer()

  const [selected, setSelected] = useState(false)
  const { wins, project, gameStatus, title, participationStartDate, participationEndDate, smsKeywords } = useStore(
    comp.dataStore
  )
  const { keywordsOnline, keywordsTv, projectManager } = useStore(project?.dataStore) || {}

  const showWin = useCallback(() => {
    if (!wins?.length) return
    setSelected(true)
    setDrawer({
      element: <WinDetailsBar wins={wins} />,
      width: 400,
      title: "Gewinne",
      onClose: () => {
        setSelected(false)
      },
    })
  }, [wins, setDrawer])

  return (
    <TableRow className={selected ? "selected" : undefined}>
      {details ? <TableCell>{details}</TableCell> : null}
      {!exclude?.includes("sender") && (
        <TableCell {...CSenderCellProps}>{project && <ProjectLabel project={project} />}</TableCell>
      )}
      <TableCell>
        <GameStatusChip value={gameStatus} />
      </TableCell>
      <TableCell>{title}</TableCell>
      <TableCell>
        <FormattedDate value={participationStartDate} format={CDayTimeFormat} />
      </TableCell>
      <TableCell>
        <FormattedDate value={participationEndDate} format={CDayTimeFormat} />
      </TableCell>
      {!exclude?.includes("field_sms_keyword") && (
        <TableCell style={{ maxWidth: "10%" }}>
          <KeywordsChipList keywords={smsKeywords} online={keywordsTv} tv={keywordsOnline} />
        </TableCell>
      )}
      {!exclude?.includes("field_project_manager") && (
        <TableCell>
          {projectManager?.map((user) => (
            <UserChip key={user.getApiId()} user={user} />
          ))}
        </TableCell>
      )}
      {!exclude?.includes("wins") && (
        <TableCell>
          {wins?.map((win) => (
            <WinChip key={win.getApiId()} win={win} onClick={showWin} />
          ))}
        </TableCell>
      )}
    </TableRow>
  )
}
