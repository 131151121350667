import { Fragment, ReactElement } from "react"

import Stack from "@mui/material/Stack"
import Paper from "@mui/material/Paper"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"

import { useStore } from "core"
import { CShippingOptionsLabels, GmtMaterialPrice, GmtWin } from "server/model"
import { getPriceType } from "../shared/WinChip"

export interface IWinDetailsBarProps {
  readonly wins: ReadonlyArray<GmtWin>
}

export default function WinDetailsBar(props: IWinDetailsBarProps): ReactElement {
  const { wins } = props
  return (
    <Stack>
      {wins
        .map((win) => win.dataStore.state.prices || [])
        .flat()
        .map((price) => (
          <TableContainer key={price.getApiId()} component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Bezeichnung:</TableCell>
                  <TableCell>{getPriceType(price)}</TableCell>
                </TableRow>
                {price instanceof GmtMaterialPrice ? <MaterialPriceDetails price={price} /> : null}
              </TableBody>
            </Table>
          </TableContainer>
        ))}
    </Stack>
  )
}

interface IMaterialPriceDetailsProps {
  readonly price: GmtMaterialPrice
}

function MaterialPriceDetails(props: IMaterialPriceDetailsProps): ReactElement {
  const { price: priceObject } = props
  const { price, qty, shippingOptions, orderStatus, sponsor } = useStore(priceObject.dataStore)
  return (
    <Fragment>
      <TableRow>
        <TableCell>Preis in &euro;:</TableCell>
        <TableCell>{price}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Menge:</TableCell>
        <TableCell>{qty}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Sponsor:</TableCell>
        <TableCell>{sponsor?.dataStore.state.title}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Versand:</TableCell>
        <TableCell>
          {(shippingOptions && CShippingOptionsLabels[shippingOptions]) || shippingOptions || "k.A."}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Bestellstatus</TableCell>
        <TableCell>{orderStatus}</TableCell>
      </TableRow>
    </Fragment>
  )
}
