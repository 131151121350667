import { Store } from "core"
import { GmtUser } from "server/model"
import { loadCsrfToken, loadSessionUser } from "server/logic"
import axios from "axios"

export const CSessionState = Store.create<ISessionState>({ state: "created" })

export async function initSessionState(): Promise<void> {
  CSessionState.update({ state: { $set: "initializing" } })
  const user = await loadSessionUser()
  let token = CSessionState.state.csrfToken
  if (user && !token) {
    token = await loadCsrfToken()
    if (token) {
      axios.defaults.headers.post["X-CSRF-Token"] = token
      axios.defaults.headers.patch["X-CSRF-Token"] = token
      axios.defaults.headers.delete["X-CSRF-Token"] = token
    }
  }
  CSessionState.update({
    state: { $set: user ? "authorized" : "unauthorized" },
    user: { $set: user },
    csrfToken: { $set: token },
  })
}

export interface ISessionState {
  readonly user?: GmtUser | null
  readonly csrfToken?: string
  readonly state: TSessionState
}

export type TSessionState = "created" | "initializing" | "authorized" | "unauthorized"
