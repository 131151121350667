import { IErrors, Store } from "core"
import { GmtModel } from "../../../server/model"

export interface ISimpleInputProps<T extends object, K extends keyof T> {
  readonly store: Store<T>
  readonly property: K
  readonly errors?: IErrors<T>
}

export function arraysNotEqual(
  array1: ReadonlyArray<GmtModel> | undefined,
  array2: ReadonlyArray<GmtModel> | undefined
): boolean {
  if (array1 === array2) return false
  if (!array1 || !array2 || array1.length !== array2.length) return true
  const sortedArray1 = array1.map((entry) => entry.getApiId())
  sortedArray1.sort()
  const sortedArray2 = array2.map((entry) => entry.getApiId())
  sortedArray2.sort()
  return !!sortedArray1.find((entry, idx) => entry !== sortedArray2[idx])
}

const CDefaultErrorMessages: Readonly<Record<string, string | undefined>> = {
  missing: "",
  invalidLink: "Das ist keine korrekte URL",
}

export function getErrorText(code: string | undefined): string | undefined {
  const text = code && CDefaultErrorMessages[code]
  return typeof text === "string" ? text : code
}
