import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { ConfirmProvider } from "material-ui-confirm"

import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import de from "date-fns/locale/de"

import reportWebVitals from "./reportWebVitals"

import { CTheme } from "./ui/theme/theme"
import App from "./ui/App"

const root = createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <BrowserRouter>
    <CssBaseline />
    <ThemeProvider theme={CTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
        <ConfirmProvider>
          <App />
        </ConfirmProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
