import { ReactElement, useCallback, useMemo } from "react"

import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import AddBoxIcon from "@mui/icons-material/AddBox"
import NotesIcon from "@mui/icons-material/Notes"

import { Store, useStore } from "core"
import { GmtProject } from "server/model"
import { IFetchProjectsFilter } from "server/logic/projects"
import ProjectsList from "../../../components/projects/ProjectsList"
import ProjectsFilter from "../../../components/projects/ProjectsFilter"
import { useProjectDetailsDrawer } from "../../../components/projects/useProjectDetailsDrawer"

export interface ISelectProjectStepProps {
  readonly onSelectProject: (project: GmtProject) => void
}

export default function StepOne(props: ISelectProjectStepProps): ReactElement {
  const { onSelectProject } = props
  const filterStore = useMemo(() => Store.create<IFetchProjectsFilter>({ text: "" }), [])
  const filter = useStore(filterStore)
  const { selectedProject, showProjectDrawer } = useProjectDetailsDrawer()

  return (
    <Paper id="SelectProjectStep">
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Projekt auswählen
        </Typography>
      </Toolbar>
      <ProjectsFilter filterStore={filterStore} />
      <ProjectsList
        filter={filter}
        selected={selectedProject}
        renderDetails={(project) => (
          <div style={{ whiteSpace: "nowrap" }}>
            <IconButton onClick={() => showProjectDrawer(project)}>
              <NotesIcon />
            </IconButton>
            <SelectButton project={project} onSelect={onSelectProject} />
          </div>
        )}
      />
    </Paper>
  )
}

function SelectButton({
  project,
  onSelect,
}: {
  readonly project: GmtProject
  readonly onSelect: (project: GmtProject) => void
}): ReactElement {
  const isActive = useStore(project.dataStore, (state) => state.state === "2")
  const onClick = useCallback(() => {
    onSelect(project)
  }, [onSelect, project])
  return (
    <IconButton onClick={onClick} disabled={!isActive} color="primary">
      <AddBoxIcon />
    </IconButton>
  )
}
