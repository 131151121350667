import { ReactElement, useEffect } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"

import { useStore } from "../core"
import { CSessionState, initSessionState } from "./state"
import { CRouteCompetitions, CRouteCoreData, CRouteDashboard, CRouteProjects, CRouteWinners } from "./routes"
import { LayoutContext } from "./components/LayoutContext"
import AppLayout from "./components/AppLayout"

import LoginPage from "./pages/LoginPage"
import Dashboard from "./pages/dashboard"
import CompetitionsRouter from "./pages/competitions/CompetitionsRouter"
import CoreDataRouter from "./pages/projects/CoreDataRouter"
import WinnersRouter from "./pages/winners/WinnersRouter"
import ProjectsRouter from "./pages/projects/ProjectsRouter"

import "./App.scss"

export default function App(): ReactElement {
  const isLoggedIn = useStore(CSessionState, (state) => !!state.user)
  const isCreated = useStore(CSessionState, (state) => state.state === "created")
  useEffect(() => {
    if (isCreated) initSessionState()
  }, [isCreated])

  return isLoggedIn ? (
    <LayoutContext>
      <AppLayout>
        <Routes>
          <Route path={CRouteDashboard} element={<Dashboard />} />
          <Route path={CRouteCompetitions + "/*"} element={<CompetitionsRouter />} />
          <Route path={CRouteWinners + "/*"} element={<WinnersRouter />} />
          <Route path={CRouteCoreData + "/*"} element={<CoreDataRouter />} />
          <Route path={CRouteProjects + "/*"} element={<ProjectsRouter />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="*" element={<RedirectDashboard />} />
        </Routes>
      </AppLayout>
    </LayoutContext>
  ) : (
    <LoginPage />
  )
}

function RedirectDashboard(): null {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(CRouteDashboard, { replace: true })
  })
  return null
}
