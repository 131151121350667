import { Fragment, ReactElement, useCallback, useState } from "react"
import { Spec } from "immutability-helper"

import TextField from "@mui/material/TextField"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"

import { FilteredTypeByPropType, useStore } from "core"
import { getErrorText, ISimpleInputProps } from "./core"
import { IInlineEditingProps, useInlineEditing } from "./useInlineEditing"

export interface ISimpleDatePickerProps<T extends object, K extends keyof FilteredTypeByPropType<T, Date | null>>
  extends ISimpleInputProps<T, K>,
    IInlineEditingProps<T> {
  readonly withTime?: boolean
  readonly label?: string
  readonly maxDate?: Date | null
  readonly minDate?: Date | null
  readonly disablePast?: boolean
  readonly disableFuture?: boolean
  readonly size?: "small" | "medium"
  readonly fullWidth?: boolean
  readonly required?: boolean
  readonly disabled?: boolean
}

export function StoreDatePicker<T extends object, K extends keyof FilteredTypeByPropType<T, Date | null>>(
  props: ISimpleDatePickerProps<T, K>
): ReactElement {
  const { store, property, minDate, maxDate, errors, withTime, size, fullWidth, baseStore, doSave, required, ...rest } =
    props
  const [renderValue, setRenderValue] = useState(1)

  const hookedSave = useCallback(
    (): Promise<unknown> =>
      doSave
        ? doSave().then((rv) => {
            setRenderValue((v) => v + 1)
            return rv
          })
        : Promise.reject(),
    [doSave]
  )

  const { endAdornment, error, isInline } = useInlineEditing(
    "date",
    store,
    property,
    required,
    baseStore,
    doSave && hookedSave,
    errors
  )

  const value = useStore(
    store,
    useCallback((state) => state[property], [property])
  )

  const onChange = useCallback(
    (date: Date | null) => {
      store.update({ [property]: { $set: date } } as Spec<T>)
    },
    [property, store]
  )

  return withTime ? (
    <DateTimePicker
      {...rest}
      key={renderValue}
      value={value || null}
      onChange={onChange}
      renderInput={(params) => {
        const { InputProps, ...rest } = params
        return (
          <TextField
            error={!!error}
            helperText={getErrorText(error)}
            InputLabelProps={{ shrink: true }}
            sx={{ flexGrow: 1 }}
            size={size}
            fullWidth={fullWidth}
            variant={isInline ? "filled" : undefined}
            required={required}
            {...rest}
            InputProps={{
              ...InputProps,
              endAdornment: (
                <Fragment>
                  {InputProps?.endAdornment}
                  {endAdornment}
                </Fragment>
              ),
            }}
          />
        )
      }}
      maxDate={maxDate || undefined}
      minDate={minDate || undefined}
    />
  ) : (
    <DatePicker
      {...rest}
      value={value || null}
      onChange={onChange}
      renderInput={(params) => {
        const { InputProps, ...rest } = params
        return (
          <TextField
            error={!!error}
            helperText={getErrorText(error)}
            InputLabelProps={{ shrink: true }}
            sx={{ flexGrow: 1 }}
            size={size}
            fullWidth={fullWidth}
            variant={isInline ? "filled" : undefined}
            required={required}
            {...rest}
            InputProps={{
              ...InputProps,
              endAdornment: (
                <Fragment>
                  {InputProps?.endAdornment}
                  {endAdornment}
                </Fragment>
              ),
            }}
          />
        )
      }}
      maxDate={maxDate || undefined}
      minDate={minDate || undefined}
    />
  )
}
