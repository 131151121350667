import { GmtModel } from "./core"
import { GmtUser } from "./GmtUser"
import { ToOneRelation } from "../../coloquent"

export interface IHistoryData {
  readonly label: string
  readonly oldValue: string
  readonly newValue: string
  readonly created: Date
  readonly user: GmtUser | null
}

export class GmtHistory extends GmtModel<IHistoryData> {
  static jsonApiType = "views--change_history--page_1"
  static endpoint = "views/change_history/page_1"
  static dates = ["created", "changed"]

  static attMapping: Readonly<Record<string, keyof IHistoryData>> = {
    label: "label",
    created: "created",
    old_value: "oldValue",
    new_value: "newValue",
  }
  static relMapping: Readonly<Record<string, keyof IHistoryData>> = {
    user_id: "user",
  }

  get label(): string {
    return this.getAttribute("label")
  }

  get created(): Date | null {
    return this.getAttribute("created")
  }

  get old_value(): string {
    return this.getAttribute("old_value")
  }

  get new_value(): string {
    return this.getAttribute("new_value")
  }

  get user(): GmtUser {
    return this.getRelation("user_id")
  }

  user_id(): ToOneRelation<GmtUser, this> {
    return this.hasOne(GmtUser)
  }
}
