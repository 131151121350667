import axios from "axios"
import { CBaseUrl } from "../constants"

export async function logout(): Promise<void> {
  try {
    const sessionTokenResponse = await axios.get(CBaseUrl + "/session/token")
    await axios.get(CBaseUrl + "/user/logout?token=" + encodeURIComponent(sessionTokenResponse.data))
  } catch (error) {
    // ignored
  }
}
