import { GmtServiceNumber } from "../model"
import { fetchModel, IFetchResult, IFetchSpec } from "./core"
import { ConditionFilterSpec } from "../../coloquent/filter/ConditionFilterSpec"
import { SimpleFilterSpec } from "../../coloquent/filter/SimpleFilterSpec"

export async function fetchServiceNumbers(
  spec: IFetchSpec<IFetchServiceNumbersFilter>
): Promise<IFetchResult<GmtServiceNumber>> {
  const { pager, filter, sort } = spec
  GmtServiceNumber.setPageSize(pager.pageSize)
  let query = GmtServiceNumber.with([])
  if (filter.text) {
    query = query.filter(new ConditionFilterSpec("name", "CONTAINS", filter.text))
  }
  if (typeof filter.online === "boolean") {
    query = query.filter(new SimpleFilterSpec("field_online", filter.online))
  }
  if (filter.exclude?.length) {
    query = query.filter(
      new ConditionFilterSpec(
        "id",
        "NOT IN",
        filter.exclude.map((entry) => (typeof entry === "string" ? entry : entry.getApiId() || ""))
      )
    )
  }
  if (filter.include?.length) {
    query = query.filter(
      new ConditionFilterSpec(
        "id",
        "IN",
        filter.include.map((entry) => (typeof entry === "string" ? entry : entry.getApiId() || ""))
      )
    )
  }
  return await fetchModel(query, pager, sort)
}

export interface IFetchServiceNumbersFilter {
  readonly text?: string
  readonly online?: boolean
  readonly exclude?: ReadonlyArray<GmtServiceNumber | string>
  readonly include?: ReadonlyArray<GmtServiceNumber | string>
}
