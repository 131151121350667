import { ReactElement, ReactNode } from "react"
import { Toolbar } from "@mui/material"

import LayoutHeader from "./LayoutHeader"
import SideBar from "./SideBar"
import ContextBar from "./ContextBar"
import AppMessages from "./AppMessages"

import "./AppLayout.scss"

export interface IAppLayout {
  readonly children: ReactNode
}

export default function AppLayout(props: IAppLayout): ReactElement {
  const { children } = props
  return (
    <div id="AppLayout">
      <LayoutHeader />
      <SideBar />
      <main>
        <Toolbar />
        {children}
      </main>
      <ContextBar />
      <AppMessages />
    </div>
  )
}
