import { Store } from "core"
import { CGameMechanicsConfig, GmtGameMechanics, TParticipationType } from "../model"

const CGameMechanicsStore = Store.create<ReadonlyArray<GmtGameMechanics>>([])
let promise: Promise<unknown> | undefined

export function getGameMechanicsStore(): Store<ReadonlyArray<GmtGameMechanics>> {
  if (!promise) {
    promise = GmtGameMechanics.get().then(
      (response) => {
        CGameMechanicsStore.set(
          response
            .getData()
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))
        )
      },
      (error) => {
        console.error(error)
        promise = undefined
      }
    )
  }
  return CGameMechanicsStore
}

export function getFilteredGameMechanics(
  list: ReadonlyArray<GmtGameMechanics>,
  participationType: TParticipationType | undefined
): ReadonlyArray<GmtGameMechanics> {
  return list.filter((m) => {
    const config = CGameMechanicsConfig[m.tid]
    return participationType && config && config.participationTypes.includes(participationType)
  })
}
