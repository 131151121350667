import { ReactElement, useEffect } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"

import { CRouteCompetitionDetailsID, CRouteCompetitionsCreate } from "./routes"
import CompetitionsOverview from "./CompetitionsOverview"
import NewCompetition from "./NewCompetition"
import CompetitionDetails from "./CompetitionDetails"

export default function CompetitionsRouter(): ReactElement {
  return (
    <Routes>
      <Route index element={<CompetitionsOverview />} />
      <Route path={CRouteCompetitionsCreate} element={<NewCompetition />} />
      <Route path={CRouteCompetitionDetailsID} element={<CompetitionDetails />} />
      <Route path="*" element={<RedirectAll />} />
    </Routes>
  )
}

function RedirectAll(): null {
  const navigate = useNavigate()
  useEffect(() => {
    navigate("", { replace: true })
  })
  return null
}
