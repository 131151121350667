import { ReactElement, useCallback, useState } from "react"

import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import IconButton from "@mui/material/IconButton"

import NotesIcon from "@mui/icons-material/Notes"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"

import { useStore } from "core"
import { GmtSponsor } from "server/model"
import { useDrawer } from "../LayoutContext"
import SponsorDetails from "../details/SponsorDetails"

export interface ISponsorListRowProps {
  readonly sponsor: GmtSponsor
}

export default function SponsorListRow(props: ISponsorListRowProps): ReactElement {
  const { sponsor } = props
  const [selected, setSelected] = useState(false)
  const setDrawer = useDrawer()
  const data = useStore(sponsor.dataStore)
  const showDetails = useCallback(() => {
    setSelected(true)
    setDrawer({
      element: <SponsorDetails sponsor={sponsor} />,
      width: 400,
      title: data.title,
      onClose: () => {
        setSelected(false)
      },
    })
  }, [data.title, setDrawer, sponsor])

  return (
    <TableRow className={selected ? "selected" : undefined}>
      <TableCell>{data.title}</TableCell>
      <TableCell>{data.partner ? <CheckIcon color={"success"} /> : <CloseIcon color={"warning"} />}</TableCell>
      <TableCell>{data.active ? <CheckIcon color={"success"} /> : <CloseIcon color={"warning"} />}</TableCell>
      <TableCell>
        <IconButton>
          <NotesIcon onClick={showDetails} />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
