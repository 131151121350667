import { ReactElement, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"

import { createPromiseTerminator, IErrors, useStore } from "core"
import { GmtProject, IProjectData } from "server/model"
import { fetchProject, saveProject } from "server/logic/projects"
import LoadingIndicator from "../../../components/shared/LoadingIndicator"
import ProjectDetailsPaper from "../../../components/projects/ProjectDetailsPaper"
import ProjectTelcoPaper from "../../../components/projects/ProjectTelcoPaper"
import ProjectTechPaper from "../../../components/projects/ProjectTechPaper"
import ProjectStepper from "./ProjectStepper"
import ProjectHistory from "./ProjectHistory"
import ProjectButtons from "./ProjectButtons"
import BriefingButtons from "./BriefingButtons"
import { useMessages } from "../../../components/LayoutContext"
import Typography from "@mui/material/Typography"
import ProjectCompetitionsPaper from "../../../components/projects/ProjectCompetitionsPaper"
import DocumentsPanel from "../../../components/shared/DocumentsPanel"

export default function ProjectDetails(): ReactElement {
  const { projectId } = useParams()

  const [project, setProject] = useState<GmtProject | null>()
  const [errors, setErrors] = useState<IErrors<IProjectData>>()
  const dataStore = useStore(project?.dataStore) || {}
  const setMessage = useMessages()

  const doSave = useCallback(async (): Promise<GmtProject | null> => {
    setErrors(undefined)
    setMessage(undefined)
    if (!project) return null
    return saveProject(project).catch((errors) => {
      if (typeof errors === "object" && errors) {
        setErrors(errors)
        setMessage({ message: errors._ || "Projekt konnte nicht gespeichert werden!", severity: "error" })
      } else {
        setMessage({ message: "Projekt konnte nicht gespeichert werden!", severity: "error" })
      }
      return null
    })
  }, [project, setMessage])

  const doReload = useCallback(() => {
    setProject(undefined)
  }, [])

  useEffect(() => {
    if (!projectId || typeof project !== "undefined") return
    return createPromiseTerminator(fetchProject(projectId), setProject)
  }, [projectId, project])

  return (
    <Container maxWidth={false}>
      {project ? (
        <Stack spacing={3}>
          <ProjectStepper project={project} />
          <Stack direction="row" my={3} spacing={3}>
            <Typography variant="h5" component="h1">
              {dataStore.title}
            </Typography>
            <BriefingButtons project={project} />
          </Stack>
          <ProjectDetailsPaper project={project} doSave={doSave} errors={errors} />
          <ProjectTelcoPaper project={project} doSave={doSave} />
          <ProjectTechPaper project={project} doSave={doSave} errors={errors} />
          <ProjectCompetitionsPaper project={project} />
          <ProjectButtons project={project} setErrors={setErrors} />
          <DocumentsPanel comp={project} doReload={doReload} endpoint={GmtProject.endpoint} />
          <ProjectHistory project={project} />
        </Stack>
      ) : (
        <LoadingIndicator />
      )}
    </Container>
  )
}
