import { GmtModel } from "./core"
import { ToOneRelation } from "../../coloquent"
import { GmtBrand } from "./GmtBrand"

export interface IProductData {
  readonly title: string
  readonly desc: string
  readonly images: ReadonlyArray<{ readonly uri: string }>
  readonly netcost: string
  readonly favorite: boolean
  readonly sku: string
  readonly id: string
  readonly brand: GmtBrand | null
}

export class GmtProduct extends GmtModel<IProductData> {
  static jsonApiType = "node--1concepts_product"
  static endpoint = "node/1concepts_product"

  static attMapping: Readonly<Record<string, keyof IProductData>> = {
    title: "title",
    field_1concepts_product_desc: "desc",
    field_1concepts_product_images: "images",
    field_1concepts_product_netcost: "netcost",
    field_1concepts_product_favorite: "favorite",
    field_1concepts_product_sku: "sku",
    field_1concepts_product_id: "id",
  }

  static relMapping: Readonly<Record<string, keyof IProductData>> = {
    field_1concepts_product_brand: "brand",
  }

  field_1concepts_product_brand(): ToOneRelation<GmtBrand> {
    return this.hasOne(GmtBrand)
  }
}
