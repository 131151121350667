export function formatCurrency(value: string | undefined, omitCurrency?: boolean): string {
  let rv = new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(parseFloat(value || "0"))
  if (omitCurrency) {
    if (!value?.trim()) return ""
    rv = rv.replace(/[^0-9,.+-]/g, "")
    const splitted = (value || "0").split(".")
    if (splitted.length === 2 && splitted[1].length < 2) {
      rv = rv.substring(0, rv.length - (2 - splitted[1].length))
    } else if (splitted.length === 1) {
      rv = rv.substring(0, rv.length - 3)
    }
  }
  return rv
}
