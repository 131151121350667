import { GmtModel } from "./core"

export interface IPartnerData {
  readonly title: string
}

export class GmtPartner extends GmtModel<IPartnerData> {
  static jsonApiType = "node--gmt_partner"
  static endpoint = "node/gmt_partner"

  static attMapping: Readonly<Record<string, keyof IPartnerData>> = {
    title: "title",
  }

  get title(): string {
    return this.getAttribute("title")
  }
}
