/* eslint-disable  @typescript-eslint/explicit-function-return-type */
import * as React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

export default function LogoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 28 27">
      <g transform="matrix(0.122449,0,0,0.11976,0,2)">
        <circle cx="112.208" cy="146.417" r="19.708" />
      </g>
      <g transform="matrix(0.122449,0,0,0.11976,0,2)">
        <path d="M154,40.208L154,166.167L193.5,166.167L193.5,0.75L154,40.208Z" />
      </g>
      <g transform="matrix(0.122449,0,0,0.11976,0,2)">
        <path d="M119.458,1L0,1L0,40.5L97.667,40.5L0.125,138.417L28.083,166.167L154.042,40.208L154.042,38.667C154.042,18.25 139.833,1 119.458,1Z" />
      </g>
    </SvgIcon>
  )
}
