import { Dispatch, ReactElement, SetStateAction, useCallback } from "react"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import { IColumnSpec } from "./ListTable"
import { IFetchSpec, ISortSpec } from "../../../server/logic"
import update from "immutability-helper"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"

export interface IListTableHeadProps<FilterType> {
  readonly columns: ReadonlyArray<IColumnSpec>
  readonly setFetchSpec?: Dispatch<SetStateAction<IFetchSpec<FilterType>>>
  readonly sort?: ISortSpec
}

export default function ListTableHead<FilterType>(props: IListTableHeadProps<FilterType>): ReactElement {
  const { columns, setFetchSpec, sort } = props

  const setSort = useCallback<Dispatch<(prev: ISortSpec | undefined) => ISortSpec>>(
    (updater) => {
      if (setFetchSpec) {
        setFetchSpec((spec) => update(spec, { sort: { $set: updater(spec.sort) } }))
      }
    },
    [setFetchSpec]
  )

  return (
    <TableHead>
      <TableRow>
        {columns.map(({ title, sortKey, key, ...rest }) => (
          <TableCell key={key} {...rest}>
            {title}
            {sortKey && <SortBtn sort={sort} sortKey={sortKey} setSort={setSort} />}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface ISortBtnProps {
  readonly sort: ISortSpec | undefined
  readonly setSort: Dispatch<(prev: ISortSpec | undefined) => ISortSpec>
  readonly sortKey: string
}

function SortBtn(props: ISortBtnProps): ReactElement {
  const { sort, sortKey, setSort } = props
  const isKey = sort?.key === sortKey
  const onClick = useCallback(() => {
    setSort((sortSpec) =>
      sortSpec
        ? update(sortSpec, { key: { $set: sortKey }, desc: { $set: sortSpec.key === sortKey && !sortSpec.desc } })
        : { key: sortKey }
    )
  }, [sortKey, setSort])
  return (
    <div onClick={onClick} className="SortBtn">
      {isKey && sort.desc ? (
        <KeyboardArrowDownIcon color={isKey ? "secondary" : "disabled"} fontSize="small" />
      ) : (
        <KeyboardArrowUpIcon color={isKey ? "secondary" : "disabled"} fontSize="small" />
      )}
    </div>
  )
}
