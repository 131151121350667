import { GmtKeyword, GmtProject, GmtServiceNumber, TProjectState } from "../../model"
import { saveProject } from "./saving"
import { concatSets } from "../core"

export async function changeProjectState(project: GmtProject, targetState: TProjectState): Promise<void> {
  const { dataStore, baseStore } = project
  const { state: baseState } = baseStore.state
  if (targetState === baseState) return
  try {
    if (targetState === "1" && baseState === "0") {
      await lockProject(project, true)
    } else if (targetState === "0") {
      await lockProject(project, false)
    }
    dataStore.update({ state: { $set: targetState } })
    await saveProject(project)
  } catch (error) {
    console.error(error)
    dataStore.update({ state: { $set: baseState } })
    try {
      if (targetState === "1" && baseState === "0") {
        await lockProject(project, false)
      } else if (targetState === "0") {
        await lockProject(project, true)
      }
    } catch (subError) {
      console.error(subError)
    }
    throw error
  }
}

async function lockProject(project: GmtProject, lock: boolean): Promise<void> {
  const { serviceNumbersOnline, serviceNumbersTv, keywordsOnline, keywordsTv } = project.dataStore.state
  await lockEntities(concatSets(serviceNumbersOnline, serviceNumbersTv), lock)
  await lockEntities(concatSets(keywordsOnline, keywordsTv), lock)
}

async function lockEntities(
  list: ReadonlyArray<GmtServiceNumber | GmtKeyword> | undefined,
  lock: boolean
): Promise<void> {
  if (!list?.length) return
  const filteredList = list.filter((entry) => entry.baseStore.state.locked !== lock)
  if (!filteredList.length) return
  await Promise.all(
    filteredList.map((entry) => {
      entry.dataStore.update({ locked: { $set: lock } })
      return entry.save()
    })
  )
}
