import { Dispatch, Fragment, ReactElement, SetStateAction, useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"

import { createPromiseTerminator } from "core"
import { fetchWinners, IFetchWinnersFilter } from "server/logic/winners"
import { fetchCompetitions, IFetchCompetitionsFilter } from "server/logic/competitions"
import { WidgetIcon } from "../../components/icons/CompetitionIcon"

export interface ITopWidgetsProps extends ISetWidgetProp {
  readonly widgets: ReadonlyArray<IWidgetDef>
}

export default function TopWidgets({ setWidgetDef, widgets }: ITopWidgetsProps): ReactElement {
  return (
    <Fragment>
      {widgets.map((entry, idx) => (
        <Widget key={idx} widgetDef={entry} setWidgetDef={setWidgetDef} />
      ))}
    </Fragment>
  )
}

interface ISetWidgetProp {
  readonly setWidgetDef: Dispatch<SetStateAction<IWidgetDef>>
}

interface IWidgetProps extends ISetWidgetProp {
  readonly widgetDef: IWidgetDef
}

function Widget({ widgetDef, setWidgetDef }: IWidgetProps): ReactElement {
  const { title, compFilter, winnerFilter, iconKey } = widgetDef
  const [rowCount, setRowCount] = useState<number>()
  const navigate = useNavigate()

  useEffect(() => {
    if (compFilter) {
      return createPromiseTerminator(
        fetchCompetitions({ filter: compFilter, pager: { page: 0, pageSize: 1 } }),
        (result) => {
          setRowCount(result.count)
        },
        (error) => {
          console.error(error)
        }
      )
    } else if (winnerFilter) {
      return createPromiseTerminator(
        fetchWinners({ filter: winnerFilter, pager: { page: 0, pageSize: 1 } }),
        (result) => {
          setRowCount(result.count)
        },
        (error) => {
          console.error(error)
        }
      )
    }
  }, [compFilter, winnerFilter])

  const onClickHandler = useCallback(() => {
    if (widgetDef.url) {
      navigate("/" + widgetDef.url)
    } else {
      setWidgetDef(widgetDef)
    }
  }, [navigate, setWidgetDef, widgetDef])

  // TODO das sollte besser klassisch mit css styling formatiert werden (ohne <Stack>  oder <Grid>)
  return (
    <Grid item xs={4} xl={2}>
      <Paper sx={{ cursor: "pointer" }} onClick={onClickHandler}>
        <Stack direction="row" paddingY={2}>
          <Stack sx={{ flexGrow: 1 }}>
            <Typography variant="caption" component="p">
              {title}
            </Typography>
            <Typography variant="h6" component="p">
              {typeof rowCount !== "number" ? "?" : rowCount}
            </Typography>
          </Stack>
          <WidgetIcon sx={{ fontSize: 70, color: "#D8D8D8" }} className={"widget-icon " + iconKey} />
        </Stack>
      </Paper>
    </Grid>
  )
}

export interface IWidgetDef {
  readonly title: string
  readonly compFilter?: IFetchCompetitionsFilter
  readonly winnerFilter?: IFetchWinnersFilter
  readonly url?: string
  iconKey?: string
}
