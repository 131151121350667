import { ReactElement, useEffect } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"

import { CRouteSponsors, CRoutePartners, CRouteProjectsCreate } from "./routes"
import ProjectsOverview from "./ProjectsOverview"
import SponsorsOverview from "./SponsorsOverview"
import PartnersOverview from "./PartnersOverview"

export default function CoreDataRouter(): ReactElement {
  return (
    <Routes>
      <Route index element={<ProjectsOverview />} />
      <Route path={CRouteProjectsCreate} element={<ProjectsOverview />} />
      <Route path={CRouteSponsors} element={<SponsorsOverview />} />
      <Route path={CRoutePartners} element={<PartnersOverview />} />
      <Route path="*" element={<RedirectAll />} />
    </Routes>
  )
}

function RedirectAll(): null {
  const navigate = useNavigate()
  useEffect(() => {
    navigate("", { replace: true })
  })
  return null
}
