import { fetchModel, IFetchResult, IFetchSpec } from "./core"
import { ConditionFilterSpec } from "../../coloquent/filter/ConditionFilterSpec"
import { GmtPartner } from "server/model/GmtPartner"

export async function fetchPartners(spec: IFetchSpec<IFetchPartnersFilter>): Promise<IFetchResult<GmtPartner>> {
  const { pager, filter, sort } = spec
  GmtPartner.setPageSize(pager.pageSize)
  let query = GmtPartner.with("")
  if (filter.text) {
    query = query.filter(new ConditionFilterSpec("title", "CONTAINS", filter.text))
  }
  if (filter.exclude?.length) {
    query = query.filter(
      new ConditionFilterSpec(
        "id",
        "NOT IN",
        filter.exclude.map((partner) => (typeof partner === "string" ? partner : partner.getApiId() || ""))
      )
    )
  }
  return await fetchModel(query, pager, sort)
}

export interface IFetchPartnersFilter {
  readonly text?: string
  readonly exclude?: ReadonlyArray<GmtPartner | string>
}
