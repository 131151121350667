import { ChangeEvent, Fragment, ReactElement, useCallback, useState } from "react"
import { Spec } from "immutability-helper"

import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import { CheckboxProps } from "@mui/material/Checkbox/Checkbox"

import { FilteredTypeByPropType, useStore } from "core"
import { ISimpleInputProps } from "./core"
import { IInlineEditingProps } from "./useInlineEditing"
import Stack from "@mui/material/Stack"
import IconButton from "@mui/material/IconButton"
import DoneIcon from "@mui/icons-material/Done"
import CloseIcon from "@mui/icons-material/Close"
import Box from "@mui/material/Box"

export interface IStoreCheckboxProps<T extends object, K extends keyof FilteredTypeByPropType<T, boolean>>
  extends ISimpleInputProps<T, K>,
    IInlineEditingProps<T>,
    Omit<CheckboxProps, "property" | "onChange" | "size"> {
  readonly label: string
  readonly small?: boolean
  readonly fullWidth?: boolean
}

export function StoreCheckbox<T extends object, K extends keyof FilteredTypeByPropType<T, boolean>>(
  props: IStoreCheckboxProps<T, K>
): ReactElement {
  const { store, property, label, baseStore, doSave, errors, small, fullWidth, ...rest } = props
  const value = useStore(store, (state) => state[property], [property])
  const baseValue = useStore(baseStore, (state) => state[property], [property])
  const [saving, setSaving] = useState(false)
  const hasChanged = !value !== !baseValue
  const isInline = !!baseStore && !!doSave

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      store.update({
        [property]: { $set: event.target.checked },
      } as Spec<T>)
    },
    [property, store]
  )

  const saveChanges = useCallback(() => {
    if (!doSave) return
    setSaving(true)
    doSave().then(
      () => {
        setSaving(false)
      },
      (error) => {
        setSaving(false)
        console.error(error)
      }
    )
  }, [doSave])

  const stopEditing = useCallback(() => {
    if (!baseStore) return
    // reset editor value
    store.update({ [property]: { $set: baseStore.state[property] } } as Spec<T>)
  }, [baseStore, property, store])

  const finalError = errors?.[property]

  return (
    <FormGroup className={isInline ? "filled" : "bordered"} sx={fullWidth ? { flexGrow: 1 } : undefined}>
      <Stack direction="row" padding={1}>
        <FormControlLabel
          control={<Checkbox {...rest} checked={!!value} onChange={onChange} className={small ? "small" : undefined} />}
          label={label}
          color={finalError ? "error" : undefined}
        />
        {hasChanged && isInline && (
          <Fragment>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={saveChanges} color="success" disabled={saving}>
              <DoneIcon />
            </IconButton>
            <IconButton onClick={stopEditing} color="error">
              <CloseIcon />
            </IconButton>
          </Fragment>
        )}
      </Stack>
    </FormGroup>
  )
}
