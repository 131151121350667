import { ReactElement } from "react"

import Tooltip from "@mui/material/Tooltip"
import Chip from "@mui/material/Chip"

import CircleIcon from "@mui/icons-material/Circle"

import { TGameStatus } from "server/model"

const CColors: Readonly<Record<TGameStatus, string>> = {
  Entwurf: "yellow",
  Aktiv: "green",
  Gestartet: "green",
  Beendet: "red",
  Gezogen: "red",
  Abgeschlossen: "grey",
}

export interface IGameStatusChipProps {
  readonly value: TGameStatus | null | undefined
}

export default function GameStatusChip(props: IGameStatusChipProps): ReactElement {
  const { value } = props
  return (
    <Tooltip title={value || "unbekannt"}>
      <Chip
        variant="outlined"
        label={value || "unbekannt"}
        icon={<CircleIcon sx={{ "&&": { color: CColors[value || "Abgeschlossen"] } }} fontSize="small" />}
      />
    </Tooltip>
  )
}
