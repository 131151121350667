import { GmtModel } from "./core"

export class GmtWinnermail extends GmtModel {
  static jsonApiType = "taxonomy_term--gewinnermailverteiler"
  static endpoint = "taxonomy_term/gewinnermailverteiler"

  get name(): ReadonlyArray<string> {
    return this.getAttribute("field_mailadress_winner")
  }
}
