import axios from "axios"
import { GmtModel, TComType } from "../../model"

const promises: Partial<Record<TComType, Promise<string>>> = {}

export async function fetchReminderTemplate(type: TComType): Promise<string> {
  try {
    return promises[type] || (promises[type] = fetchTemplateForType(type))
  } catch (error) {
    promises[type] = undefined
    throw error
  }
}

async function fetchTemplateForType(type: TComType): Promise<string> {
  const response = await axios.get<IReminderTemplateResponse>(GmtModel.jsonApiBaseUrl + "/template/reminder/" + type)
  return response.data.body
}

interface IReminderTemplateResponse {
  readonly subject: string
  readonly body: string
  readonly token: ReadonlyArray<string>
}
