import { GmtModel } from "./core"
import { ToOneRelation } from "../../coloquent"
import { GmtFile } from "./GmtFile"

export interface ISenderData {
  readonly name: string
  readonly logo: GmtFile
}

export class GmtSender extends GmtModel<ISenderData> {
  static jsonApiType = "taxonomy_term--brands"
  static endpoint = "taxonomy_term/brands"

  static attMapping: Readonly<Record<string, keyof ISenderData>> = {
    name: "name",
  }
  static relMapping: Readonly<Record<string, keyof ISenderData>> = {
    field_station_logo: "logo",
  }

  get displayName(): string {
    return this.getAttribute("name")
  }

  field_station_logo(): ToOneRelation<GmtFile, this> {
    return this.hasOne(GmtFile)
  }
}
