import { MouseEventHandler, ReactElement, useCallback, useState } from "react"

import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ListItemText from "@mui/material/ListItemText"
import { PopoverOrigin } from "@mui/material/Popover/Popover"

import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import AddIcon from "@mui/icons-material/Add"

import { Store, useStore } from "core"
import { GmtMaterialPrice, GmtWin } from "server/model"
import { useDrawer } from "../LayoutContext"
import PricesList from "./PricesList"
import PriceDrawer from "./PriceDrawer"
import WinnersList from "../winners/WinnersList"
import { setWinnerBackComplete } from "../../../server/logic/winners"
import { CSessionState } from "../../state"

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
}
const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
}

export interface IWinPaperProps {
  readonly win: GmtWin
  readonly index: number
  readonly deleteWin: (win: GmtWin) => void
  readonly duplicateWin: (win: GmtWin) => Promise<void>
  readonly selectedStore: Store<GmtWin | undefined>
  readonly readOnly: boolean
  readonly showWinner?: boolean
}

export default function WinPaper(props: IWinPaperProps): ReactElement {
  const { win, index, deleteWin, duplicateWin, selectedStore, readOnly, showWinner = false } = props
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const isSelected = useStore(selectedStore) === win
  const drawer = useDrawer()
  const user = useStore(CSessionState, (state) => state.user)
  const winner = useStore(win.dataStore, (state) => state.winner)
  const filter = {
    win: win.getApiId(),
  }
  const hasMaterialPrice = useStore(
    win.dataStore,
    ({ prices }) => !!prices?.find((price) => price instanceof GmtMaterialPrice)
  )

  const isConverted = useStore(winner?.dataStore, (state) => !!state.materialPriceConversion)

  const handleMenu = useCallback<MouseEventHandler<HTMLElement>>((event) => setAnchorEl(event.currentTarget), [])

  const handleClose = useCallback(() => setAnchorEl(undefined), [])

  const handleDelete = useCallback(() => {
    handleClose()
    deleteWin(win)
  }, [deleteWin, handleClose, win])

  const handleDuplicate = useCallback(() => {
    handleClose()
    void duplicateWin(win)
  }, [duplicateWin, handleClose, win])

  const handleCreatePrice = useCallback(() => {
    selectedStore.set(win)
    drawer({
      element: <PriceDrawer win={win} />,
      width: 620,
      title: "Preis anlegen",
      subTitle: win.dataStore.state.title,
      onClose: () => {
        selectedStore.set((selected) => (selected === win ? undefined : selected))
      },
    })
  }, [selectedStore, drawer, win])

  const convertMaterialPrice = useCallback(() => {
    if (!user || !winner) return
    selectedStore.set(win)
    drawer({
      element: <PriceDrawer win={win} initTab={2} standAlone={true} />,
      width: 620,
      title: "Sachpreis in Geldpreis umwandeln",
      subTitle: win.dataStore.state.title,
      onClose: () => {
        selectedStore.set((selected) => (selected === win ? undefined : selected))
        setWinnerBackComplete(user, winner)
      },
    })
  }, [user, selectedStore, win, drawer, winner])

  return (
    <Paper className={isSelected ? "selected" : undefined}>
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Gewinn {index} G-{win.getNodeId()}
        </Typography>
        {!readOnly && (
          <>
            <IconButton onClick={handleMenu}>
              <MoreHorizIcon />
            </IconButton>
            <Button variant="outlined" onClick={handleCreatePrice} startIcon={<AddIcon />}>
              Preis
            </Button>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={anchorOrigin}
              keepMounted
              transformOrigin={transformOrigin}
              open={!!anchorEl}
              onClose={handleClose}
            >
              <MenuItem onClick={handleDuplicate}>
                <ListItemText>Duplizieren</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleDelete}>
                <ListItemText>Löschen</ListItemText>
              </MenuItem>
            </Menu>
          </>
        )}
        {readOnly && winner && hasMaterialPrice && !isConverted && (
          <Button variant="outlined" onClick={convertMaterialPrice} startIcon={<AddIcon />}>
            Sachpreis umwandeln
          </Button>
        )}
      </Toolbar>
      <PricesList win={win} readOnly={readOnly} />
      {winner && showWinner && (
        <>
          <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
            Gewinner
          </Typography>
          <WinnersList filter={filter} />
        </>
      )}
    </Paper>
  )
}
