import { ReactElement } from "react"

import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"

import { useStore } from "core"
import { CProjectStateNames, CProjectStates, GmtProject } from "server/model"

export default function ProjectStepper({ project }: { readonly project: GmtProject }): ReactElement {
  const { state } = useStore(project.dataStore)
  return (
    <Stepper activeStep={(state && CProjectStates.indexOf(state)) || 0} alternativeLabel>
      {CProjectStates.map((state) => (
        <Step key={state}>
          <StepLabel>{CProjectStateNames[state]}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
