import { ReactElement, useEffect, useMemo, useState } from "react"

import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import { fetchWinners, IFetchWinnersFilter } from "server/logic/winners"
import WinnersList from "ui/components/winners/WinnersList"
import WinnersFilter from "./WinnersFilter"

import "./WinnersSearch.scss"
import { createPromiseTerminator, Store, useStore } from "core"

export default function WinnersSearch(): ReactElement {
  const filterStore = useMemo(() => Store.create<IFetchWinnersFilter>({ text: "", minEnd: null, maxEnd: null }), [])
  const filter = useStore(filterStore)

  const [winnerCount, setWinnerCount] = useState<number>()
  useEffect(() => {
    return createPromiseTerminator(fetchWinners({ filter: {}, pager: { page: 0, pageSize: 1 } }), (result) => {
      setWinnerCount(result.count)
    })
  }, [])

  return (
    <Paper id="WinnersSearch">
      <Toolbar>
        <Typography variant="h6" component="h6">
          Alle Gewinner{typeof winnerCount !== "number" ? "" : " [" + winnerCount + "]"}
        </Typography>
      </Toolbar>
      <WinnersFilter filterStore={filterStore} />
      <WinnersList filter={filter} />
    </Paper>
  )
}
