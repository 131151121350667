import { ReactElement, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import IconButton from "@mui/material/IconButton"
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"

import { GmtCompetition } from "server/model"
import { IFetchResult, ISortSpec } from "server/logic"
import { fetchCompetitions, IFetchCompetitionsFilter } from "server/logic/competitions"
import { CRouteWinners } from "../../../routes"
import ListTable, { IColumnSpec, useFetchSpec } from "../../../components/shared/ListTable"
import CompetitionListRow, { CColumnsUnsortable } from "../../../components/competitions/CompetitionListRow"
import { CRouteWinnerDrawing } from "../routes"

const CColumns: ReadonlyArray<IColumnSpec> = [
  {
    key: "details",
    title: "Ziehung",
  },
  ...CColumnsUnsortable,
]

const CSort: ISortSpec = {
  key: "field_participation_end_date",
}

export default function EndedCompetitions(): ReactElement {
  const filter = useMemo((): IFetchCompetitionsFilter => ({ gameStatus: "Beendet" }), [])

  const navigate = useNavigate()

  const [fetchSpec, setFetchSpec] = useFetchSpec(filter, undefined, CSort)
  const [fetchResult, setFetchResult] = useState<IFetchResult<GmtCompetition>>()

  const CExcluded = ["field_sms_keyword"]

  return (
    <Container maxWidth={false}>
      <Paper>
        <Toolbar>
          <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
            Gewinnerziehung
          </Typography>
        </Toolbar>
        <ListTable
          columns={CColumns}
          exclude={CExcluded}
          fetchSpec={fetchSpec}
          setFetchSpec={setFetchSpec}
          fetchResult={fetchResult}
          setFetchResult={setFetchResult}
          doFetch={fetchCompetitions}
          renderRow={(comp) => (
            <CompetitionListRow
              key={comp.getApiId()}
              comp={comp}
              exclude={CExcluded}
              details={
                <IconButton
                  onClick={() => {
                    navigate("/" + CRouteWinners + "/" + CRouteWinnerDrawing + "/" + comp.getApiId())
                  }}
                >
                  <AutoFixHighIcon />
                </IconButton>
              }
            />
          )}
        />
      </Paper>
    </Container>
  )
}
