import { ReactElement, useCallback } from "react"

import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"

import AddIcon from "@mui/icons-material/Add"

import { useCreateStore, useStore } from "core"
import { GmtCompetition, GmtWin } from "server/model"
import WinPaper from "../wins/WinPaper"
import { useDrawer, useMessages } from "../LayoutContext"
import { createWin, duplicateWin, removeWin } from "../../../server/logic/competitions"

export interface ICompetitionsInnerWinsProps {
  readonly competition: GmtCompetition
  readonly showWinner?: boolean
}

export default function CompetitionWins(props: ICompetitionsInnerWinsProps): ReactElement {
  const { competition, showWinner = false } = props
  const drawer = useDrawer()
  const setMessage = useMessages()
  const wins = useStore(competition.dataStore, (state) => state.wins)
  const isActive = useStore(competition.dataStore, (state) => !!state.gameStatus && state.gameStatus !== "Entwurf")
  const selectedStore = useCreateStore<GmtWin>()

  const deleteWin = useCallback(
    (win: GmtWin) => {
      removeWin(competition, win).then((success) => {
        if (!success) {
          setMessage({ message: "Unerwarteter Fehler beim Löschen des Gewinns", severity: "error" })
        }
      })
      selectedStore.set(undefined)
      drawer()
    },
    [competition, drawer, selectedStore, setMessage]
  )

  const onDuplicateWin = useCallback(
    (win: GmtWin): Promise<void> => {
      return duplicateWin(competition, win).then(
        (copy) => {
          selectedStore.set(copy)
          drawer()
        },
        (error) => {
          setMessage({ message: "Unerwarteter Fehler beim Kopieren des Gewinns", severity: "error" })
          console.error(error)
        }
      )
    },
    [competition, selectedStore, drawer, setMessage]
  )

  const onCreateWin = useCallback(() => {
    createWin(competition).then((win) => {
      if (win) {
        selectedStore.set(win)
        drawer()
      } else {
        setMessage({ message: "Fehler beim Anlegen des Gewinns", severity: "error" })
      }
    })
  }, [competition, selectedStore, drawer, setMessage])

  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Gewinn(-e)
        </Typography>
        {!isActive && (
          <Button variant="outlined" onClick={onCreateWin} startIcon={<AddIcon />}>
            Gewinn
          </Button>
        )}
      </Toolbar>
      <Stack spacing={2} marginBottom={2}>
        {wins?.map((win, index) => (
          <WinPaper
            key={win.localId}
            win={win}
            index={index + 1}
            deleteWin={deleteWin}
            duplicateWin={onDuplicateWin}
            selectedStore={selectedStore}
            readOnly={isActive}
            showWinner={showWinner}
          />
        ))}
      </Stack>
    </Paper>
  )
}
