import { ReactElement, useCallback, useMemo, useState } from "react"

import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"

import { useStore } from "core"
import { GmtProject } from "server/model"
import { CSessionState } from "../../../state"
import { useDrawer } from "../../../components/LayoutContext"
import StepOne from "./StepOne"
import StepTwo from "./StepTwo"
import StepThree from "./StepThree"

const steps = ["Projektdaten", "Telco", "Tech"]

export default function NewProject(): ReactElement {
  const [step, setStep] = useState(0)
  const user = useStore(CSessionState, (state) => state.user)
  const project = useMemo(
    () =>
      new GmtProject().initialize({
        state: "0",
        serviceNumbersOnline: [],
        serviceNumbersTv: [],
        keywordsOnline: [],
        keywordsTv: [],
        projectManager: user ? [user] : [],
        probability: "10",
        winningLine: 1,
        statistics: "Minutenstatistik",
        extrapolation: "Am Ende des Sendefensters",
      }),
    [user]
  )

  const drawer = useDrawer()
  const goBack = useCallback(() => {
    setStep((step) => Math.max(0, step - 1))
    drawer()
  }, [drawer])
  const goNext = useCallback(() => {
    setStep((step) => Math.min(steps.length - 1, step + 1))
    drawer()
  }, [drawer])

  return (
    <Container maxWidth={false} disableGutters>
      <Stack spacing={2} padding={2}>
        <Stepper activeStep={step} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {step === 0 ? (
          <StepOne project={project} goNext={goNext} />
        ) : step === 1 ? (
          <StepTwo project={project} goBack={goBack} goNext={goNext} />
        ) : step === 2 ? (
          <StepThree project={project} goBack={goBack} />
        ) : null}
      </Stack>
    </Container>
  )
}
