import { Fragment, ReactElement, useCallback, useState } from "react"

import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import LoadingButton from "@mui/lab/LoadingButton"

import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck"
import LockResetIcon from "@mui/icons-material/LockReset"
import PersonOffIcon from "@mui/icons-material/PersonOff"

import { useStore } from "core"
import { GmtWinner } from "server/model"
import { setWinnerComplete } from "server/logic/winners"
import { CSessionState } from "../../../state"
import { useMessages } from "../../../components/LayoutContext"
import ErrorsDialog, { useErrorDialog } from "../../../components/shared/ErrorsDialog"
import { useNavigate } from "react-router-dom"

export default function WinnerButtons({ winner }: { readonly winner: GmtWinner }): ReactElement {
  const winnerStatus = useStore(winner.dataStore, (data) => data.winnerStatus)
  const completedBy = useStore(winner.dataStore, (data) => data.completedBy)
  const user = useStore(CSessionState, (state) => state.user)

  const setMessage = useMessages()
  const [loading, setLoading] = useState(false)

  const { handleOpen, dialogProps, setDialogErrors } = useErrorDialog()

  const navigate = useNavigate()
  const goBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const errorHandler = useCallback(
    (error: unknown) => {
      setMessage({
        message: typeof error === "string" ? error : "Es ist ein unerwarteter Fehler aufgetreten",
        severity: "error",
      })
      setLoading(false)
      console.error(error)
    },
    [setMessage]
  )

  const doSetComplete = useCallback(() => {
    if (!user) return
    setLoading(true)
    setWinnerComplete(user, winner).then((errors) => {
      setLoading(false)
      setDialogErrors(errors)
      if (errors.length) {
        handleOpen()
      } else {
        setMessage({
          message: "Status erfolgreich auf 'Vollständig' gesetzt",
          severity: "success",
        })
      }
    }, errorHandler)
  }, [errorHandler, handleOpen, setDialogErrors, setMessage, user, winner])

  const doUnsetComplete = useCallback(() => {
    setLoading(true)
    winner.dataStore.update({ winnerStatus: { $set: "Benachrichtigt" } })
    winner.save().then(() => {
      setLoading(false)
      setMessage({
        message: "Status 'Vollständig' erfolgreich zurück gesetzt",
        severity: "success",
      })
    }, errorHandler)
  }, [errorHandler, setMessage, winner])

  const doSetValidated = useCallback(() => {
    setLoading(true)
    winner.dataStore.update({ winnerStatus: { $set: "Geprüft" } })
    winner.save().then(() => {
      setLoading(false)
      setMessage({
        message: "Status erfolgreich auf 'Geprüft' gesetzt",
        severity: "success",
      })
    }, errorHandler)
  }, [errorHandler, setMessage, winner])

  const doSetBookout = useCallback(() => {
    setLoading(true)
    winner.dataStore.update({ winnerStatus: { $set: "Ausgebucht" } })
    winner.save().then(() => {
      setLoading(false)
      setMessage({
        message: "Status erfolgreich auf 'Ausgebucht' gesetzt",
        severity: "success",
      })
    }, errorHandler)
  }, [errorHandler, setMessage, winner])

  return (
    <Fragment>
      <Stack direction="row" spacing={2}>
        <Button variant="outlined" onClick={goBack}>
          Zurück
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        {(!winnerStatus || winnerStatus === "Gezogen" || winnerStatus === "Benachrichtigt") && (
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={doSetComplete}
            loadingPosition="start"
            startIcon={<PlaylistAddCheckIcon />}
          >
            Gewinnerdaten vollständig
          </LoadingButton>
        )}
        {winnerStatus === "Vollständig" && (
          <LoadingButton
            variant="outlined"
            color="warning"
            loading={loading}
            onClick={doUnsetComplete}
            loadingPosition="start"
            startIcon={<LockResetIcon />}
          >
            Gewinnerdaten fehlerhaft
          </LoadingButton>
        )}
        {winnerStatus === "Vollständig" && completedBy?.getApiId() !== user?.getApiId() && (
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={doSetValidated}
            loadingPosition="start"
            startIcon={<PlaylistAddCheckIcon />}
          >
            Gewinner geprüft
          </LoadingButton>
        )}
        {(winnerStatus === "Benachrichtigt" || winnerStatus === "Vollständig" || winnerStatus === "Geprüft") && (
          <LoadingButton
            variant="outlined"
            color="warning"
            loading={loading}
            onClick={doSetBookout}
            loadingPosition="start"
            startIcon={<PersonOffIcon />}
          >
            Gewinner ausbuchen
          </LoadingButton>
        )}
      </Stack>
      <ErrorsDialog
        {...dialogProps}
        title="Statusänderung nicht möglich"
        description="Die Statusänderung ist aus folgenden Gründen nicht möglich:"
      />
    </Fragment>
  )
}
