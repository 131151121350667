import { ToOneRelation, ToManyRelation } from "../../coloquent"
import { GmtModel } from "./core"
import { GmtProject } from "./GmtProject"
import { GmtWin } from "./GmtWin"
import { GmtKeyword } from "./GmtKeyword"
import { GmtUser } from "./GmtUser"
import { GmtFile } from "./GmtFile"

export const CGameStatus = ["Entwurf", "Aktiv", "Gestartet", "Beendet", "Gezogen", "Abgeschlossen"] as const
export type TGameStatus = (typeof CGameStatus)[number]
export const CGameStatusNames: Readonly<Record<TGameStatus, string>> = {
  Abgeschlossen: "Abgeschlossen",
  Aktiv: "Aktiv",
  Beendet: "Beendet",
  Entwurf: "Entwurf",
  Gestartet: "Gestartet",
  Gezogen: "Gezogen",
}

export interface ICompetitionData {
  readonly title: string
  readonly question: string
  readonly answer: string
  readonly answers: ReadonlyArray<string>
  readonly gameStatus: TGameStatus | null
  readonly participationStartDate: Date | null
  readonly participationEndDate: Date | null
  readonly exported: Array<string> | null
  readonly onlinePublished: Date | null
  readonly teletextPublished: Date | null
  readonly notes: string

  readonly user: GmtUser | null
  readonly project: GmtProject | null
  readonly documents: ReadonlyArray<GmtFile>
  readonly additionalProjects: ReadonlyArray<GmtProject>
  readonly wins: ReadonlyArray<GmtWin>
  readonly smsKeywords: ReadonlyArray<GmtKeyword>
}

export class GmtCompetition extends GmtModel<ICompetitionData> {
  static jsonApiType = "node--gmt_competition"
  static endpoint = "node/gmt_competition"

  static dates = [
    "field_participation_start_date",
    "field_participation_end_date",
    "field_publishing_online",
    "field_publishing_teletext",
  ]

  static attMapping: Readonly<Record<string, keyof ICompetitionData>> = {
    title: "title",
    field_game_status: "gameStatus",
    field_participation_start_date: "participationStartDate",
    field_participation_end_date: "participationEndDate",
    field_question: "question",
    field_correct_answer: "answer",
    field_answer: "answers",
    field_export_gewinnarena: "exported",
    field_publishing_online: "onlinePublished",
    field_publishing_teletext: "teletextPublished",
    field_notices: "notes",
  }
  static relMapping: Readonly<Record<string, keyof ICompetitionData>> = {
    field_project_data: "project",
    field_more_projects: "additionalProjects",
    field_win: "wins",
    field_sms_keyword: "smsKeywords",
    uid: "user",
    field_documents: "documents",
  }
  nid(): number {
    return this.getAttribute("drupal_internal__nid")
  }

  field_project_data(): ToOneRelation<GmtProject, this> {
    return this.hasOne(GmtProject)
  }

  field_more_projects(): ToManyRelation<GmtProject, this> {
    return this.hasMany(GmtProject)
  }

  field_win(): ToManyRelation<GmtWin, this> {
    return this.hasMany(GmtWin)
  }

  field_sms_keyword(): ToManyRelation<GmtKeyword, this> {
    return this.hasMany(GmtKeyword)
  }

  field_documents(): ToManyRelation<GmtFile, this> {
    return this.hasMany(GmtFile)
  }
}
