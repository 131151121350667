import { MouseEventHandler, ReactElement, useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import update from "immutability-helper"

import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { PopoverOrigin } from "@mui/material/Popover/Popover"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"

import LogoutIcon from "@mui/icons-material/Logout"
import PersonIcon from "@mui/icons-material/Person"
import MenuIcon from "@mui/icons-material/Menu"

import { logout } from "../../../server/logic"
import { CSessionState } from "../../state"
import { useLayoutContextAndDispatch } from "../LayoutContext"

import "./LayoutHeader.scss"
import { CSidebarSizes } from "../../constants"

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
}
const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
}

export default function LayoutHeader(): ReactElement {
  const [context, setContext] = useLayoutContextAndDispatch()
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const navigate = useNavigate()
  const handleMenu = useCallback<MouseEventHandler<HTMLElement>>((event) => setAnchorEl(event.currentTarget), [])
  const handleClose = useCallback(() => setAnchorEl(undefined), [])
  const handleLogout = useCallback(() => {
    logout().then(() => {
      CSessionState.update({ state: { $set: "created" }, user: { $set: undefined } })
      navigate("/")
    })
  }, [navigate])
  const toggleSideBar = useCallback(() => {
    setContext((ctx) => update(ctx, { sideBarOpen: { $set: !ctx.sideBarOpen } }))
  }, [setContext])
  return (
    <AppBar
      id="LayoutHeader"
      position="absolute"
      color="transparent"
      style={{
        paddingRight: context.drawer?.width || 0,
        paddingLeft: context.sideBarOpen ? CSidebarSizes[0] : CSidebarSizes[1],
      }}
    >
      <Toolbar sx={{ marginLeft: "10px" }}>
        <IconButton size="large" color="inherit" onClick={toggleSideBar}>
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }} />
        <IconButton size="large" onClick={handleMenu} color="inherit">
          <PersonIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          keepMounted
          transformOrigin={transformOrigin}
          open={!!anchorEl}
          onClose={handleClose}
        >
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}
