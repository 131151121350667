import { ReactElement, SyntheticEvent, useCallback, useState } from "react"

import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"

import { GmtWin } from "server/model"
import MaterialPricesTab from "./MaterialPricesTab"
import CashPriceTab from "./CashPriceTab"
import CustomPriceTab from "./CustomPriceTab"

export interface IPriceDrawerProps {
  readonly win: GmtWin
  readonly initTab?: number
  readonly standAlone?: boolean
}

export default function PriceDrawer(props: IPriceDrawerProps): ReactElement {
  const { win, initTab = 0, standAlone = false } = props
  const [tab, setTab] = useState(initTab)
  const onTabChange = useCallback((event: SyntheticEvent, value: number) => setTab(value), [])
  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {!standAlone && (
          <Tabs value={tab} onChange={onTabChange} variant="fullWidth">
            <Tab label="Shop" />
            <Tab label="Manueller Preis" />
            <Tab label="Geldpreis" />
          </Tabs>
        )}
      </Box>
      {tab === 0 ? (
        <MaterialPricesTab win={win} />
      ) : tab === 1 ? (
        <CustomPriceTab win={win} />
      ) : tab === 2 ? (
        <CashPriceTab win={win} />
      ) : null}
    </Box>
  )
}
