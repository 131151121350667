import { ReactElement, useCallback, useEffect } from "react"
import { useLocation } from "react-router-dom"

import Drawer from "@mui/material/Drawer"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"

import CloseIcon from "@mui/icons-material/Close"

import { useDrawer, useLayoutContext } from "../LayoutContext"

export default function ContextBar(): ReactElement | null {
  const location = useLocation()
  const { drawer } = useLayoutContext()
  const setDrawer = useDrawer()

  const onCloseClick = useCallback(() => setDrawer(), [setDrawer])

  useEffect(() => setDrawer(), [location.pathname, setDrawer])

  return drawer ? (
    <Drawer
      id="ContextBar"
      anchor="right"
      variant="permanent"
      style={{ width: drawer.width }}
      PaperProps={{ style: { width: drawer.width } }}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Typography component="h2" variant="h6" noWrap>
            {drawer.title}
          </Typography>
          {drawer.subTitle && (
            <Typography component="div" variant="subtitle1" noWrap>
              {drawer.subTitle}
            </Typography>
          )}
        </Box>
        <IconButton onClick={onCloseClick}>
          <CloseIcon />
        </IconButton>
      </Toolbar>
      {drawer.element}
    </Drawer>
  ) : null
}
