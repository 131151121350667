import { GmtProject } from "../../model"
import { concatSets, getObjectErrors } from "../core"
import { saveNewKeywords } from "../keywords/saving"

/**
 * Save a project in a consistent way:
 * - locking / unlocking references
 * - creating new keywords
 *
 * @param project
 */
export async function saveProject(project: GmtProject): Promise<GmtProject> {
  const { dataStore } = project
  const { participationType } = dataStore.state

  // cleanup keywords and service-numbers based on participation-type
  if (participationType === "online") {
    dataStore.update({ keywordsTv: { $set: [] }, serviceNumbersTv: { $set: [] } })
  } else if (participationType === "phone") {
    dataStore.update({ keywordsOnline: { $set: [] }, serviceNumbersOnline: { $set: [] } })
  }

  const { keywordsOnline, keywordsTv } = dataStore.state
  try {
    await saveNewKeywords(concatSets(keywordsTv, keywordsOnline))
    await project.save()
    return project
  } catch (error) {
    throw getObjectErrors(GmtProject, error)
  }
}
