import {
  getPrefetchRelationsForBankData,
  getPrefetchRelationsForPrice,
  GmtCommunication,
  GmtWinner,
  IJsonApiResponse,
  TWinnerStatus,
} from "../../model"
import { ConditionFilterSpec } from "../../../coloquent/filter/ConditionFilterSpec"
import { IFetchResult, IPagerInfo } from "../core"
import { SortDirection } from "../../../coloquent"
import { getPrefetchRelationsForProject } from "../projects"
import { GroupFilterSpec } from "../../../coloquent/filter/GroupFilterSpec"
import { parseCurrency } from "../../../ui/components/shared/parseCurrency"

// https://www.drupal.org/docs/core-modules-and-themes/core-modules/jsonapi-module/filtering

const CWith = [
  ...getPrefetchRelationsForPrice("field_ref_win.field_price."),
  "field_ref_win.field_ref_competition.field_project_data.field_sender.field_station_logo",
  "field_ref_win.field_ref_competition.field_project_data.field_project_manager",
  "field_completed_by",
  "field_gmt_drawing_ref",
  "field_gmt_drawing_ref.field_drawing_recordwav",
]

export async function fetchWinner(id: string): Promise<GmtWinner | null> {
  const result = await GmtWinner.find(id, [
    ...CWith,
    ...getPrefetchRelationsForProject("field_ref_win.field_ref_competition.field_project_data."),
    ...getPrefetchRelationsForProject("field_ref_win.field_ref_competition.field_more_projects."),
    ...getPrefetchRelationsForBankData("field_bank_data."),
  ])
  const rv = result.getData()
  rv?.resetBaseStore()
  return rv
}

export async function fetchCommunication(winner: GmtWinner): Promise<ReadonlyArray<GmtCommunication>> {
  const result = await GmtCommunication.with(["uid", "field_ref_winner"])
    .where("field_ref_winner.id", winner.getApiId() || "_na_")
    .orderBy("created", SortDirection.DESC)
    .get()
  return result.getData()
}

export async function fetchWinners(spec: IFetchWinnersSpec): Promise<IFetchResult<GmtWinner>> {
  const { pager, filter, sort } = spec
  GmtWinner.setPageSize(pager.pageSize)
  const finalStatus =
    filter.winnerStates?.length === 1 && !filter.winnerStatus
      ? filter.winnerStates[0]
      : filter.winnerStates?.length
      ? undefined
      : filter.winnerStatus

  const finalStates = filter.winnerStates?.length
    ? filter.winnerStates
      ? [...filter.winnerStates, filter.winnerStatus]
      : filter.winnerStates
    : []
  let query = GmtWinner.with(CWith)
  if (filter.text) {
    query = query.filter(
      new GroupFilterSpec("OR", [
        new ConditionFilterSpec("title", "CONTAINS", filter.text),
        new ConditionFilterSpec("field_ref_win.drupal_internal__nid", "CONTAINS", filter.text),
        new ConditionFilterSpec("field_winner_address.given_name", "CONTAINS", filter.text),
        new ConditionFilterSpec("field_winner_address.additional_name", "CONTAINS", filter.text),
        new ConditionFilterSpec("field_winner_address.family_name", "CONTAINS", filter.text),
        new ConditionFilterSpec("field_winner_address.postal_code", "CONTAINS", filter.text),
        new ConditionFilterSpec("field_winner_address.address_line1", "CONTAINS", filter.text),
        new ConditionFilterSpec("field_winner_address.address_line2", "CONTAINS", filter.text),
        new ConditionFilterSpec("field_winner_address.locality", "CONTAINS", filter.text),
        new ConditionFilterSpec("field_winner_mail", "CONTAINS", filter.text),
        new ConditionFilterSpec("field_mobil", "CONTAINS", filter.text),
        new ConditionFilterSpec("field_phone", "CONTAINS", filter.text),
        new ConditionFilterSpec(
          "field_ref_win.field_price.field_wps_number_ref.field_wps_number",
          "CONTAINS",
          filter.text
        ),
        new ConditionFilterSpec("field_ref_win.field_price.title", "CONTAINS", filter.text),
        new ConditionFilterSpec(
          "field_ref_win.field_price.field_value_in_euro",
          "CONTAINS",
          parseCurrency(filter.text)
        ),
        new ConditionFilterSpec(
          "field_ref_win.field_price.field_price_in_euro",
          "CONTAINS",
          parseCurrency(filter.text)
        ),
        new ConditionFilterSpec(
          "field_ref_win.field_price.field_winning_amount",
          "CONTAINS",
          parseCurrency(filter.text)
        ),
      ])
    )
  }
  if (finalStatus) {
    query = query.where("field_winner_status", finalStatus)
  }
  if (finalStates.length > 1) {
    query = query.filter(
      new GroupFilterSpec(
        "OR",
        finalStates.map((state) =>
          state
            ? new ConditionFilterSpec("field_winner_status", "=", state)
            : new ConditionFilterSpec("field_winner_status", "IS NULL")
        )
      )
    )
  }
  if (filter.minEnd) {
    query = query.filter(
      new ConditionFilterSpec(
        "field_ref_win.field_ref_competition.field_participation_end_date",
        ">=",
        filter.minEnd.toISOString()
      )
    )
  }
  if (filter.maxEnd) {
    query = query.filter(
      new ConditionFilterSpec(
        "field_ref_win.field_ref_competition.field_participation_end_date",
        "<=",
        filter.maxEnd.toISOString()
      )
    )
  }
  if (filter.sender) {
    query = query.where("field_ref_win.field_ref_competition.field_project_data.field_sender.id", filter.sender)
  }
  if (filter.projectManager) {
    query = query.filter(
      new ConditionFilterSpec(
        "field_ref_win.field_ref_competition.field_project_data.field_project_manager.name",
        "CONTAINS",
        filter.projectManager
      )
    )
  }
  if (filter.win) {
    query = query.where("field_ref_win.id", filter.win)
  }
  if (sort?.key) {
    query = query.orderBy(sort.key, sort.desc ? SortDirection.DESC : SortDirection.ASC)
  }
  const response = await query.get(pager.page + 1)
  const rawResponse = response.getHttpClientResponse().getData() as IJsonApiResponse
  return {
    pager,
    count: parseInt(rawResponse.meta?.count || "0"),
    data: response.getData(),
  }
}

export interface IFetchWinnersSpec {
  readonly pager: IPagerInfo
  readonly filter: IFetchWinnersFilter
  readonly sort?: ISortWinnerSpec
}

export interface IFetchWinnersFilter {
  readonly text?: string
  readonly winnerStatus?: TWinnerStatus
  readonly winnerStates?: ReadonlyArray<TWinnerStatus | null>
  readonly minEnd?: Date | null
  readonly maxEnd?: Date | null
  readonly sender?: string
  readonly projectManager?: string
  readonly win?: string
}

export interface ISortWinnerSpec {
  readonly key: string
  readonly desc?: boolean
}
