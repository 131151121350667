import { Store } from "./Store"
import { useCallback, useLayoutEffect, useMemo, useState } from "react"

/**
 * Use this hook to work with the state of a provided store.
 * A re-rendering of the using component is triggered on each state change.
 *
 * @param store
 */
export function useStore<S>(store: Store<S>): S
export function useStore<S>(store: Store<S> | undefined): S | undefined
export function useStore(store: undefined): undefined
export function useStore<S, R>(
  store: Store<S> | undefined,
  selector: (state: Readonly<S>) => R,
  deps?: ReadonlyArray<unknown>
): R

export function useStore<S, R>(
  store?: Store<S>,
  selector?: (state: Readonly<S>) => R,
  deps: ReadonlyArray<unknown> = []
): R | undefined {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectorCb = useCallback(selector || ((state: Readonly<S>) => state as unknown as R), deps)
  const [state, setState] = useState<Readonly<R> | undefined>(() => store && selectorCb(store.state))
  useLayoutEffect(() => store?.listen(selectorCb, setState), [selectorCb, store])
  return state
}

/**
 * Use this hook, to create a local store singleton instance, where the state itself is not needed locally.
 * This hook does not force a re-rendering of the using component.
 * This is usefully, when you only want to share state over multiple child components, without using the state itself.
 */
export function useCreateStore<T>(): Store<T | undefined>
export function useCreateStore<T>(initialState: Readonly<T>): Store<T>
export function useCreateStore<T>(initialState: () => Readonly<T>): Store<T>
export function useCreateStore<T>(initialState?: Readonly<T> | (() => Readonly<T>)): Store<T | undefined>

export function useCreateStore<T>(initialState?: Readonly<T> | (() => Readonly<T>)): Store<T | undefined> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => Store.create<T | undefined>(initialState), [])
}

/**
 * Use this hook, if you want to work with a local state, that is wrapped into a store.
 * This hook combines useCreateStore() and useStore()
 */
export function useStateStore<T>(): [Readonly<T> | undefined, Store<T | undefined>]
export function useStateStore<T>(initialState: Readonly<T>): [Readonly<T>, Store<T>]
export function useStateStore<T>(initialState: () => Readonly<T>): [Readonly<T>, Store<T>]

export function useStateStore<T>(
  initialState?: Readonly<T> | (() => Readonly<T>)
): [Readonly<T> | undefined, Store<T | undefined>] {
  const store = useCreateStore(initialState)
  const state = useStore(store)
  return [state, store]
}
