import axios, { AxiosError } from "axios"
import { IErrors, stripErrors } from "core"
import { GmtBankData, GmtUser, GmtWinner, IAddress, IBankData } from "../../model"

export function cleanIban(iban: string): string {
  return iban
    .trim()
    .toUpperCase()
    .split(/[^A-Z0-9]/)
    .join("")
}

export function formatIban(iban: string): string {
  const slices = cleanIban(iban).match(/.{1,4}/g)
  const rv = slices?.length ? slices.join(" ") : ""
  return rv + (slices?.length && slices[slices.length - 1].length === 4 ? " " : "")
}

export function getFullNameFromAddress(address: Readonly<Partial<IAddress>>): string {
  const { given_name, family_name } = address
  return ((given_name || "") + " " + (family_name || "")).trim()
}

export async function validateAndSaveBankData(
  user: GmtUser,
  winner: GmtWinner,
  bankData: GmtBankData
): Promise<IErrors<IBankData> | undefined> {
  const { iban } = bankData.dataStore.state
  const cleanedIban = cleanIban(iban || "")
  if (cleanedIban?.length !== 22) {
    return { iban: "Das ist keine gültige IBAN" }
  } else {
    try {
      const response = await axios.get<ICheckResponse>(
        "https://gmt-backend.redorbit.de/gmt/check-bankaccount?iban=" + encodeURIComponent(cleanedIban)
      )
      const { title } = bankData.dataStore.state
      if (response.data.iban) bankData.dataStore.update({ iban: { $set: response.data.iban } })
      if (response.data.name) bankData.dataStore.update({ bankName: { $set: response.data.name } })
      if (!title?.trim()) {
        const name = getFullNameFromAddress(winner.addressDataStore.state)
        if (name) bankData.dataStore.update({ title: { $set: name } })
      }
      const data = bankData.dataStore.state
      const errors = stripErrors<IBankData>({
        title: data.title?.trim() ? "" : "missing",
        bankName: data.bankName?.trim() ? "" : "missing",
      })
      if (errors) return errors
      const isNew = !bankData.getApiId()
      bankData.dataStore.update({ modifiedBy: { $set: user } })
      await bankData.save()
      if (isNew) {
        const winnerData = winner.dataStore.state
        const winnerAddressData = winner.addressDataStore.state
        winner.addressDataStore.set(winner.addressBaseStore.state)
        winner.dataStore.set(winner.baseStore.state)
        winner.dataStore.update({ bankData: { $set: bankData } })
        await winner.save()
        winner.dataStore.set({ ...winnerData, bankData })
        winner.addressDataStore.set(winnerAddressData)
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data?.iban) {
        return { iban: "Das ist keine gültige IBAN" }
      } else {
        throw error
      }
    }
  }
}

export async function setBankDataValidated(user: GmtUser, bankData: GmtBankData): Promise<void> {
  const { validated, modifiedBy, iban } = bankData.baseStore.state
  if (!bankData.getApiId() || bankData.hasChanges()) throw "Es gibt ungespeicherte Änderungen"
  if (validated) throw "Bankdaten sind schon validiert"
  if (!iban?.trim()) throw "Die IBAN fehlt"
  if (modifiedBy?.getApiId() === user.getApiId()) {
    throw "Der letzte Bearbeiter und der Prüfer dürfen nicht identisch sein"
  }
  bankData.dataStore.update({ validated: { $set: true }, validatedBy: { $set: user } })
  try {
    await bankData.save()
  } catch (error) {
    bankData.dataStore.update({ validated: { $set: false }, validatedBy: { $set: undefined } })
    throw error
  }
}

interface ICheckResponse {
  iban: string
  error: string
  status: number
  name: string | null
  bic: string | null
}
