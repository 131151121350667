import { ReactElement, useState } from "react"

import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import { GmtHistory } from "../../../server/model"
import { useStore } from "../../../core"
import format from "date-fns/format"
import de from "date-fns/locale/de"

export interface IHistoryListRowProps {
  readonly history: GmtHistory
}

export default function HistoryListRow(props: IHistoryListRowProps): ReactElement {
  const { history } = props
  const [selected] = useState(false)
  const data = useStore(history.dataStore)
  return (
    <TableRow className={selected ? "selected" : undefined}>
      <TableCell>{data.label}</TableCell>
      <TableCell>{data.newValue}</TableCell>
      <TableCell>{data.oldValue}</TableCell>
      <TableCell>{data.created && format(data.created, "d.M.yyyy - H:mm", { locale: de })}</TableCell>
      <TableCell>{data.user?.name}</TableCell>
    </TableRow>
  )
}
