import { IErrors, stripErrors } from "core"
import { GmtCashPrice, GmtCompetition, GmtMaterialPrice, GmtPrice, GmtWin, ICompetitionData } from "../../model"
import { getObjectErrors } from "../core"

export function validateCompetitionData(data: Partial<ICompetitionData>): IErrors<ICompetitionData> | undefined {
  return stripErrors<ICompetitionData>({
    title: data.title?.trim() ? "" : "missing",
  })
}

export async function createPrice(win: GmtWin, price: GmtPrice): Promise<boolean> {
  win.addToRelation("prices", price)
  if (win.getApiId()) {
    try {
      await price.save()
      await win.save()
    } catch (error) {
      win.removeFromRelation("prices", price)
      if (price.getApiId()) {
        await price.delete().catch((error) => {
          // ignore error on rollback
          console.error(error)
        })
      }
      console.error(error)
      return false
    }
  }
  return true
}

export async function duplicateWin(comp: GmtCompetition, win: GmtWin): Promise<GmtWin> {
  const copy = new GmtWin().initialize({
    title: "Gewinn Nº" + ((comp.dataStore.state.wins?.length || 0) + 1),
    prices: win.dataStore.state.prices?.map((price) => {
      if (price instanceof GmtCashPrice) {
        return new GmtCashPrice().initialize(price.dataStore.state)
      } else if (price instanceof GmtMaterialPrice) {
        return new GmtMaterialPrice().initialize(price.dataStore.state)
      } else {
        return price
      }
    }),
  })
  comp.addToRelation("wins", copy)
  if (comp.getApiId()) await saveCompetition(comp)
  return copy
}

export async function removeWin(comp: GmtCompetition, win: GmtWin): Promise<boolean> {
  comp.removeFromRelation("wins", win)
  const { wins } = comp.dataStore.state
  const changedWins = wins?.filter((win, idx) => {
    const newTitle = "Gewinn Nº" + (idx + 1)
    const changed = win.dataStore.state.title !== newTitle
    if (changed) {
      win.dataStore.update({ title: { $set: newTitle } })
    }
    return changed
  })
  if (!comp.getApiId()) return true
  let success = true
  if (changedWins?.length) {
    await Promise.all(
      changedWins.map((win) =>
        win.save().catch((error) => {
          console.error(error)
          success = false
        })
      )
    )
  }
  try {
    await saveCompetition(comp)
    if (win.getApiId()) {
      const { prices } = win.baseStore.state
      if (prices?.length) {
        await Promise.all(
          prices.map((price) =>
            price.delete().catch((error) => {
              console.error(error)
              success = false
            })
          )
        )
      }
      await win.delete()
    }
    return success
  } catch (error) {
    console.error(error)
    return false
  }
}

export async function createWin(comp: GmtCompetition): Promise<GmtWin | null> {
  const win = new GmtWin().initialize({
    title: "Gewinn Nº" + ((comp.dataStore.state.wins?.length || 0) + 1),
    prices: [],
  })
  comp.addToRelation("wins", win)
  if (comp.getApiId()) {
    try {
      await win.save()
      await comp.save()
    } catch (error) {
      console.error(error)
      comp.removeFromRelation("wins", win)
      if (win.getApiId()) {
        await win.delete().catch((error) => {
          console.error(error)
        })
      }
      return null
    }
  }
  return win
}

export async function removePrice(win: GmtWin, price: GmtPrice): Promise<boolean> {
  let success = true
  win.removeFromRelation("prices", price)
  if (win.getApiId()) {
    try {
      await win.save()
    } catch (error) {
      console.error(error)
      success = false
    }
  }
  if (price.getApiId()) {
    try {
      await price.delete()
    } catch (error) {
      console.error(error)
      success = false
    }
  }
  return success
}

export async function saveCompetition(competition: GmtCompetition): Promise<GmtCompetition> {
  const { dataStore } = competition
  const { wins } = dataStore.state

  let createdWins: ReadonlyArray<GmtWin> | undefined
  const newWins = wins?.filter((entry) => !entry.getApiId())
  if (newWins?.length) {
    const saveResult = await Promise.all(newWins.map(saveWin))
    createdWins = saveResult.filter((entry): entry is GmtWin => !!entry)
  }
  try {
    if (newWins?.length && newWins.length !== createdWins?.length) throw "Fehler beim Speichern der Gewinne"
    await competition.save()
    // we need to set the reverse relation on all created wins
    if (createdWins?.length) {
      await Promise.all(
        createdWins.map((win) => {
          win.dataStore.update({ competition: { $set: competition } })
          return win.save().catch((error) => {
            console.error(error) // ignore unexpected errors (potential data inconsistency)
          })
        })
      )
    }
  } catch (error) {
    // delete created wins
    if (createdWins?.length) {
      await Promise.all(
        createdWins.map((win) =>
          win.delete().catch((error) => {
            console.error(error)
          })
        )
      )
    }
    throw getObjectErrors(GmtCompetition, error)
  }
  return competition
}

async function saveWin(win: GmtWin): Promise<GmtWin | null> {
  const { prices } = win.dataStore.state
  let createdPrices: ReadonlyArray<GmtPrice> | undefined
  const newPrices = prices?.filter((entry) => !entry.getApiId())
  if (newPrices?.length) {
    const saveResult = await Promise.all(
      newPrices.map((price) =>
        price.save().then(
          () => price,
          (error) => {
            console.error(error)
            return null
          }
        )
      )
    )
    createdPrices = saveResult.filter((entry): entry is GmtPrice => !!entry)
  }
  try {
    if (newPrices?.length && newPrices.length !== createdPrices?.length) throw "Fehler beim Speichern der Preise"
    await win.save()
    return win
  } catch (error) {
    // delete created prices
    if (createdPrices?.length) {
      await Promise.all(
        createdPrices.map((price) =>
          price.delete().catch((error) => {
            console.error(error)
          })
        )
      )
    }
    console.error(error)
    return null
  }
}
