import { ReactElement, ReactNode } from "react"
import { Form, FormProps } from "react-final-form"

export interface IGmtFormProps<FormValues, InitialFormValues> extends FormProps<FormValues, InitialFormValues> {
  readonly useHtmlForm?: boolean
  readonly children: ReactNode
}

export function GmtForm<FormValues, InitialFormValues>(
  props: IGmtFormProps<FormValues, InitialFormValues>
): ReactElement {
  const { children, subscription, useHtmlForm, ...rest } = props
  return (
    <Form
      {...rest}
      subscription={subscription || {}}
      render={({ handleSubmit }) => {
        return useHtmlForm ? <form onSubmit={handleSubmit}>{children}</form> : children
      }}
    />
  )
}
