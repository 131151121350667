import { GmtPrice, IPriceData } from "./GmtPrice"
import { GmtProduct } from "./GmtProduct"
import { GmtSponsor } from "./GmtSponsor"
import { SaveResponse, ToOneRelation } from "../../coloquent"
import { GmtWps } from "./GmtWps"

export const CShippingOptions = ["1", "2", "3", "4"] as const
export type TShippingOptions = (typeof CShippingOptions)[number]
export const CShippingOptionsLabels: Readonly<Record<TShippingOptions, string>> = {
  "1": "Versand Partnershop",
  "2": "Versand PSD/Shop",
  "3": "Versand Sponsor",
  "4": "Versand UND Abrechnung Partnershop",
}

export interface IMaterialPriceData extends IPriceData {
  readonly orderStatus: string
  readonly comment: string
  readonly link: { readonly uri: string }
  readonly qty: number
  readonly price: string
  readonly invoiceNumber: string
  readonly wpsNumber: GmtWps | null
  readonly value: string
  readonly shippingOptions: TShippingOptions
  readonly product: GmtProduct | null
  readonly shippingDate: Date | null
}

export class GmtMaterialPrice extends GmtPrice<IMaterialPriceData> {
  static jsonApiType = "node--material_price"
  static endpoint = "node/material_price"

  static dates = ["field_shipping_date"]

  static attMapping: Readonly<Record<string, keyof IMaterialPriceData>> = {
    title: "title",
    field_order_status: "orderStatus",
    field_comment: "comment",
    field_shoplink: "link",
    field_quantity: "qty",
    field_price_in_euro: "price",
    field_value_in_euro: "value",
    field_invoice_number: "invoiceNumber",
    field_shipping_options: "shippingOptions",
    field_shipping_date: "shippingDate",
  }
  static relMapping: Readonly<Record<string, keyof IMaterialPriceData>> = {
    field_product: "product",
    field_sponsor: "sponsor",
    field_wps_number_ref: "wpsNumber",
  }

  // noinspection JSUnusedGlobalSymbols
  field_sponsor(): ToOneRelation<GmtSponsor, this> {
    return this.hasOne(GmtSponsor)
  }

  // noinspection JSUnusedGlobalSymbols
  field_product(): ToOneRelation<GmtProduct, this> {
    return this.hasOne(GmtProduct)
  }

  // noinspection JSUnusedGlobalSymbols
  field_wps_number_ref(): ToOneRelation<GmtWps, this> {
    return this.hasOne(GmtWps)
  }

  async save(): Promise<SaveResponse<this>> {
    const { wpsNumber } = this.dataStore.state
    if (wpsNumber && !wpsNumber.getApiId() && wpsNumber.dataStore.state.wpsNummer?.startsWith("Neu: ")) {
      const value = wpsNumber.dataStore.state.wpsNummer?.substring(5).trim()
      const newNumber = new GmtWps().initialize({
        wpsNummer: value,
        title: value + " - manuell",
        validity: { value: "1970-01-01T00:00:00+00:00", end_value: "1970-01-01T00:00:00+00:00" },
      })
      await newNumber.save()
      this.dataStore.update({ wpsNumber: { $set: newNumber } })
    }
    return super.save()
  }
}
