import { GmtHistory } from "../model"
import { fetchModel, IFetchResult, IFetchSpec } from "./core"
import format from "date-fns/format"

export async function fetchHistory(spec: IFetchSpec<IFetchHistoryFilter>): Promise<IFetchResult<GmtHistory>> {
  const { pager, filter, sort } = spec

  GmtHistory.setPageSize(pager.pageSize)
  let query = GmtHistory.with("user_id")
  if (filter.parentId) {
    query = query.option("views-argument[]", String(filter.parentId))
  }
  if (filter.createdMin) {
    query = query.option("views-filter[created][min]", format(filter.createdMin, "yyyy-MM-dd") + " 00:00:00")
  }
  if (filter.createdMax) {
    query = query.option("views-filter[created][max]", format(filter.createdMax, "yyyy-MM-dd") + " 23:59:59")
  }
  if (filter.label) {
    query = query.option("views-filter[label]", String(filter.label))
  }
  if (filter.oldValue) {
    query = query.option("views-filter[old_value]", String(filter.oldValue))
  }
  if (filter.newValue) {
    query = query.option("views-filter[new_value]", String(filter.newValue))
  }
  return await fetchModel(query, pager, sort)
}

export interface IFetchHistoryFilter {
  parentId: number | string
  createdMin?: Date | null
  createdMax?: Date | null
  label?: string
  oldValue?: string
  newValue?: string
}
