import { ReactElement } from "react"

import { Avatar } from "@mui/material"

import { useStore } from "core"
import { GmtSender } from "server/model"
import Tooltip from "@mui/material/Tooltip"
import { TableCellProps } from "@mui/material/TableCell/TableCell"

export const CSenderCellWidth = 70
export const CSenderCellProps: Readonly<TableCellProps> = {
  align: "center",
  width: CSenderCellWidth,
}

export interface ISenderImageProps {
  readonly sender: GmtSender
  readonly tooltip?: boolean
}

export default function SenderImage(props: ISenderImageProps): ReactElement {
  const { sender, tooltip } = props
  const { name, logo } = useStore(sender.dataStore)
  const avatar = (
    <Avatar
      variant="square"
      src={logo?.url}
      alt={name}
      sx={{ width: 24, height: 24 }}
      imgProps={{ style: { objectFit: "contain" } }}
    />
  )
  return tooltip ? <Tooltip title={name || ""}>{avatar}</Tooltip> : avatar
}
