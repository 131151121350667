import { ReactElement } from "react"

import Box from "@mui/material/Box"

import { GmtSender } from "server/model"
import SenderImage from "./SenderImage"

export default function SenderInfoBox({ sender }: { readonly sender: GmtSender }): ReactElement {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      <SenderImage sender={sender} />
      &nbsp;&nbsp;
      {sender.displayName}
    </Box>
  )
}
