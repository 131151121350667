import { ReactElement, useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"

import { fetchPartners, IFetchPartnersFilter } from "server/logic/fetchPartners"
import { CRoutePartnerCreate } from "../routes"
import PartnersList from "ui/components/partners/PartnersList"
import PartnersFilter from "./PartnersFilter"
import { createPromiseTerminator } from "../../../../core"

export default function PartnersSearch(): ReactElement {
  const navigate = useNavigate()
  const [filter, setFilter] = useState<IFetchPartnersFilter>({
    text: "",
  })
  const [partnerCount, setPartnerCount] = useState<number>()
  useEffect(() => {
    return createPromiseTerminator(fetchPartners({ filter: {}, pager: { page: 0, pageSize: 1 } }), (result) => {
      setPartnerCount(result.count)
    })
  }, [])
  const createNew = useCallback(() => navigate(CRoutePartnerCreate), [navigate])
  return (
    <Paper id="PartnersSearch">
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Alle Partner{typeof partnerCount !== "number" ? "" : " [" + partnerCount + "]"}
        </Typography>
        <Button variant="contained" onClick={createNew}>
          Partner anlegen
        </Button>
      </Toolbar>
      <PartnersFilter filter={filter} setFilter={setFilter} />
      <PartnersList filter={filter} />
    </Paper>
  )
}
