import { PaginationSpec } from "./PaginationSpec"
import { QueryParam } from "../QueryParam"

export class OffsetBasedPaginationSpec extends PaginationSpec {
  protected pageOffsetParamName: string

  protected pageLimitParamName: string

  protected pageLimit: number

  protected pageOffset = 0

  private queryParams: QueryParam[] = []

  constructor(pageOffsetParamName: string, pageLimitParamName: string, limit: number) {
    super()
    this.pageOffsetParamName = pageOffsetParamName
    this.pageLimitParamName = pageLimitParamName
    this.pageLimit = limit
  }

  public getPaginationParameters(): QueryParam[] {
    this.queryParams = []

    if (this.pageOffset !== undefined) {
      this.queryParams.push(new QueryParam(`${this.pageOffsetParamName}`, this.pageOffset))
      this.queryParams.push(new QueryParam(`${this.pageLimitParamName}`, this.pageLimit))
    }

    return this.queryParams
  }

  public setPage(page: number): void {
    page = Math.max(page, 1)
    this.pageOffset = (page - 1) * this.pageLimit
  }

  public setPageLimit(pageLimit: number): void {
    this.pageLimit = pageLimit
  }
}
