import { ReactElement } from "react"
import TextField from "@mui/material/TextField"
import { BaseTextFieldProps } from "@mui/material/TextField/TextField"
import { useField } from "react-final-form"

export interface IFormTextFieldProps extends BaseTextFieldProps {
  readonly fieldName: string
}

export function FormTextField(props: IFormTextFieldProps): ReactElement {
  const { fieldName, helperText, ...rest } = props
  const { meta, input } = useField(fieldName, {
    subscription: {
      value: true,
      pristine: true,
      error: true,
      invalid: true,
      submitError: true,
    },
  })
  const hasError = !meta.pristine && meta.invalid
  return (
    <TextField
      {...input}
      {...rest}
      error={hasError}
      helperText={(hasError && (meta.error || meta.submitError)) || helperText}
      sx={{ flexGrow: 1 }}
      InputLabelProps={{ shrink: true }}
    />
  )
}
