import { Fragment, SyntheticEvent, useCallback, useEffect, useState } from "react"

import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"

import { createPromiseTerminator, useStore } from "core"
import { GmtSponsor, GmtModel } from "server/model"
import { entitiesEqual, fetchSponsors } from "server/logic"
import { useInlineEditing } from "../forms"

export default function SponsorAutoComplete({
  model,
  doSave,
  readOnly,
  required,
}: {
  readonly model: GmtModel<{ readonly sponsor: GmtSponsor | null }>
  readonly doSave?: () => Promise<unknown>
  readonly readOnly?: boolean
  readonly required?: boolean
}): JSX.Element {
  const { endAdornment, isInline } = useInlineEditing(
    "object",
    model.dataStore,
    "sponsor",
    required,
    model.baseStore,
    doSave
  )

  const value = useStore(model.dataStore, (state) => state.sponsor)

  const [sponsors, setSponsors] = useState<ReadonlyArray<GmtSponsor>>(
    model.dataStore.state.sponsor ? [model.dataStore.state.sponsor] : []
  )
  const [inputValue, setInputValue] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    return createPromiseTerminator(
      fetchSponsors({ filter: { text: inputValue }, sort: { key: "title" }, pager: { pageSize: 10, page: 0 } }),
      (result) => {
        const { sponsor } = model.dataStore.state
        setSponsors([
          ...(sponsor ? [sponsor] : []),
          ...result.data.filter((entry) => entry.getApiId() !== sponsor?.getApiId()),
        ])
        setLoading(false)
      },
      () => {
        setLoading(false)
      }
    )
  }, [inputValue, model.dataStore])

  const onInputChange = useCallback((event: SyntheticEvent, value: string) => {
    setInputValue(value)
  }, [])
  const getOptionLabel = useCallback((option: GmtSponsor) => option.dataStore.state.title || "k.A.", [])
  const onChange = useCallback(
    (event: SyntheticEvent, value: GmtSponsor | null) => model.dataStore.update({ sponsor: { $set: value } }),
    [model.dataStore]
  )

  return (
    <Autocomplete
      filterSelectedOptions
      disableClearable={required}
      openOnFocus
      options={sponsors}
      loading={loading}
      getOptionLabel={getOptionLabel}
      onInputChange={onInputChange}
      isOptionEqualToValue={entitiesEqual}
      value={value || undefined}
      onChange={onChange}
      disabled={readOnly}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={isInline ? "filled" : "outlined"}
          label="Sponsor"
          InputLabelProps={{ shrink: true }}
          size="small"
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {params.InputProps.endAdornment}
                {endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  )
}
