import { ReactNode, useCallback, useMemo } from "react"

import Grid from "@mui/material/Grid"

import { GmtMaterialPrice, ICashPriceData } from "server/model"
import { moneyValueTransformer } from "../../utils"
import { IInlineEditingProps, StoreTextField } from "../forms"
import SponsorAndShipping from "./SponsorAndShipping"
import WpsAutoComplete from "./WpsAutoComplete"

export default function CustomPricePanel({
  price,
  readOnly,
  children,
}: {
  readonly price: GmtMaterialPrice
  readonly readOnly?: boolean
  readonly children?: ReactNode
}): JSX.Element {
  const { baseStore, dataStore } = price

  const doSave = useCallback((): Promise<unknown> => {
    return price.save().catch((error) => {
      console.error(error)
    })
  }, [price])

  const inlineMode = !!price.getApiId()
  const inlineProps = useMemo<IInlineEditingProps<Partial<ICashPriceData>>>(
    () => (inlineMode ? { baseStore, doSave } : {}),
    [inlineMode, doSave, baseStore]
  )
  return (
    <Grid container spacing={2} marginTop={children ? 2 : undefined}>
      <Grid item xs={12}>
        <StoreTextField
          {...inlineProps}
          label="Bezeichnung"
          store={dataStore}
          property="title"
          disabled={readOnly}
          fullWidth
          required
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <StoreTextField
          {...inlineProps}
          label="Preis in Euro"
          store={dataStore}
          property="price"
          disabled={readOnly}
          fullWidth
          size="small"
          inputTransformer={moneyValueTransformer}
        />
      </Grid>
      <Grid item xs={6}>
        <StoreTextField
          {...inlineProps}
          label="Menge"
          store={dataStore}
          property="qty"
          type="number"
          disabled={readOnly}
          required
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <WpsAutoComplete model={price} readOnly={readOnly} doSave={inlineMode ? doSave : undefined} />
      </Grid>
      <Grid item xs={6}>
        <StoreTextField
          {...inlineProps}
          label="Rechnungsnummer"
          store={dataStore}
          property="invoiceNumber"
          disabled={readOnly}
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <StoreTextField
          {...inlineProps}
          label="Link zum Shop"
          store={dataStore}
          property="link"
          disabled={readOnly}
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <StoreTextField
          {...inlineProps}
          label="Notiz"
          store={dataStore}
          property="comment"
          disabled={false}
          multiline
          fullWidth
          rows={3}
        />
      </Grid>
      <SponsorAndShipping
        price={price}
        doSave={inlineMode ? doSave : undefined}
        inlineProps={inlineProps}
        readOnly={readOnly}
      />
      {children}
    </Grid>
  )
}
