import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react"
import update from "immutability-helper"
import { AlertProps } from "@mui/material/Alert/Alert"

export interface ILayoutContext {
  readonly sideBarOpen: boolean
  readonly drawer?: IDrawerSpec
  readonly message?: IAlertSpec
}

export interface IDrawerSpec {
  readonly element: ReactElement
  readonly width: number
  readonly title?: string
  readonly subTitle?: string
  readonly onClose?: () => void
}

export interface IAlertSpec extends Pick<AlertProps, "severity" | "action" | "icon"> {
  readonly message: string
  readonly timeout?: number
}

const CLayoutContext = createContext<ILayoutContextInternal | undefined>(undefined)

export interface ILayoutContextProps {
  readonly children: ReactNode
}

export function LayoutContext(props: ILayoutContextProps): ReactElement {
  const { children } = props
  const [state, setState] = useState<ILayoutContext>({ sideBarOpen: false })
  return <CLayoutContext.Provider value={{ state, setState }}>{children}</CLayoutContext.Provider>
}

export function useLayoutContext(): ILayoutContext {
  const context = useContext(CLayoutContext)
  if (!context) throw "Missing Layout Context"
  return context.state
}

export function useLayoutContextDispatch(): Dispatch<SetStateAction<ILayoutContext>> {
  const context = useContext(CLayoutContext)
  if (!context) throw "Missing Layout Context"
  return context.setState
}

export function useLayoutContextAndDispatch(): [ILayoutContext, Dispatch<SetStateAction<ILayoutContext>>] {
  const context = useContext(CLayoutContext)
  if (!context) throw "Missing Layout Context"
  return [context.state, context.setState]
}

export function useDrawer(): (drawer?: IDrawerSpec) => void {
  const dispatch = useLayoutContextDispatch()
  return useCallback(
    (drawer) => {
      dispatch((ctx) => {
        if (ctx.drawer?.onClose) ctx.drawer.onClose()
        return update(ctx, {
          sideBarOpen: { $set: drawer ? false : ctx.sideBarOpen },
          drawer: {
            $set: drawer,
          },
        })
      })
    },
    [dispatch]
  )
}

export function useMessages(): (messageSpec?: IAlertSpec) => void {
  const dispatch = useLayoutContextDispatch()
  return useCallback(
    (messageSpec) => {
      dispatch((ctx) => {
        return update(ctx, {
          message: { $set: messageSpec },
        })
      })
    },
    [dispatch]
  )
}

interface ILayoutContextInternal {
  readonly state: ILayoutContext
  readonly setState: Dispatch<SetStateAction<ILayoutContext>>
}
