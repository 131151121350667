import { Fragment, ReactElement, useCallback, useEffect, useMemo, useState } from "react"

import Stack from "@mui/material/Stack"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import IconButton from "@mui/material/IconButton"

import SearchIcon from "@mui/icons-material/Search"
import LiveTvIcon from "@mui/icons-material/LiveTv"
import LanguageIcon from "@mui/icons-material/Language"
import CircleIcon from "@mui/icons-material/Circle"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"

import { concatArrays, Store, useStore } from "core"
import { GmtProject, GmtServiceNumber } from "server/model"
import { entitiesEqual, fetchServiceNumbers, IFetchResult, IFetchServiceNumbersFilter, ISortSpec } from "server/logic"
import { StoreDelayedTextField } from "../forms"
import ListTable, { IColumnSpec, useFetchSpec } from "../shared/ListTable"

export interface IServiceNumberDrawerProps {
  readonly project: GmtProject
}

const CDefaultSort: ISortSpec = {
  key: "field_locked",
}

const CColumns: ReadonlyArray<IColumnSpec> = [
  {
    key: "field_locked",
    title: "Status",
    sortKey: "field_locked",
  },
  {
    key: "name",
    title: "Servicenummer",
    sortKey: "name",
  },
  {
    key: "details",
  },
]

export default function ServiceNumberDrawer(props: IServiceNumberDrawerProps): ReactElement {
  const { project } = props
  const { serviceNumbersOnline, serviceNumbersTv } = useStore(project.dataStore)
  const serviceNumbers = useMemo(
    () => concatArrays(serviceNumbersOnline, serviceNumbersTv, entitiesEqual),
    [serviceNumbersOnline, serviceNumbersTv]
  )
  const filterStore = useMemo(
    () =>
      Store.create<IFetchServiceNumbersFilter>({
        exclude: serviceNumbers,
        text: "",
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const filter = useStore(filterStore)
  const [fetchSpec, setFetchSpec] = useFetchSpec(filter, undefined, CDefaultSort)
  const [fetchResult, setFetchResult] = useState<IFetchResult<GmtServiceNumber>>()

  useEffect(() => {
    filterStore.update({
      exclude: { $set: serviceNumbers },
    })
  }, [filterStore, serviceNumbers])

  return (
    <Stack spacing={2} padding={2}>
      <StoreDelayedTextField label="Suche" store={filterStore} property="text" icon={<SearchIcon />} />
      <ListTable
        columns={CColumns}
        fetchSpec={fetchSpec}
        setFetchSpec={setFetchSpec}
        fetchResult={fetchResult}
        setFetchResult={setFetchResult}
        doFetch={fetchServiceNumbers}
        className="ProjectsList"
        data={serviceNumbers}
        renderRow={(entry) => (
          <ServiceNumberRow
            key={entry.getApiId()}
            serviceNumber={entry}
            project={project}
            included={serviceNumbers.findIndex((e) => entitiesEqual(entry, e)) >= 0}
            online={
              !!serviceNumbersOnline?.length && serviceNumbersOnline.findIndex((e) => entitiesEqual(entry, e)) >= 0
            }
          />
        )}
      />
    </Stack>
  )
}

interface IServiceNumberRow {
  readonly serviceNumber: GmtServiceNumber
  readonly included: boolean
  readonly online: boolean
  readonly project: GmtProject
}

function ServiceNumberRow(props: IServiceNumberRow): ReactElement {
  const { serviceNumber, included, online, project } = props
  const { locked, name } = useStore(serviceNumber.dataStore)
  const participationType = useStore(project.dataStore, (data) => data.participationType)
  const canOnline = participationType === "online" || participationType === "hybrid"
  const canTv = participationType === "phone" || participationType === "hybrid"

  const addEntryToOnline = useCallback(
    () => project.addToRelation("serviceNumbersOnline", serviceNumber),
    [project, serviceNumber]
  )
  const addEntryToTv = useCallback(
    () => project.addToRelation("serviceNumbersTv", serviceNumber),
    [project, serviceNumber]
  )

  const removeEntry = useCallback(
    () => project.removeFromRelation(online ? "serviceNumbersOnline" : "serviceNumbersTv", serviceNumber),
    [online, project, serviceNumber]
  )

  return (
    <TableRow selected={included}>
      <TableCell>
        {included ? (
          online ? (
            <LanguageIcon color={locked ? "error" : "success"} />
          ) : (
            <LiveTvIcon color={locked ? "error" : "success"} />
          )
        ) : (
          <CircleIcon color={locked ? "error" : "success"} fontSize="small" />
        )}
      </TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>
        {included ? (
          <IconButton color="error" onClick={removeEntry}>
            <RemoveCircleIcon />
          </IconButton>
        ) : (
          <Fragment>
            {canOnline && (
              <IconButton color="primary" disabled={locked} onClick={addEntryToOnline}>
                <LanguageIcon />
              </IconButton>
            )}
            {canTv && (
              <IconButton color="primary" disabled={locked} onClick={addEntryToTv}>
                <LiveTvIcon />
              </IconButton>
            )}
          </Fragment>
        )}
      </TableCell>
    </TableRow>
  )
}
