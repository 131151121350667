import { ReactElement, useEffect } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"

import { CRouteProjectDetailsID, CRouteProjectsCreate } from "./routes"
import NewProject from "./NewProject"
import ProjectsOverview from "./ProjectsOverview"
import ProjectDetails from "./ProjectDetails"

export default function ProjectsRouter(): ReactElement {
  return (
    <Routes>
      <Route index element={<ProjectsOverview />} />
      <Route path={CRouteProjectsCreate} element={<NewProject />} />
      <Route path={CRouteProjectDetailsID} element={<ProjectDetails />} />
      <Route path="*" element={<RedirectAll />} />
    </Routes>
  )
}

function RedirectAll(): null {
  const navigate = useNavigate()
  useEffect(() => {
    navigate("", { replace: true })
  })
  return null
}
