import { Fragment, ReactElement, SyntheticEvent, useCallback, useEffect, useState } from "react"
import { Spec } from "immutability-helper"

import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"

import { createPromiseTerminator, useStore } from "core"
import { GmtUser } from "server/model"
import { entitiesEqual, fetchUser } from "server/logic"
import { ISimpleInputProps } from "../forms/core"
import { IInlineEditingProps, useInlineEditing } from "../forms"

export interface IUsersAutoCompleteProps<T extends object, K extends keyof T>
  extends ISimpleInputProps<T, K>,
    IInlineEditingProps<T> {
  readonly label?: string
  readonly required?: boolean
}

export default function UsersAutoComplete<T extends Record<string, unknown>, K extends keyof T>(
  props: IUsersAutoCompleteProps<T, K>
): ReactElement {
  const { store, property, label, errors, baseStore, doSave, required } = props
  const { endAdornment, error, isInline } = useInlineEditing("array", store, property, false, baseStore, doSave)
  const finalError = error || (errors && errors[property])

  const value = useStore(store, (state) => (state[property] as ReadonlyArray<GmtUser>) || [], [property])

  const [users, setUsers] = useState<ReadonlyArray<GmtUser>>([])
  const [inputValue, setInputValue] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getCurrentState = (): ReadonlyArray<GmtUser> => (store.state[property] as ReadonlyArray<GmtUser>) || []
    setUsers(getCurrentState())
    if (inputValue) {
      setLoading(true)
      return createPromiseTerminator(
        fetchUser({ filter: { text: inputValue }, pager: { pageSize: 10, page: 0 } }),
        (result) => {
          setUsers([
            ...getCurrentState(),
            ...result.data.filter((entry) => !getCurrentState().find((e) => entry.getApiId() === e.getApiId())),
          ])
          setLoading(false)
        },
        () => {
          setLoading(false)
        }
      )
    } else {
      setLoading(false)
    }
  }, [inputValue, property, store])

  const onInputChange = useCallback((event: SyntheticEvent, value: string) => {
    setInputValue(value)
  }, [])
  const getOptionLabel = useCallback((option: GmtUser) => option.displayName, [])
  const onChange = useCallback(
    (event: SyntheticEvent, value: Array<GmtUser>) => store.update({ [property]: { $set: value } } as Spec<T>),
    [property, store]
  )

  return (
    <Autocomplete
      multiple
      disableClearable
      filterSelectedOptions
      openOnFocus
      options={users}
      loading={loading}
      getOptionLabel={getOptionLabel}
      onInputChange={onInputChange}
      isOptionEqualToValue={entitiesEqual}
      value={value as Array<GmtUser>}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!finalError}
          helperText={finalError}
          variant={isInline ? "filled" : undefined}
          label={label}
          required={required}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {params.InputProps.endAdornment}
                {endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  )
}
