import { Fragment, ReactNode, useCallback, useState } from "react"
import { Spec } from "immutability-helper"

import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"

import DoneIcon from "@mui/icons-material/Done"
import CloseIcon from "@mui/icons-material/Close"

import { IErrors, Store, useStore } from "core"
import { GmtModel } from "server/model"
import { arraysNotEqual } from "./core"
import { isValid } from "date-fns"

export interface IInlineEditingProps<T> {
  readonly baseStore?: Store<T>
  readonly doSave?: () => Promise<unknown>
}

export function useInlineEditing<T extends object, K extends keyof T>(
  type: "string" | "array" | "number" | "date" | "object" | "boolean" | "link",
  store: Store<T>,
  property: K,
  required?: boolean,
  baseStore?: Store<T>,
  onSave?: () => Promise<unknown>,
  errors?: IErrors<T>,
  customError?: string
): {
  readonly endAdornment: ReactNode | undefined
  readonly error: string | undefined
  readonly isInline: boolean
} {
  const [saving, setSaving] = useState(false)
  const value = useStore(store, (state) => state[property], [property])
  const baseValue = useStore(baseStore, (state) => state[property], [property])
  const requiredError = required && (typeof value === "string" ? !value.trim() : !value) ? "missing" : ""
  const linkError =
    type === "link" &&
    typeof value === "object" &&
    (value as { uri?: string })?.uri?.trim() &&
    !/^https?:\/\/.+/.test((value as { uri: string }).uri.trim())
      ? "invalidLink"
      : ""
  const hasChanged =
    !!baseStore &&
    !!onSave &&
    ((type === "string" &&
      ((typeof baseValue === "string" && typeof value === "string" && baseValue.trim() !== value.trim()) ||
        !baseValue !== !value)) ||
      (type === "boolean" && !baseValue !== !value) ||
      (type === "number" && baseValue !== value) ||
      (type === "array" && Array.isArray(baseValue) && Array.isArray(value) && arraysNotEqual(baseValue, value)) ||
      (type === "date" &&
        (!value || isValid(value)) &&
        (baseValue as unknown as Date)?.getTime() !== (value as unknown as Date)?.getTime()) ||
      (type === "object" &&
        ((!baseValue && value) ||
          (baseValue && !value) ||
          (baseValue as unknown as GmtModel)?.getApiId() !== (value as unknown as GmtModel)?.getApiId())) ||
      (type === "link" && (baseValue as { uri?: string })?.uri?.trim() !== (value as { uri?: string })?.uri?.trim()))

  const saveChanges = useCallback(() => {
    if (!onSave) return
    setSaving(true)
    onSave().finally(() => {
      setSaving(false)
    })
  }, [onSave])

  const stopEditing = useCallback(() => {
    if (!baseStore) return
    // reset editor value
    store.update({ [property]: { $set: baseStore.state[property] } } as Spec<T>)
  }, [baseStore, property, store])

  const preError = (hasChanged && (customError || requiredError || linkError)) || errors?.[property]
  const error = preError === "missing" && !requiredError ? undefined : preError

  return {
    error,
    isInline: !!baseStore && !!onSave,
    endAdornment: hasChanged ? (
      <Fragment>
        <InputAdornment position="end">
          <IconButton edge="end" onClick={saveChanges} color="success" disabled={saving || !!requiredError}>
            <DoneIcon />
          </IconButton>
        </InputAdornment>
        <InputAdornment position="end">
          <IconButton edge="end" onClick={stopEditing} color="error">
            <CloseIcon />
          </IconButton>
        </InputAdornment>
      </Fragment>
    ) : undefined,
  }
}
