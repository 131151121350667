import { GmtProduct } from "../../model"

export function toggleFavorite(product: GmtProduct): Promise<void> {
  product.dataStore.update(({ favorite }) => ({ favorite: { $set: !favorite } }))
  return product.save().then(
    () => undefined,
    (error) => {
      console.error(error)
    }
  )
}
