import { GmtModel } from "./core"
import { GmtUser } from "./GmtUser"
import { ToOneRelation } from "../../coloquent"

export interface IBankData {
  readonly title: string
  readonly iban: string
  readonly bankName: string
  readonly validated: boolean
  readonly modifiedBy: GmtUser | null
  readonly validatedBy: GmtUser | null
}

const CPrefetchedRelations = ["field_modified_by", "field_checked_by"]

export function getPrefetchRelationsForBankData(prefix: string): ReadonlyArray<string> {
  return CPrefetchedRelations.map((entry) => prefix + entry)
}

export class GmtBankData extends GmtModel<IBankData> {
  static jsonApiType = "node--gmt_bank_data"
  static endpoint = "node/gmt_bank_data"

  static attMapping: Readonly<Record<string, keyof IBankData>> = {
    title: "title",
    field_iban: "iban",
    field_name_of_bank: "bankName",
    field_validated: "validated",
  }

  static relMapping: Readonly<Record<string, keyof IBankData>> = {
    field_modified_by: "modifiedBy",
    field_checked_by: "validatedBy",
  }

  nid(): number {
    return this.getAttribute("drupal_internal__nid")
  }

  field_modified_by(): ToOneRelation<GmtUser> {
    return this.hasOne(GmtUser)
  }

  field_checked_by(): ToOneRelation<GmtUser> {
    return this.hasOne(GmtUser)
  }
}
