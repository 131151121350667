import { Fragment, ReactElement, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"

import { createPromiseTerminator, IErrors, useStore } from "core"
import { GmtCashPrice, GmtMaterialPrice, GmtWinner, IGmtWinnerData } from "server/model"
import { fetchWinner } from "server/logic/winners"
import { useMessages } from "../../../components/LayoutContext"
import LoadingIndicator from "../../../components/shared/LoadingIndicator"
import CompetitionPanel from "../../../components/competitions/CompetitionPanel"
import ProjectsListPaper from "../../../components/competitions/ProjectsListPaper"
import WinnerPanel from "../../../components/winners/WinnerPanel"
import WinnerStats from "./WinnerStats"
import WinnerEvents from "./WinnerEvents"
import WinnerBankData from "./WinnerBankData"
import WinnerCommunications from "./WinnerCommunications"
import WinnerHistory from "./WinnerHistory"
import CompetitionWins from "../../../components/competitions/CompetitionWins"
import WinnerButtons from "./WinnerButtons"
import LoadingButton from "@mui/lab/LoadingButton"
import WinnerDraw from "./WinnerDraw"

export default function WinnerDetails(): ReactElement {
  const { winnerId } = useParams()
  const setMessage = useMessages()
  const [loading, setLoading] = useState(false)

  const [winner, setWinner] = useState<GmtWinner | null>()
  const [errors, setErrors] = useState<IErrors<IGmtWinnerData>>()

  const { title, win } = useStore(winner?.dataStore) || {}
  const { competition } = useStore(win?.dataStore) || {}
  const winnerStatus = useStore(winner?.dataStore, (data) => data.winnerStatus)

  const isCashPrice = useStore(win?.dataStore, (data) => !!data.prices?.find((price) => price instanceof GmtCashPrice))
  const isManualPrice = useStore(
    win?.dataStore,
    (data) => !!data.prices?.find((price) => price instanceof GmtMaterialPrice && !price.dataStore.state.product)
  )
  const shippingBtnDisabled = useStore(
    win?.dataStore,
    (data) => !!data.prices?.find((price) => price instanceof GmtCashPrice && !price.dataStore.state.sepaDate)
  )

  const doSave = useCallback((): Promise<unknown> => {
    setErrors(undefined)
    if (!winner) return Promise.reject("Internal state error: Missing winner!")
    // TODO replace with saveWinner()
    return winner.save().catch((errors) => {
      setErrors(errors)
      if (errors._) {
        setMessage({
          message: errors._,
          severity: "error",
        })
      }
    })
  }, [setMessage, winner])

  const doShippingDone = useCallback(() => {
    setErrors(undefined)
    setLoading(true)
    if (!winner) return Promise.reject("Internal state error: Missing winner!")
    winner.dataStore.update({ winnerStatus: { $set: "Versand" } })
    winner.save().then(() => {
      setLoading(false)
      setMessage({
        message: "Status erfolgreich auf 'Versand' gesetzt",
        severity: "success",
      })
    })
  }, [setMessage, winner])

  useEffect(() => {
    if (!winnerId) return
    return createPromiseTerminator(fetchWinner(winnerId), setWinner)
  }, [winnerId])

  return (
    <Container maxWidth={false} className="marginBottom">
      {winner ? (
        <Stack spacing={3}>
          <WinnerStats winner={winner} />
          <Stack direction="row" my={3} spacing={0}>
            <Typography variant="h5" component="h1">
              {title}
            </Typography>
            {winnerStatus === "Geprüft" && isManualPrice && (
              <LoadingButton
                sx={{ marginLeft: "auto" }}
                loading={loading}
                variant="contained"
                onClick={doShippingDone}
                disabled={shippingBtnDisabled}
              >
                Versand erfolgt
              </LoadingButton>
            )}
          </Stack>
          <WinnerEvents winner={winner} />
          {competition && (
            <Fragment>
              <ProjectsListPaper competition={competition} readOnly />
              <CompetitionPanel competition={competition} />
              <CompetitionWins competition={competition} showWinner={false} />
            </Fragment>
          )}
          <WinnerPanel winner={winner} doSave={doSave} errors={errors} />
          <WinnerBankData winner={winner} required={isCashPrice} />
          <WinnerCommunications winner={winner} />
          <WinnerDraw winner={winner} />
          <WinnerButtons winner={winner} />
          <WinnerHistory winner={winner} />
        </Stack>
      ) : (
        <LoadingIndicator />
      )}
    </Container>
  )
}
