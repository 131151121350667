import { GmtModel } from "./core"
import { GmtFile } from "./GmtFile"
import { ToManyRelation } from "../../coloquent"

export interface IDrawData {
  readonly title: string
  readonly audio: ReadonlyArray<GmtFile> | null
  readonly answer: string | null
  readonly type: string
}

export class GmtWinnigDraw extends GmtModel<IDrawData> {
  static jsonApiType = "node--gmt_winning_draw"
  static endpoint = "node/gmt_winning_draw"

  static attMapping: Readonly<Record<string, keyof IDrawData>> = {
    title: "title",
    field_drawing_recordtext: "answer",
    field_drawing_recordtype: "type",
  }

  static relMapping: Readonly<Record<string, keyof IDrawData>> = {
    field_drawing_recordwav: "audio",
  }
  nid(): number {
    return this.getAttribute("drupal_internal__nid")
  }

  field_drawing_recordwav(): ToManyRelation<GmtFile, this> {
    return this.hasMany(GmtFile)
  }
}
