import { GmtModel } from "./core"
import { ToOneRelation, SaveResponse } from "../../coloquent"
import { GmtWin } from "./GmtWin"
import { GmtBankData } from "./GmtBankData"
import { IAddress } from "./shared"
import { getObjectProps, Store } from "core"
import { GmtUser } from "./GmtUser"
import { GmtWinnigDraw } from "./GmtWinningDraw"

export const CWinnerStatus = ["Gezogen", "Benachrichtigt", "Vollständig", "Geprüft", "Versand", "Ausgebucht"] as const
export type TWinnerStatus = (typeof CWinnerStatus)[number]
export const CWinnerStatusNames: Readonly<Record<TWinnerStatus, string>> = {
  Ausgebucht: "Ausgebucht",
  Geprüft: "Geprüft",
  Versand: "Versand",
  Gezogen: "Gezogen",
  Benachrichtigt: "Benachrichtigt",
  Vollständig: "Vollständig",
}

export const CSalutations = ["1", "2", "3"]
export type TSalutation = (typeof CSalutations)[number]
export const CSalutationNames: Readonly<Record<TSalutation, string>> = {
  "1": "Herr",
  "2": "Frau",
  "3": "Divers",
}

export interface IGmtWinnerData {
  readonly title: string
  readonly salutation: TSalutation
  readonly mail: string
  readonly birthday: Date | null
  readonly notified: Date | null
  readonly published: Date | null
  readonly mobileNumber: string
  readonly notes: string
  readonly phoneNumber: string
  readonly address: IAddress | null
  readonly effortRecorded: Date | null
  readonly accountClosureRecorded: Date | null
  readonly winnerStatus: TWinnerStatus
  readonly win: GmtWin | null
  readonly bankData: GmtBankData | null
  readonly completedBy: GmtUser | null
  readonly materialPriceConversion: boolean
  readonly drawingNode: GmtWinnigDraw | null
}

export class GmtWinner extends GmtModel<IGmtWinnerData> {
  static jsonApiType = "node--gmt_winner"
  static endpoint = "node/gmt_winner"

  static dates = [
    "field_birthday",
    "field_winner_notified",
    "field_winner_published",
    "field_effort_recorded",
    "field_account_closure_recorded",
  ]

  static attMapping: Readonly<Record<string, keyof IGmtWinnerData>> = {
    title: "title",
    field_salutation: "salutation",
    field_winner_mail: "mail",
    field_birthday: "birthday",
    field_winner_notified: "notified",
    field_winner_published: "published",
    field_mobil: "mobileNumber",
    field_note: "notes",
    field_effort_recorded: "effortRecorded",
    field_account_closure_recorded: "accountClosureRecorded",
    field_winner_status: "winnerStatus",
    field_phone: "phoneNumber",
    field_winner_address: "address",
    field_material_price_conversion: "materialPriceConversion",
  }
  static relMapping: Readonly<Record<string, keyof IGmtWinnerData>> = {
    field_bank_data: "bankData",
    field_ref_win: "win",
    field_completed_by: "completedBy",
    field_gmt_drawing_ref: "drawingNode",
  }

  readonly addressDataStore = Store.create<Partial<IAddress>>({})
  readonly addressBaseStore = Store.create<Partial<IAddress>>({})

  constructor() {
    super()
    this.dataStore.listen(
      (state) => state.address,
      (address) => this.addressDataStore.set(address || {})
    )
    this.baseStore.listen(
      (state) => state.address,
      (address) => this.addressBaseStore.set(address || {})
    )
  }

  save(): Promise<SaveResponse<this>> {
    const address = this.addressDataStore.state
    const isEmpty = getObjectProps(address).filter((key) => !!address[key]).length === 0
    this.dataStore.update({ address: { $set: isEmpty ? null : address } })
    return super.save()
  }

  nid(): number {
    return this.getAttribute("drupal_internal__nid")
  }

  field_ref_win(): ToOneRelation<GmtWin, this> {
    return this.hasOne(GmtWin)
  }

  field_bank_data(): ToOneRelation<GmtBankData, this> {
    return this.hasOne(GmtBankData)
  }

  field_completed_by(): ToOneRelation<GmtUser, this> {
    return this.hasOne(GmtUser)
  }

  field_gmt_drawing_ref(): ToOneRelation<GmtWinnigDraw, this> {
    return this.hasOne(GmtWinnigDraw)
  }
}
