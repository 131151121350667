import { ReactElement, useState } from "react"
import { useNavigate } from "react-router-dom"

import IconButton from "@mui/material/IconButton"

import NotesIcon from "@mui/icons-material/Notes"

import { GmtCompetition } from "server/model"
import { IFetchResult, IPagerInfo, ISortSpec } from "server/logic"
import { fetchCompetitions, IFetchCompetitionsFilter } from "server/logic/competitions"
import { CRouteCompetitions } from "../../routes"
import { CRouteCompetitionDetails } from "../../pages/competitions/routes"
import ListTable, { IColumnSpec, useFetchSpec } from "../shared/ListTable"
import { CSenderCellWidth } from "../sender/SenderImage"
import CompetitionListRow from "./CompetitionListRow"

export interface ICompetitionsListProps {
  readonly pager?: IPagerInfo
  readonly filter: IFetchCompetitionsFilter
  readonly sort?: ISortSpec
}

const CDefaultSort: ISortSpec = {
  key: "field_participation_start_date",
}

const CColumns: ReadonlyArray<IColumnSpec> = [
  {
    key: "details",
  },
  {
    sortKey: "field_project_data.field_sender.name",
    title: "Brand",
    key: "field_sender",
    width: CSenderCellWidth,
  },
  {
    key: "field_game_status",
    title: "Status",
    sortKey: "field_game_status",
  },
  {
    key: "title",
    title: "Titel",
    sortKey: "title",
  },
  {
    sortKey: "field_participation_start_date",
    title: "Start",
    key: "field_participation_start_date",
  },
  {
    key: "field_participation_end_date",
    title: "Schluss",
    sortKey: "field_participation_end_date",
  },
  {
    key: "field_project_manager",
    title: "Projektverantwortliche(r)",
  },
  {
    key: "wins",
    title: "Gewinn(e)",
  },
]

export default function CompetitionsList(props: ICompetitionsListProps): ReactElement {
  const { pager, filter, sort = CDefaultSort } = props

  const navigate = useNavigate()

  const [fetchSpec, setFetchSpec] = useFetchSpec(filter, pager, sort)
  const [fetchResult, setFetchResult] = useState<IFetchResult<GmtCompetition>>()

  const CExcluded = ["field_sms_keyword"]

  return (
    <ListTable
      columns={CColumns}
      exclude={CExcluded}
      fetchSpec={fetchSpec}
      setFetchSpec={setFetchSpec}
      fetchResult={fetchResult}
      setFetchResult={setFetchResult}
      doFetch={fetchCompetitions}
      className="CompetitionsList"
      renderRow={(comp) => (
        <CompetitionListRow
          key={comp.getApiId()}
          comp={comp}
          exclude={CExcluded}
          details={
            <IconButton
              onClick={() => {
                navigate("/" + CRouteCompetitions + "/" + CRouteCompetitionDetails + "/" + comp.getApiId())
              }}
            >
              <NotesIcon />
            </IconButton>
          }
        />
      )}
    />
  )
}
