import { Fragment, ReactElement, useCallback, useState } from "react"

import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"

import { IErrors, useStore } from "core"
import { CGameMechanicsConfig, GmtCompetition, ICompetitionData } from "server/model"
import { validateCompetitionData } from "server/logic/competitions"
import CompetitionDetailsPaper from "../../../components/competitions/CompetitionDetailsPaper"
import ProjectsListPaper from "../../../components/competitions/ProjectsListPaper"

export interface IDetailsStepProps {
  readonly competition: GmtCompetition
  readonly goBack: () => void
  readonly goNext: () => void
}

export default function StepTwo(props: IDetailsStepProps): ReactElement {
  const { competition, goBack, goNext } = props
  const [errors, setErrors] = useState<IErrors<ICompetitionData>>()
  const isTitle = useStore(competition.dataStore, (data) => !!data.title)
  const isKeyword = useStore(competition.dataStore, (data) => !!data.smsKeywords?.length)
  const project = useStore(competition.dataStore, (data) => data.project)
  const tid = project?.dataStore.state.gameMechanics?.tid
  const mechanicsConfig = tid ? CGameMechanicsConfig[tid] : undefined

  // callbacks
  const validateAndGoNext = useCallback(() => {
    const errors = validateCompetitionData(competition.dataStore.state)
    setErrors(errors)
    if (!errors) goNext()
  }, [competition, goNext])

  const isNotValid = !isTitle || (mechanicsConfig?.smsKeyword && !isKeyword)

  return (
    <Fragment>
      <CompetitionDetailsPaper competition={competition} errors={errors} />
      <ProjectsListPaper competition={competition} />
      <Stack direction="row">
        <Button variant="outlined" onClick={goBack}>
          Zurück
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="contained" onClick={validateAndGoNext} disabled={isNotValid}>
          Weiter
        </Button>
      </Stack>
    </Fragment>
  )
}
