import { GmtModel } from "./core"

export class GmtBrand extends GmtModel {
  static jsonApiType = "taxonomy_term--1conceptsbrands"
  static endpoint = "taxonomy_term/1conceptsbrands"

  get name(): string {
    return this.getAttribute("name")
  }
}
