import { ToOneRelation } from "../../coloquent"
import { GmtPrice, IPriceData } from "./GmtPrice"
import { GmtSponsor } from "./GmtSponsor"

export interface ICashPriceData extends IPriceData {
  readonly winningAmount: string // number
  readonly lightningTransfer: boolean
  readonly payoutPsd: boolean
  readonly sepaDate: Date | null
}

export class GmtCashPrice extends GmtPrice<ICashPriceData> {
  static jsonApiType = "node--cash_price"
  static endpoint = "node/cash_price"

  static dates = ["field_sepa_date"]

  static attMapping: Readonly<Record<string, keyof ICashPriceData>> = {
    title: "title",
    field_winning_amount: "winningAmount",
    field_lightning_transfer: "lightningTransfer",
    field_payout_psd: "payoutPsd",
    field_sepa_date: "sepaDate",
  }
  static relMapping: Readonly<Record<string, keyof ICashPriceData>> = {
    field_sponsor: "sponsor",
  }

  field_sponsor(): ToOneRelation<GmtSponsor, this> {
    return this.hasOne(GmtSponsor)
  }
}
