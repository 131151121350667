import { ToOneRelation } from "../../coloquent"
import { IAddress } from "./shared"
import { GmtModel } from "./core"
import { GmtPartner } from "./GmtPartner"

export interface ISponsorData {
  readonly title: string
  readonly address: IAddress | null
  readonly mail: string
  readonly phone: string
  readonly name: string
  readonly tax: string
  readonly ustId: string
  readonly active: boolean
  readonly partner: GmtPartner | null
}

export class GmtSponsor extends GmtModel<ISponsorData> {
  static jsonApiType = "node--gmt_sponsor"
  static endpoint = "node/gmt_sponsor"

  static attMapping: Readonly<Record<string, keyof ISponsorData>> = {
    title: "title",
    field_address: "address",
    field_contact_mail: "mail",
    field_contact_name: "name",
    field_contact_phone: "phone",
    field_tax: "tax",
    field_ust_id: "ustId",
    status: "active",
  }
  static relMapping: Readonly<Record<string, keyof ISponsorData>> = {
    field_handover: "partner",
  }

  field_handover(): ToOneRelation<GmtPartner, this> {
    return this.hasOne(GmtPartner)
  }
}
