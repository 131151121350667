import { Store } from "core"
import { GmtSender } from "../model"

const CSenderStore = Store.create<ReadonlyArray<GmtSender>>([])

let promise: Promise<ReadonlyArray<GmtSender>> | undefined

export function getAllSenders(): Promise<ReadonlyArray<GmtSender>> {
  return (
    promise ||
    (promise = GmtSender.with(["field_station_logo", "field_ucp_number"])
      .get()
      .then(
        (response) => {
          const list = response.getData()
          CSenderStore.set(list)
          return list
        },
        (error) => {
          console.error(error)
          promise = undefined
          return []
        }
      ))
  )
}

export function getSenderStore(): Store<ReadonlyArray<GmtSender>> {
  void getAllSenders()
  return CSenderStore
}
