import { ReactElement } from "react"

import SearchIcon from "@mui/icons-material/Search"

import { Store, useStore } from "core"
import { CGameStatus, CGameStatusNames } from "server/model"
import { IFetchCompetitionsFilter } from "server/logic/competitions"
import SelectSender from "../../../components/sender/SelectSender"

import "./CompetitionsFilter.scss"
import { StoreDatePicker, StoreDelayedTextField, StoreSimpleSelect } from "../../../components/forms"
import Box from "@mui/material/Box"

export interface ICompetitionsFilterProps {
  readonly filterStore: Store<IFetchCompetitionsFilter>
}

export default function CompetitionsFilter(props: ICompetitionsFilterProps): ReactElement {
  const { filterStore } = props
  const filter = useStore(filterStore)

  return (
    <Box id="CompetitionsFilter" paddingLeft={2}>
      <Box marginRight={2} marginBottom={2} className="searchBox">
        <StoreDelayedTextField label="Filter" store={filterStore} property="text" icon={<SearchIcon />} size="small" />
      </Box>
      <Box className="searchBox" marginRight={2} marginBottom={2}>
        <div>
          <StoreDatePicker
            label="Teilnahmestart"
            maxDate={filter.maxStart || filter.maxEnd || undefined}
            store={filterStore}
            property="minStart"
            size="small"
          />
          <span className="separator">-</span>
          <StoreDatePicker
            minDate={filter.minStart || undefined}
            maxDate={filter.maxEnd || undefined}
            store={filterStore}
            property="maxStart"
            size="small"
          />
        </div>
      </Box>
      <Box className="searchBox" marginRight={2} marginBottom={2}>
        <div>
          <StoreDatePicker
            label="Teilnahmeschluss"
            maxDate={filter.maxEnd || undefined}
            minDate={filter.minStart || undefined}
            store={filterStore}
            property="minEnd"
            size="small"
          />
          <span className="separator">-</span>
          <StoreDatePicker
            minDate={filter.minEnd || filter.minStart || undefined}
            store={filterStore}
            property="maxEnd"
            size="small"
          />
        </div>
      </Box>
      <Box marginRight={2} marginBottom={2} className="searchBox">
        <StoreDelayedTextField
          label="Projektverantwortliche(r)"
          store={filterStore}
          property="projectManager"
          icon={<SearchIcon />}
          size="small"
        />
      </Box>
      <Box marginRight={2} marginBottom={2} className="searchBox">
        <StoreSimpleSelect
          label="Status"
          store={filterStore}
          property="gameStatus"
          list={CGameStatus}
          labels={CGameStatusNames}
          emptyLabel="alle"
          small
        />
      </Box>
      <Box marginRight={2} marginBottom={2} className="searchBox">
        <SelectSender store={filterStore} property="sender" small />
      </Box>
    </Box>
  )
}
