import { ReactElement, useCallback, useMemo } from "react"

import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"

import { useStore } from "core"
import { GmtMaterialPrice, GmtWin } from "server/model"
import { createPrice } from "server/logic/competitions"
import { useDrawer, useMessages } from "../../LayoutContext"
import CustomPricePanel from "../CustomPricePanel"

export default function CustomPriceTab({ win }: { readonly win: GmtWin }): ReactElement {
  const drawer = useDrawer()
  const setMessage = useMessages()
  const price = useMemo(() => new GmtMaterialPrice().initialize({ qty: 1 }), [])
  const isNotValid = useStore(price.dataStore, (data) => !data.title?.trim())

  const onClick = useCallback(() => {
    createPrice(win, price).then((success) => {
      if (!success) {
        setMessage({ message: "Unerwarteter Fehler beim Anlegen des Preises", severity: "error" })
      }
    })
    drawer()
  }, [drawer, price, setMessage, win])

  return (
    <CustomPricePanel price={price}>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <Button variant="contained" onClick={onClick} disabled={isNotValid} fullWidth>
          Manuellen Preis hinzufügen
        </Button>
      </Grid>
    </CustomPricePanel>
  )
}
