import { ReactElement, useCallback, useMemo, useState } from "react"

import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import LoadingButton from "@mui/lab/LoadingButton"
import TextField from "@mui/material/TextField"

import CreditScoreIcon from "@mui/icons-material/CreditScore"

import { IErrors, useStore } from "core"
import { GmtBankData, GmtWinner, IBankData, IGmtWinnerData } from "server/model"
import { formatIban, getFullNameFromAddress, setBankDataValidated, validateAndSaveBankData } from "server/logic/winners"
import { IInlineEditingProps, StoreTextField } from "../../../components/forms"
import { useMessages } from "../../../components/LayoutContext"
import { CSessionState } from "../../../state"

export default function WinnerBankData({
  winner,
  required,
}: {
  readonly winner: GmtWinner
  readonly required?: boolean
}): ReactElement {
  const setMessage = useMessages()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<IErrors<IBankData>>()
  const bankData = useStore(winner.dataStore, (state) => state.bankData)
  const finalBankData = useMemo(() => {
    if (bankData) return bankData
    const { given_name, family_name } = winner.addressDataStore.state
    const name = ((given_name || "") + " " + (family_name || "")).trim()
    return new GmtBankData().initialize({ bankName: "", iban: "", title: name })
  }, [bankData, winner])
  const { dataStore, baseStore } = finalBankData
  const fullName = useStore(winner.addressDataStore, getFullNameFromAddress)
  const user = useStore(CSessionState, (state) => state.user)
  const editedBy = useStore(baseStore, (data) => data.modifiedBy)
  const validatedBy = useStore(baseStore, (data) => (data.validated ? data.validatedBy : undefined))

  const doSave = useCallback(async () => {
    if (!user) return
    setLoading(true)
    setErrors(undefined)
    return validateAndSaveBankData(user, winner, finalBankData).then(
      (errors) => {
        setErrors(errors)
        setLoading(false)
      },
      (error) => {
        setMessage({ message: "Es ist ein unerwarteter Fehler aufgetreten", severity: "error" })
        setLoading(false)
        console.error(error)
      }
    )
  }, [finalBankData, setMessage, user, winner])

  const doValidation = useCallback(async () => {
    if (!user) return
    setLoading(true)
    return setBankDataValidated(user, finalBankData).then(
      () => {
        setLoading(false)
      },
      (error) => {
        setMessage({
          message: typeof error === "string" ? error : "Es ist ein unerwarteter Fehler aufgetreten",
          severity: "error",
        })
        setLoading(false)
        console.error(error)
      }
    )
  }, [finalBankData, setMessage, user])

  const inlineProps = useMemo<IInlineEditingProps<Partial<IGmtWinnerData>>>(
    () => ({ baseStore, doSave }),
    [doSave, baseStore]
  )

  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Bankdaten
        </Typography>
        {finalBankData.getApiId() && !validatedBy && editedBy?.getApiId() !== user?.getApiId() && (
          <LoadingButton
            variant="outlined"
            loading={loading}
            onClick={doValidation}
            loadingPosition="start"
            startIcon={<CreditScoreIcon />}
          >
            Bankdaten validieren
          </LoadingButton>
        )}
      </Toolbar>
      <Grid container spacing={2} paddingBottom={2}>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            errors={errors}
            label="Kontoinhaber"
            store={dataStore}
            property="title"
            placeholder={fullName}
            required={required}
            disabled={!!validatedBy}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            errors={errors}
            label="Name der Bank"
            store={dataStore}
            property="bankName"
            placeholder="automatisch ermittelt"
            required={required}
            disabled={!!validatedBy}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            errors={errors}
            label="IBAN"
            store={dataStore}
            property="iban"
            inputTransformer={formatIban}
            placeholder="XX00 0000 0000 0000 0000 00"
            required={required}
            disabled={!!validatedBy}
            fullWidth
          />
        </Grid>
        {finalBankData?.getApiId() && (
          <Grid item xs={12} md={6}>
            <TextField
              label={"Dateneingabe durch" + (validatedBy ? ", geprüft durch" : "")}
              variant="filled"
              value={(editedBy?.displayName || "k.A.") + (validatedBy ? ", " + validatedBy.displayName : "")}
              disabled
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}
