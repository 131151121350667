import { ReactElement, useState } from "react"

import { GmtHistory } from "server/model"
import { fetchHistory, IFetchHistoryFilter, IFetchResult, IPagerInfo, ISortSpec } from "server/logic"
import ListTable, { IColumnSpec, useFetchSpec } from "../shared/ListTable"
import HistoryListRow from "./HistoryListRow"

export interface IHistoriesListProps {
  readonly pager?: IPagerInfo
  readonly filter: IFetchHistoryFilter
  readonly sort?: ISortSpec
}

const CDefaultSort: ISortSpec = {
  key: "created",
}

const CColumns: ReadonlyArray<IColumnSpec> = [
  {
    key: "label",
    title: "Geändertes Feld",
  },
  {
    key: "newValue",
    title: "Neuer Wert",
  },
  {
    key: "oldValue",
    title: "Alter Wert",
  },
  {
    key: "created",
    title: "Änderungszeit",
  },
  {
    key: "user",
    title: "User",
  },
]

export default function HistoriesList(props: IHistoriesListProps): ReactElement {
  const { pager, filter, sort = CDefaultSort } = props

  const [fetchSpec, setFetchSpec] = useFetchSpec(filter, pager, sort)
  const [fetchResult, setFetchResult] = useState<IFetchResult<GmtHistory>>()

  return (
    <ListTable
      columns={CColumns}
      fetchSpec={fetchSpec}
      setFetchSpec={setFetchSpec}
      fetchResult={fetchResult}
      setFetchResult={setFetchResult}
      doFetch={fetchHistory}
      className="HistoriesList"
      renderRow={(history) => <HistoryListRow key={history.getApiId()} history={history} />}
    />
  )
}
