import { ReactElement, useCallback, useMemo, useState } from "react"

import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"

import AddBoxIcon from "@mui/icons-material/AddBox"
import SearchIcon from "@mui/icons-material/Search"
import FavoriteIcon from "@mui/icons-material/Favorite"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"

import { Store, useStore } from "core"
import { GmtMaterialPrice, GmtProduct, GmtWin } from "server/model"
import { IFetchResult } from "server/logic"
import { fetchProducts, IFetchProductFilter, toggleFavorite } from "server/logic/products"
import ListTable, { IColumnSpec, useFetchSpec } from "../../shared/ListTable"
import { StoreCheckbox, StoreDelayedTextField } from "../../forms"
import { useDrawer, useMessages } from "../../LayoutContext"
import { formatCurrency } from "../../shared/formatCurrency"
import { createPrice } from "../../../../server/logic/competitions"
import Tooltip from "@mui/material/Tooltip"
import ProductPricePanel from "../ProductPricePanel"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"

const CColumns: ReadonlyArray<IColumnSpec> = [
  { key: "actions", title: "Favorit", sortKey: "field_1concepts_product_favorite" },
  { key: "title", title: "Bezeichnung", sortKey: "title" },
  { key: "image", title: "Bild" },
  { key: "brand", title: "Marke", sortKey: "field_1concepts_product_brand.name" },
  { key: "price", title: "Nettopreis", sortKey: "field_1concepts_product_netcost" },
]

export interface IMaterialPricesTabProps {
  readonly win: GmtWin
}

export default function MaterialPricesTab(props: IMaterialPricesTabProps): ReactElement {
  const { win } = props
  const prices = useStore(win.dataStore, (data) => data.prices)
  const filterStore = useMemo(() => Store.create<IFetchProductFilter>({ text: "", favorite: true }), [])
  const filter = useStore(filterStore)
  const [fetchSpec, setFetchSpec] = useFetchSpec(filter)
  const [fetchResult, setFetchResult] = useState<IFetchResult<GmtProduct>>()
  const drawer = useDrawer()
  const setMessage = useMessages()

  const [newPrice, setNewPrice] = useState<GmtMaterialPrice>()

  const onClickPlus = useCallback((product: GmtProduct) => {
    setNewPrice(
      new GmtMaterialPrice().initialize({
        product,
        title: product.dataStore.state.title,
        qty: 1,
      })
    )
  }, [])
  const onClickSave = useCallback(() => {
    if (!newPrice) return
    createPrice(win, newPrice).then((success) => {
      if (!success) {
        setMessage({ message: "Unerwarteter Fehler beim Anlegen des manuellen Preises", severity: "error" })
      }
    })
    drawer()
  }, [drawer, newPrice, setMessage, win])

  return newPrice ? (
    <ProductPricePanel price={newPrice}>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <Button variant="contained" onClick={onClickSave} fullWidth>
          Shop Preis hinzufügen
        </Button>
      </Grid>
    </ProductPricePanel>
  ) : (
    <Stack marginTop={2} spacing={2}>
      <Stack direction="row" spacing={2}>
        <StoreDelayedTextField
          store={filterStore}
          property="text"
          label="Suche"
          icon={<SearchIcon />}
          size="small"
          fullWidth
        />
        <StoreCheckbox label="Favoriten" store={filterStore} property="favorite" small />
      </Stack>
      <ListTable
        columns={CColumns}
        fetchSpec={fetchSpec}
        setFetchSpec={setFetchSpec}
        fetchResult={fetchResult}
        setFetchResult={setFetchResult}
        doFetch={fetchProducts}
        renderRow={(price) => (
          <ProductTableRow
            key={price.localId}
            product={price}
            onClick={onClickPlus}
            disabled={
              !!prices?.find((p) => {
                return p instanceof GmtMaterialPrice && p.dataStore.state.product?.getApiId() === price.getApiId()
              })
            }
          />
        )}
      />
    </Stack>
  )
}

function ProductTableRow({
  product,
  onClick,
  disabled,
}: {
  readonly product: GmtProduct
  readonly onClick: (price: GmtProduct) => void
  readonly disabled: boolean
}): ReactElement {
  const { title, netcost, images, brand, favorite, desc } = useStore(product.dataStore)
  const image = images?.length ? images[0].uri : undefined

  const onFavoriteClick = useCallback(() => {
    void toggleFavorite(product)
  }, [product])
  const onAddClick = useCallback(() => {
    onClick(product)
  }, [onClick, product])

  return (
    <TableRow>
      <TableCell>
        <Stack direction="row">
          <IconButton onClick={onAddClick} disabled={disabled} color="primary">
            <AddBoxIcon />
          </IconButton>
          <IconButton onClick={onFavoriteClick} color={favorite ? "primary" : undefined}>
            {favorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </IconButton>
        </Stack>
      </TableCell>
      <TableCell>
        <Tooltip title={desc}>
          <span>{title}</span>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        {image && <img alt="" src={image} style={{ maxWidth: 50, maxHeight: 50 }} />}
      </TableCell>
      <TableCell>{brand?.name}</TableCell>
      <TableCell align="right">{formatCurrency(netcost)}</TableCell>
    </TableRow>
  )
}
