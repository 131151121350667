import { GmtUser } from "../model"
import { fetchModel, IFetchResult, IFetchSpec } from "./core"
import { ConditionFilterSpec } from "../../coloquent/filter/ConditionFilterSpec"
import { GroupFilterSpec } from "../../coloquent/filter/GroupFilterSpec"

export async function fetchUser(spec: IFetchSpec<IFetchUserFilter>): Promise<IFetchResult<GmtUser>> {
  const { pager, filter, sort } = spec
  GmtUser.setPageSize(pager.pageSize)
  let query = GmtUser.where("status", "1")
  if (filter.userId) {
    query = query.where("id", filter.userId)
  }
  if (filter.text) {
    query = query.filter(
      new GroupFilterSpec("OR", [
        new ConditionFilterSpec("name", "CONTAINS", filter.text),
        new ConditionFilterSpec("mail", "CONTAINS", filter.text),
      ])
    )
  }
  return await fetchModel(query, pager, sort)
}

export interface IFetchUserFilter {
  readonly text?: string
  readonly userId?: string | undefined
}
