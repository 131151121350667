import { ReactElement } from "react"

import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"

import PartnersSearch from "./PartnersSearch"

export default function PartnersOverview(): ReactElement {
  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PartnersSearch />
        </Grid>
      </Grid>
    </Container>
  )
}
