import { GmtModel } from "./core"
import { CBaseUrl } from "../constants"

export class GmtFile extends GmtModel {
  static jsonApiType = "file--file"
  static endpoint = "file/file"

  get uri(): IUri {
    return this.getAttribute("uri")
  }

  get filename(): string {
    return this.getAttribute("filename")
  }

  get url(): string {
    return CBaseUrl + this.uri.url
  }
}

interface IUri {
  readonly url: string
}
