import { ReactElement, useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import format from "date-fns/format"
import de from "date-fns/locale/de"
import update from "immutability-helper"

import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"

import NotesIcon from "@mui/icons-material/Notes"

import { useStore } from "core"
import { GmtWinner } from "server/model"
import { CRouteWinners } from "../../routes"
import { CRouteWinnerDetails } from "../../pages/winners/routes"
import { useDrawer, useLayoutContextDispatch } from "../LayoutContext"
import WinChip from "../shared/WinChip"
import ProjectLabel from "../projects/ProjectLabel"
import { CSenderCellProps } from "../sender/SenderImage"
import WinDetailsBar from "../wins/WinDetailsBar"
import WinnerNoticeBar from "../details/WinnerNoticeBar"
import UserChip from "../shared/UserChip"

export interface IWinnerListRowProps {
  readonly winner: GmtWinner
}

export default function WinnerListRow(props: IWinnerListRowProps): ReactElement {
  const { winner } = props
  const { win, title, address, winnerStatus, notes } = useStore(winner.dataStore)
  const [selected, setSelected] = useState(false)
  const navigate = useNavigate()
  const setDrawer = useDrawer()
  const setContext = useLayoutContextDispatch()
  const showWin = useCallback(() => {
    if (!win) return
    setSelected(true)
    setContext((ctx) => update(ctx, { sideBarOpen: { $set: false } }))
    setDrawer({
      element: <WinDetailsBar wins={[win]} />,
      width: 400,
      title: "Gewinne",
      onClose: () => {
        setSelected(false)
      },
    })
  }, [setContext, setDrawer, win])

  const showNotice = useCallback(() => {
    setSelected(true)
    setContext((ctx) => update(ctx, { sideBarOpen: { $set: false } }))
    setDrawer({
      element: <WinnerNoticeBar winner={winner} />,
      width: 400,
      title: "Notiz",
      onClose: () => {
        setSelected(false)
      },
    })
  }, [setContext, setDrawer, winner])

  const showDetails = useCallback(
    () => navigate("/" + CRouteWinners + "/" + CRouteWinnerDetails + "/" + winner.getApiId()),
    [winner, navigate]
  )

  const { project, participationEndDate } = useStore(win?.dataStore.state.competition?.dataStore) || {}
  const { projectManager } = useStore(project?.dataStore) || {}

  return (
    <TableRow className={selected ? "selected" : undefined}>
      <TableCell>
        <IconButton onClick={showDetails}>
          <NotesIcon />
        </IconButton>
      </TableCell>
      <TableCell {...CSenderCellProps}>{project && <ProjectLabel project={project} />}</TableCell>
      <TableCell>{title}</TableCell>
      <TableCell>{address?.given_name}</TableCell>
      <TableCell>{address?.family_name}</TableCell>
      <TableCell>{win && <WinChip win={win} onClick={showWin} />}</TableCell>
      <TableCell>{participationEndDate ? format(participationEndDate, "P, p", { locale: de }) : ""}</TableCell>
      <TableCell>{winnerStatus}</TableCell>
      <TableCell>
        {projectManager?.map((user) => (
          <UserChip key={user.getApiId()} user={user} />
        ))}
      </TableCell>
      <TableCell>
        <Typography variant="body1" component="div" sx={{ cursor: "pointer" }} noWrap={true} onClick={showNotice}>
          {notes}
        </Typography>
      </TableCell>
    </TableRow>
  )
}
