import { ReactElement } from "react"
import { CWinnerStatus, GmtWinner } from "../../../../server/model"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import { useStore } from "../../../../core"

export interface IWinnerStatsProps {
  readonly winner: GmtWinner
}

export default function WinnerStats(props: IWinnerStatsProps): ReactElement {
  const { winner } = props
  const { winnerStatus } = useStore(winner.dataStore)
  const activeStep = winnerStatus ? CWinnerStatus.indexOf(winnerStatus) : 0
  return (
    <Box>
      <Stepper activeStep={activeStep} alternativeLabel>
        {CWinnerStatus.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
