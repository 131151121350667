import { ReactNode } from "react"
import { LabelDisplayedRowsArgs } from "@mui/material/TablePagination/TablePagination"
import { formatCurrency } from "./components/shared/formatCurrency"

export const CTablePaginationProps: {
  readonly labelDisplayedRows: (args: LabelDisplayedRowsArgs) => ReactNode
  readonly labelRowsPerPage: string
} = {
  labelRowsPerPage: "Anzahl pro Seite",
  labelDisplayedRows: ({ from, to, count }) => `${from}–${to} von ${count !== -1 ? count : `mehr als ${to}`}`,
}

export function moneyValueTransformer(value: string, fromInput: boolean): string {
  if (fromInput) {
    return value.replace(/[^0-9,]/g, "").replaceAll(",", ".")
  } else {
    return formatCurrency(value, true)
  }
}
