import { IFilterSpec } from "../IFilterSpec"
import { QueryParam } from "../QueryParam"

export class GroupFilterSpec implements IFilterSpec {
  readonly conjunction: "OR"
  readonly filters: ReadonlyArray<IFilterSpec>

  constructor(conjunction: "OR", filters: ReadonlyArray<IFilterSpec>) {
    this.conjunction = conjunction
    this.filters = filters
  }

  getQueryParams(idx: string): ReadonlyArray<QueryParam> {
    const groupId = "g" + idx
    let rv = [new QueryParam(`filter[${groupId}][group][conjunction]`, this.conjunction)]
    this.filters.forEach((filter, index) => {
      rv = rv.concat(filter.getQueryParams(groupId + "-" + index, groupId))
    })
    return rv
  }
}
