import { ReactElement, useMemo, useState } from "react"
import { Container, Grid } from "@mui/material"

import CompetitionsWidget from "./CompetitionsWidget"

import AddButtons from "./AddButtons"
import TopWidgets, { IWidgetDef } from "./TopWidgets"
import { useStore } from "../../../core"
import { CSessionState } from "../../state"
import { addDays } from "date-fns"
import { CRouteWinners } from "../../routes"
import { CRouteWinnerDrawing } from "../winners/routes"

export default function Index(): ReactElement {
  const user = useStore(CSessionState, (state) => state.user)
  const widgets = useMemo(
    (): ReadonlyArray<IWidgetDef> => [
      {
        title: "Meine Gewinnspiele",
        compFilter: { projectUser: user, gameStates: ["Entwurf", "Aktiv"] },
        iconKey: "My",
      },
      {
        title: "Starten Demnächst",
        compFilter: { projectUser: user, minStart: new Date(), maxStart: addDays(new Date(), 7) },
        iconKey: "StartSoon",
      },
      {
        title: "Export fehlt",
        compFilter: { minStart: new Date(), maxStart: addDays(new Date(), 7), notExported: true },
        iconKey: "ExportMissing",
      },
      {
        title: "Bereit für Ziehung",
        compFilter: { gameStatus: "Beendet" },
        url: CRouteWinners + "/" + CRouteWinnerDrawing,
        iconKey: "ReadyForDraw",
      },
      { title: "Bereit für Validierung", winnerFilter: { winnerStatus: "Vollständig" }, iconKey: "ReadyForValidation" },
      {
        title: "Gewinner in Abwicklung",
        winnerFilter: { winnerStates: ["Benachrichtigt", "Geprüft"] },
        iconKey: "WinnerInLiquidation",
      },
    ],
    [user]
  )
  const [widgetDef, setWidgetDef] = useState<IWidgetDef>(widgets[0])

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} paddingBottom={3}>
        <TopWidgets setWidgetDef={setWidgetDef} widgets={widgets} />
        <Grid item xs={12} xl={10}>
          <CompetitionsWidget widgetDef={widgetDef} />
        </Grid>
        <Grid item xs={12} xl={2}>
          <AddButtons />
        </Grid>
      </Grid>
    </Container>
  )
}
