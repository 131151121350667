import { ReactElement, useState } from "react"

import { GmtPartner } from "server/model"
import { fetchPartners, IFetchPartnersFilter, IFetchResult, IPagerInfo, ISortSpec } from "server/logic"
import ListTable, { IColumnSpec, useFetchSpec } from "../shared/ListTable"
import PartnerListRow from "./PartnerListRow"

export interface IPartnersListProps {
  readonly pager?: IPagerInfo
  readonly filter: IFetchPartnersFilter
  readonly sort?: ISortSpec
}

const CDefaultSort: ISortSpec = {
  key: "title",
}

const CColumns: ReadonlyArray<IColumnSpec> = [
  {
    key: "title",
    title: "Firmenname",
    sortKey: "title",
  },
  {
    key: "details",
    title: "Details",
  },
]

export default function PartnersList(props: IPartnersListProps): ReactElement {
  const { pager, filter, sort = CDefaultSort } = props

  const [fetchSpec, setFetchSpec] = useFetchSpec(filter, pager, sort)
  const [fetchResult, setFetchResult] = useState<IFetchResult<GmtPartner>>()

  return (
    <ListTable
      columns={CColumns}
      fetchSpec={fetchSpec}
      setFetchSpec={setFetchSpec}
      fetchResult={fetchResult}
      setFetchResult={setFetchResult}
      doFetch={fetchPartners}
      className="PartnersList"
      renderRow={(partner) => <PartnerListRow key={partner.getApiId()} partner={partner} />}
    />
  )
}
