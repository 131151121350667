import { Fragment, ReactElement, useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"

import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import LoadingButton from "@mui/lab/LoadingButton"

import CreateIcon from "@mui/icons-material/Create"

import { GmtProject } from "server/model"
import { useMessages } from "../../../components/LayoutContext"
import ProjectTechPaper from "../../../components/projects/ProjectTechPaper"
import { CRouteProjects } from "../../../routes"
import { CRouteProjectDetails } from "../routes"
import { saveProject } from "../../../../server/logic/projects"

export default function StepThree({
  project,
  goBack,
}: {
  readonly project: GmtProject
  readonly goBack: () => void
}): ReactElement {
  const navigate = useNavigate()
  const setMessage = useMessages()
  const [saving, setSaving] = useState(false)

  const onSave = useCallback(() => {
    setSaving(true)
    saveProject(project).then(
      () => {
        navigate("/" + CRouteProjects + "/" + CRouteProjectDetails + "/" + project.getApiId())
      },
      (error) => {
        setSaving(false)
        setMessage({ message: "Projekt konnte nicht gespeichert werden", severity: "error" })
        console.error(error)
      }
    )
  }, [project, navigate, setMessage])

  return (
    <Fragment>
      <ProjectTechPaper project={project} />
      <Stack direction="row">
        <Button variant="outlined" onClick={goBack}>
          Zurück
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <LoadingButton
          variant="contained"
          onClick={onSave}
          loading={saving}
          loadingPosition="start"
          startIcon={<CreateIcon />}
        >
          Projekt Anlegen
        </LoadingButton>
      </Stack>
    </Fragment>
  )
}
