import { Fragment, ReactElement, useCallback, useState } from "react"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import LoadingButton from "@mui/lab/LoadingButton"

import CreateIcon from "@mui/icons-material/Create"
import EmailIcon from "@mui/icons-material/Email"

import { Store, useStore } from "core"
import { GmtProject } from "server/model"
import { createBriefing, downloadBriefing, ICreateBriefingResponse } from "server/logic"
import { useMessages } from "../../../components/LayoutContext"
import { useNavigate } from "react-router-dom"
import { CRouteCompetitions } from "../../../routes"
import { CRouteCompetitionsCreate } from "../../competitions/routes"

const CCache: Store<Readonly<Record<string, ICreateBriefingResponse | undefined>>> = Store.create({})

export default function BriefingButtons({ project }: { readonly project: GmtProject }): ReactElement {
  const id = project.getApiId() || "nA"
  const setMessage = useMessages()
  const navigate = useNavigate()
  const response = useStore(CCache, (state) => state[id], [id])
  const [loading, setLoading] = useState(false)
  const isActive = useStore(project.baseStore, (data) => data.state === "2")

  const onClickCreate = useCallback(() => {
    setLoading(true)
    createBriefing(project)
      .then(
        (response) => {
          CCache.update({ [id]: { $set: response } })
          return downloadBriefing(project, response)
        },
        (error) => {
          console.error(error)
          setMessage({ message: "Briefing konnte nicht erstellt werden", severity: "error" })
        }
      )
      .then(
        () => {
          setLoading(false)
        },
        (error) => {
          console.error(error)
          setMessage({ message: "Briefing konnte nicht runtergeladen werden", severity: "error" })
          setLoading(false)
        }
      )
  }, [id, project, setMessage])

  const onClickSendBriefing = useCallback(() => {
    if (!response) return
    window.location.href =
      "mailto:" +
      response.receivers.join(",") +
      "?subject=" +
      encodeURIComponent(response.subject) +
      "&body=" +
      encodeURIComponent(response.body)
    setMessage({ message: "Das Email-Program wurde geöffnet", severity: "success" })
  }, [response, setMessage])

  const createCompetition = useCallback(() => {
    navigate("/" + CRouteCompetitions + "/" + CRouteCompetitionsCreate + "?project=" + encodeURIComponent(id))
  }, [id, navigate])

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }} />
      <LoadingButton
        variant={response ? "outlined" : "contained"}
        loading={loading}
        onClick={onClickCreate}
        loadingPosition="start"
        startIcon={<CreateIcon />}
      >
        Briefing erstellen
      </LoadingButton>
      {response && (
        <Button variant="contained" startIcon={<EmailIcon />} onClick={onClickSendBriefing}>
          Briefing verschicken
        </Button>
      )}
      {isActive && (
        <Button variant="contained" startIcon={<CreateIcon />} onClick={createCompetition}>
          Gewinnspiel anlegen
        </Button>
      )}
    </Fragment>
  )
}
