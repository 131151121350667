import { ReactElement, useEffect, useMemo, useState } from "react"

import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"

import CircleIcon from "@mui/icons-material/Circle"

import { useStore } from "core"
import { CParticipationTypeNames, CProjectStateNames, GmtProject } from "server/model"
import { IFetchResult, ISortSpec } from "server/logic"
import { fetchProjects, IFetchProjectsFilter } from "server/logic/projects"
import ListTable, { IColumnSpec, useFetchSpec } from "../shared/ListTable"
import SenderImage, { CSenderCellProps, CSenderCellWidth } from "../sender/SenderImage"
import KeywordsChipList from "../shared/KeywordsChipList"
import ServiceNumbersChipList from "../serviceNumbers/ServiceNumbersChipList"
import Tooltip from "@mui/material/Tooltip"
import UserChip from "../shared/UserChip"

export interface IProjectsListProps {
  readonly filter?: IFetchProjectsFilter
  readonly data?: ReadonlyArray<GmtProject>
  readonly renderDetails?: (project: GmtProject) => ReactElement
  readonly columns?: ReadonlyArray<string>
  readonly exclude?: ReadonlyArray<string>
  readonly selected?: GmtProject
}

const CDefaultSort: ISortSpec = {
  key: "title",
}

const CColumns: ReadonlyArray<IColumnSpec> = [
  {
    key: "details",
  },
  {
    key: "title",
    title: "Titel",
    sortKey: "title",
  },
  {
    key: "sender",
    title: "Brand",
    sortKey: "field_sender.name",
    width: CSenderCellWidth,
  },
  {
    key: "field_participation_route",
    title: "Weg",
    sortKey: "field_participation_route",
  },
  {
    key: "field_status",
    title: "St.",
    sortKey: "field_status",
  },
  {
    key: "field_game_mechanics",
    title: "Mechanik",
    sortKey: "field_game_mechanics.name",
  },
  {
    key: "field_service_number",
    title: "ServiceNr",
  },
  {
    key: "field_project_manager",
    title: "Projektverantwortliche(r)",
  },
  {
    key: "field_sms_keywords",
    title: "Kennwort",
  },
]

export default function ProjectsList(props: IProjectsListProps): ReactElement {
  const { filter, renderDetails, data, columns, exclude, selected } = props

  const [fetchSpec, setFetchSpec] = useFetchSpec(filter, undefined, CDefaultSort)
  const [fetchResult, setFetchResult] = useState<IFetchResult<GmtProject> | undefined>(
    data && !filter
      ? {
          data,
          count: data.length,
          pager: { page: 0, pageSize: data.length },
        }
      : undefined
  )

  // update the fetch result, if data changes
  useEffect(() => {
    setFetchResult((fetchResult) =>
      !data || fetchResult?.data === data
        ? fetchResult
        : {
            data,
            count: data.length,
            pager: { page: 0, pageSize: data.length },
          }
    )
  }, [data])

  const isData = !!data
  const headerColumns = useMemo(
    () =>
      CColumns.filter((column) => !columns || columns.includes(column.key)).map(({ sortKey, ...rest }) =>
        isData ? rest : { ...rest, sortKey }
      ),
    [columns, isData]
  )

  return (
    <ListTable
      columns={headerColumns}
      exclude={exclude}
      fetchSpec={fetchSpec}
      setFetchSpec={setFetchSpec}
      fetchResult={fetchResult}
      setFetchResult={setFetchResult}
      doFetch={data ? undefined : fetchProjects}
      className="ProjectsList marginBottom"
      renderRow={(project) => (
        <ProjectTableRow
          key={project.getApiId()}
          project={project}
          columns={columns}
          exclude={exclude}
          renderDetails={renderDetails}
          selected={selected?.getApiId() === project.getApiId()}
        />
      )}
    />
  )
}

interface IProjectTableRowProps {
  readonly project: GmtProject
  readonly columns?: ReadonlyArray<string>
  readonly exclude?: ReadonlyArray<string>
  readonly renderDetails?: (project: GmtProject) => ReactElement
  readonly selected?: boolean
}

const colors = {
  "0": "red",
  "1": "yellow",
  "2": "green",
}

function ProjectTableRow(props: IProjectTableRowProps): ReactElement {
  const { project, columns, renderDetails, exclude, selected } = props
  const {
    sender,
    title,
    serviceNumbersOnline,
    serviceNumbersTv,
    participationType,
    gameMechanics,
    state,
    keywordsOnline,
    keywordsTv,
    projectManager,
  } = useStore(project.dataStore)

  return (
    <TableRow className={selected ? "selected" : undefined}>
      {renderDetails && <TableCell>{renderDetails(project)}</TableCell>}
      {(!columns?.length || columns.includes("title")) && <TableCell className="Title">{title}</TableCell>}
      {(!columns?.length || columns.includes("sender")) && !exclude?.includes("sender") && (
        <TableCell {...CSenderCellProps}>{sender && <SenderImage sender={sender} tooltip />}</TableCell>
      )}
      {(!columns?.length || columns.includes("field_participation_route")) && (
        <TableCell>{participationType ? CParticipationTypeNames[participationType] : "k.A."}</TableCell>
      )}
      {(!columns?.length || columns.includes("field_status")) && (
        <TableCell>
          <Tooltip title={state ? CProjectStateNames[state] : "k.A."}>
            <CircleIcon className={"statusIcon " + colors[state || "0"]} fontSize="small" />
          </Tooltip>
        </TableCell>
      )}
      {(!columns?.length || columns.includes("field_game_mechanics")) && <TableCell>{gameMechanics?.name}</TableCell>}
      {(!columns?.length || columns.includes("field_creditor_key")) && (
        <TableCell>
          <ServiceNumbersChipList online={serviceNumbersOnline} tv={serviceNumbersTv} />
        </TableCell>
      )}
      {(!columns?.length || columns.includes("field_project_manager")) && (
        <TableCell>
          {projectManager?.map((user) => (
            <UserChip key={user.getApiId()} user={user} />
          ))}
        </TableCell>
      )}
      {(!columns?.length || columns.includes("field_sms_keywords")) && !exclude?.includes("field_sms_keywords") && (
        <TableCell style={{ maxWidth: "10%" }}>
          <KeywordsChipList online={keywordsTv} tv={keywordsOnline} />
        </TableCell>
      )}
    </TableRow>
  )
}
