import { GmtModel } from "./core"

export class GmtUser extends GmtModel {
  static jsonApiType = "user--user"
  static endpoint = "user/user"

  get name(): string {
    return this.getAttribute("name")
  }

  get mail(): string {
    return this.getAttribute("mail")
  }

  get status(): boolean {
    return this.getAttribute("status")
  }

  get displayName(): string {
    const { name, mail } = this
    return name ? (mail ? name + " <" + mail + ">" : name) : mail || "<k.A.>"
  }
}
