import { ReactElement, useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"

import { fetchSponsors, IFetchSponsorsFilter } from "server/logic/fetchSponsors"
import { CRouteSponsorCreate } from "../routes"
import SponsorsList from "ui/components/sponsors/SponsorsList"
import SponsorsFilter from "./SponsorsFilter"
import { createPromiseTerminator } from "../../../../core"

export default function SponsorsSearch(): ReactElement {
  const navigate = useNavigate()
  const [filter, setFilter] = useState<IFetchSponsorsFilter>({
    text: "",
  })
  const [sponsorCount, setSponsorCount] = useState<number>()
  useEffect(() => {
    return createPromiseTerminator(fetchSponsors({ filter: {}, pager: { page: 0, pageSize: 1 } }), (result) => {
      setSponsorCount(result.count)
    })
  }, [])
  const createNew = useCallback(() => navigate(CRouteSponsorCreate), [navigate])
  return (
    <Paper id="SponsorsSearch">
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Alle Sponsoren{typeof sponsorCount !== "number" ? "" : " [" + sponsorCount + "]"}
        </Typography>
        <Button variant="contained" onClick={createNew}>
          Sponsor anlegen
        </Button>
      </Toolbar>
      <SponsorsFilter filter={filter} setFilter={setFilter} />
      <SponsorsList filter={filter} />
    </Paper>
  )
}
