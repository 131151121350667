import { ReactElement, useCallback, useState } from "react"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"

export function useErrorDialog(): {
  dialogProps: IErrorsDialogProps
  handleOpen: () => void
  setDialogErrors: (errors: ReadonlyArray<string>) => void
} {
  const [open, setOpen] = useState(false)
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const [dialogErrors, setDialogErrors] = useState<ReadonlyArray<string>>([])
  return { dialogProps: { open, handleClose, dialogErrors }, handleOpen, setDialogErrors }
}

export interface IErrorsDialogProps {
  readonly open: boolean
  readonly handleClose: () => void
  readonly dialogErrors: ReadonlyArray<string>
}

export interface IErrorsDialogInternalProps extends IErrorsDialogProps {
  readonly title: string
  readonly description: string
}

export default function ErrorsDialog(props: IErrorsDialogInternalProps): ReactElement {
  const { open, handleClose, title, description, dialogErrors } = props
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          {description}
          <ul>
            {dialogErrors.map((text, idx) => (
              <li key={idx}>{text}</li>
            ))}
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
