export const CParticipationTypes = ["phone", "online", "hybrid"] as const
export type TParticipationType = (typeof CParticipationTypes)[number]
export const CParticipationTypeNames: Readonly<Record<TParticipationType, string>> = {
  phone: "Telefon",
  online: "Online",
  hybrid: "Hybrid",
}

export interface IAddress {
  readonly given_name?: string | null
  readonly additional_name?: string | null
  readonly family_name?: string | null
  readonly organization?: string | null
  readonly address_line1?: string | null
  readonly address_line2?: string | null
  readonly postal_code?: string | null
  readonly locality?: string | null
  readonly country_code?: string | null
}

export const CCountryCodes = ["DE", "AT", "CH"]
export type TCountryCode = (typeof CCountryCodes)[number]
export const CCountryNames: Readonly<Record<TCountryCode, string>> = {
  DE: "Deutschland",
  AT: "Österreich",
  CH: "Schweiz",
}
