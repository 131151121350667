import { Fragment, ReactElement, SyntheticEvent, useCallback, useEffect, useState } from "react"

import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"

import { createPromiseTerminator, useStore } from "core"
import { GmtKeyword, GmtProject } from "server/model"
import { entitiesEqual } from "server/logic"
import { createKeyword, fetchKeywords } from "server/logic/keywords"
import { useInlineEditing } from "../forms"

export interface IKeywordsAutoCompleteProps {
  readonly project: GmtProject
  readonly online: boolean
  readonly doSave?: () => Promise<unknown>
}

export default function KeywordsAutoComplete(props: IKeywordsAutoCompleteProps): ReactElement {
  const { project, online, doSave } = props
  const isActive = useStore(project.baseStore, (data) => data.state === "2")

  const { endAdornment, isInline } = useInlineEditing(
    "array",
    project.dataStore,
    online ? "keywordsOnline" : "keywordsTv",
    false,
    project.baseStore,
    doSave
  )

  const value = useStore(project.dataStore, (state) => (online ? state.keywordsOnline : state.keywordsTv), [online])

  const [keywords, setKeywords] = useState<ReadonlyArray<GmtKeyword>>([])
  const [inputValue, setInputValue] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getCurrentState = (): ReadonlyArray<GmtKeyword> =>
      project.dataStore.state[online ? "keywordsOnline" : "keywordsTv"] || []
    setKeywords(getCurrentState())
    if (inputValue) {
      setLoading(true)
      return createPromiseTerminator(
        fetchKeywords({ filter: { text: inputValue }, sort: { key: "name" }, pager: { pageSize: 10, page: 0 } }),
        (result) => {
          const upperCaseValue = inputValue.toUpperCase()
          const newOption =
            result.data.find((entry) => entry.dataStore.state.name?.toUpperCase() === upperCaseValue) ||
            getCurrentState().find((entry) => entry.dataStore.state.name?.toUpperCase() === upperCaseValue) ||
            createKeyword(upperCaseValue)
          setKeywords([
            newOption,
            ...getCurrentState(),
            ...result.data.filter(
              (entry) =>
                entry !== newOption && !getCurrentState().find((e) => entry === e || entry.getApiId() === e.getApiId())
            ),
          ])
          setLoading(false)
        },
        () => {
          setLoading(false)
        }
      )
    } else {
      setLoading(false)
    }
  }, [inputValue, online, project.dataStore])

  const onInputChange = useCallback((event: SyntheticEvent, value: string) => {
    setInputValue(value)
  }, [])
  const getOptionLabel = useCallback(
    (option: GmtKeyword) =>
      option.getApiId()
        ? (option.dataStore.state.locked ? "[" + option.dataStore.state.name + "]" : option.dataStore.state.name) ||
          "k.A."
        : "+ " + (option.dataStore.state.name || "k.A.") + " +",
    []
  )
  const getOptionDisabled = useCallback((option: GmtKeyword) => !!option.dataStore.state.locked, [])
  const onChange = useCallback(
    (event: SyntheticEvent, value: Array<GmtKeyword>) =>
      project.dataStore.update({ [online ? "keywordsOnline" : "keywordsTv"]: { $set: value } }),
    [online, project.dataStore]
  )

  return (
    <Autocomplete
      multiple
      disableClearable
      filterSelectedOptions
      openOnFocus
      options={keywords}
      loading={loading}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      onInputChange={onInputChange}
      isOptionEqualToValue={entitiesEqual}
      value={value as Array<GmtKeyword>}
      onChange={onChange}
      readOnly={isActive}
      renderInput={(params) => (
        <TextField
          {...params}
          className={isInline ? "noLabel" : undefined}
          variant={isInline ? "filled" : "standard"}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {params.InputProps.endAdornment}
                {endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  )
}
