import { Fragment, ReactElement } from "react"

import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"

import { GmtProject } from "server/model"
import ProjectTelcoPaper from "../../../components/projects/ProjectTelcoPaper"

export interface IStepTwoProps {
  readonly project: GmtProject
  readonly goNext: () => void
  readonly goBack: () => void
}

export default function StepTwo(props: IStepTwoProps): ReactElement {
  const { project, goBack, goNext } = props

  return (
    <Fragment>
      <ProjectTelcoPaper project={project} />
      <Stack direction="row">
        <Button variant="outlined" onClick={goBack}>
          Zurück
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="contained" onClick={goNext}>
          Weiter
        </Button>
      </Stack>
    </Fragment>
  )
}
