import { GmtModel } from "./core"
import { GmtSender } from "./GmtSender"
import { GmtPartner } from "./GmtPartner"
import { ToOneRelation } from "../../coloquent"

export interface IWpsData {
  readonly title: string
  readonly wpsNummer: string
  readonly sender: GmtSender | null
  readonly supplier: GmtPartner | null
  readonly validity: { readonly end_value: string; readonly value: string }
}

export class GmtWps extends GmtModel<IWpsData> {
  static jsonApiType = "node--gmt_wps_number"
  static endpoint = "node/gmt_wps_number"

  static attMapping: Readonly<Record<string, keyof IWpsData>> = {
    title: "title",
    field_wps_number: "wpsNummer",
    field_validity: "validity",
  }

  static relMapping: Readonly<Record<string, keyof IWpsData>> = {
    field_sender: "sender",
    field_supplier: "supplier",
  }

  field_supplier(): ToOneRelation<GmtPartner, this> {
    return this.hasOne(GmtPartner)
  }

  field_sender(): ToOneRelation<GmtSender, this> {
    return this.hasOne(GmtSender)
  }
}
