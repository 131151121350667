import { ReactElement, useState } from "react"

import { GmtSponsor } from "server/model"
import { fetchSponsors, IFetchSponsorsFilter, IFetchResult, IPagerInfo, ISortSpec } from "server/logic"
import ListTable, { IColumnSpec, useFetchSpec } from "../shared/ListTable"
import SponsorListRow from "./SponsorListRow"

import "./SponsorsList.scss"

export interface ISponsorsListProps {
  readonly pager?: IPagerInfo
  readonly filter: IFetchSponsorsFilter
  readonly sort?: ISortSpec
}

const CDefaultSort: ISortSpec = {
  key: "title",
}

const CColumns: ReadonlyArray<IColumnSpec> = [
  {
    key: "title",
    title: "Firmenname",
    sortKey: "title",
  },
  {
    key: "field_handover",
    title: "Abrechnung mit Partnershops",
    sortKey: "field_handover",
  },
  {
    key: "field_active",
    title: "Aktiv",
    sortKey: "field_active",
  },
  {
    key: "details",
    title: "Details",
  },
]

export default function SponsorsList(props: ISponsorsListProps): ReactElement {
  const { pager, filter, sort = CDefaultSort } = props

  const [fetchSpec, setFetchSpec] = useFetchSpec(filter, pager, sort)
  const [fetchResult, setFetchResult] = useState<IFetchResult<GmtSponsor>>()

  return (
    <ListTable
      columns={CColumns}
      fetchSpec={fetchSpec}
      setFetchSpec={setFetchSpec}
      fetchResult={fetchResult}
      setFetchResult={setFetchResult}
      doFetch={fetchSponsors}
      className="SponsorsList"
      renderRow={(sponsor) => <SponsorListRow key={sponsor.getApiId()} sponsor={sponsor} />}
    />
  )
}
