import { ReactElement, useCallback, useEffect } from "react"
import Alert from "@mui/material/Alert"
import { useLayoutContext, useMessages } from "../LayoutContext"
import "./AppMessages.scss"

export default function AppMessages(): ReactElement | null {
  const { message } = useLayoutContext()
  const setMessage = useMessages()
  const { message: msg, ...rest } = message || {}
  const onClose = useCallback(() => {
    setMessage()
  }, [setMessage])
  useEffect(() => {
    if (!message) return
    const timeout = window.setTimeout(() => {
      setMessage()
    }, message.timeout || (message.severity === "error" ? 10000 : 5000))
    return () => {
      window.clearTimeout(timeout)
    }
  }, [message, setMessage])
  return message ? (
    <div id="AppMessages">
      <Alert {...rest} variant="filled" onClose={onClose}>
        {msg}
      </Alert>
    </div>
  ) : null
}
