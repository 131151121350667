import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from "react"

import Stack from "@mui/material/Stack"
import Grid from "@mui/material/Grid"

import { createPromiseTerminator, useStore } from "core"
import { GmtMaterialPrice, IMaterialPriceData } from "server/model"
import { fetchProduct } from "server/logic/products"
import DetailsTable from "../shared/DetailsTable"
import { formatCurrency } from "../shared/formatCurrency"
import { IInlineEditingProps, StoreTextField } from "../forms"
import SponsorAndShipping from "./SponsorAndShipping"

export default function ProductPricePanel({
  price,
  readOnly,
  children,
}: {
  readonly price: GmtMaterialPrice
  readonly readOnly?: boolean
  readonly children?: ReactNode
}): JSX.Element {
  const { dataStore, baseStore } = price
  const product = useStore(dataStore, (data) => data.product)
  const [myProduct, setMyProduct] = useState(product)
  const productData = useStore(myProduct?.dataStore)
  const stored = !!price.getApiId()

  const doSave = useCallback((): Promise<unknown> => {
    return price.save().catch((error) => {
      console.error(error)
    })
  }, [price])

  const inlineProps = useMemo<IInlineEditingProps<Partial<IMaterialPriceData>>>(
    () => (stored ? { baseStore, doSave } : {}),
    [stored, doSave, baseStore]
  )

  useEffect(() => {
    const id = product?.getApiId()
    if (!id) return
    return createPromiseTerminator(fetchProduct(id), setMyProduct)
  }, [product])

  return (
    <Stack spacing={2}>
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={6}>
          <StoreTextField
            {...inlineProps}
            label="Menge"
            store={dataStore}
            property="qty"
            type="number"
            disabled={readOnly}
            required
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} />
        <SponsorAndShipping
          price={price}
          doSave={stored ? doSave : undefined}
          inlineProps={inlineProps}
          readOnly={readOnly}
        />
        {children}
      </Grid>
      {productData && (
        <DetailsTable
          data={[
            { title: "Bezeichnung", value: productData.title },
            { title: "Marke", value: productData.brand?.name },
            { title: "Nettopreis", value: formatCurrency(productData.netcost) },
            { title: "Beschreibung", value: productData.desc },
            ...(productData.images?.length
              ? [
                  {
                    title: productData.images.length === 1 ? "Bild" : "Bilder",
                    value: productData.images?.map((image, idx) => (
                      <Fragment key={idx}>
                        <img alt="" src={image.uri} style={{ maxWidth: 100, maxHeight: 100 }} />
                        <br />
                      </Fragment>
                    )),
                  },
                ]
              : []),
          ]}
        />
      )}
    </Stack>
  )
}
