import { Fragment, ReactElement, useCallback, useState } from "react"

import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"

import EditIcon from "@mui/icons-material/Edit"
import DoneIcon from "@mui/icons-material/Done"
import CloseIcon from "@mui/icons-material/Close"

import { useStore } from "core"
import { GmtProject, GmtServiceNumber } from "server/model"
import { useDrawer } from "../LayoutContext"
import { arraysNotEqual } from "../forms/core"
import ServiceNumberDrawer from "../serviceNumbers/ServiceNumberDrawer"
import ServiceNumbersChipList from "../serviceNumbers/ServiceNumbersChipList"
import KeywordsAutoComplete from "./KeywordsAutoComplete"

export default function ProjectTelcoPaper({
  project,
  doSave,
}: {
  readonly project: GmtProject
  readonly doSave?: () => Promise<unknown>
}): ReactElement {
  const { participationType } = useStore(project.dataStore)

  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Telco
        </Typography>
      </Toolbar>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Teilnahmeweg</TableCell>
              <TableCell>Servicenummer(n)</TableCell>
              <TableCell>SMS-Keyword(s)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(participationType === "phone" || participationType === "hybrid") && (
              <ParticipationRow project={project} title={"Telefon"} property="serviceNumbersTv" doSave={doSave} />
            )}
            {(participationType === "online" || participationType === "hybrid") && (
              <ParticipationRow project={project} title={"Online"} property="serviceNumbersOnline" doSave={doSave} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

function ParticipationRow({
  project,
  title,
  doSave,
  property,
}: {
  readonly project: GmtProject
  readonly title: string
  readonly doSave?: () => Promise<unknown>
  readonly property: "serviceNumbersTv" | "serviceNumbersOnline"
}): ReactElement {
  const { baseStore, dataStore } = project
  const [saving, setSaving] = useState(false)

  const list = useStore(dataStore, (state) => state[property], [property])
  const baseList = useStore(baseStore, (state) => state[property], [property])
  const isActive = useStore(project.baseStore, (data) => data.state === "2")

  const drawer = useDrawer()
  const openServicenumberDrawer = useCallback(() => {
    drawer({
      title: "Servicenummern",
      width: 620,
      element: <ServiceNumberDrawer project={project} />,
    })
  }, [drawer, project])

  const onSave = useCallback(async (): Promise<unknown> => {
    if (!doSave || isActive) return
    setSaving(true)
    return doSave().finally(() => {
      setSaving(false)
    })
  }, [doSave, isActive])

  const onCancel = useCallback(() => {
    project.dataStore.update({ [property]: { $set: project.baseStore.state[property] } })
  }, [project, property])

  const onDelete = useCallback(
    (value: GmtServiceNumber) => {
      project.removeFromRelation(property, value)
    },
    [project, property]
  )

  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell className="noPaddingY">
        <div className={!doSave ? "MuiFormGroup-root withPaddings" : "MuiFormGroup-root filled withPaddings"}>
          <Stack direction="row">
            <ServiceNumbersChipList
              tv={property === "serviceNumbersTv" ? list : undefined}
              online={property === "serviceNumbersOnline" ? list : undefined}
              onDelete={isActive ? undefined : onDelete}
            />
            {!isActive && (
              <IconButton color="primary" onClick={openServicenumberDrawer}>
                <EditIcon />
              </IconButton>
            )}
            {!!doSave && arraysNotEqual(list, baseList) && (
              <Fragment>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton onClick={onSave} color="success" disabled={saving}>
                  <DoneIcon />
                </IconButton>
                <IconButton onClick={onCancel} color="error">
                  <CloseIcon />
                </IconButton>
              </Fragment>
            )}
          </Stack>
        </div>
      </TableCell>
      <TableCell className="noPaddingY">
        <KeywordsAutoComplete
          project={project}
          doSave={!doSave ? undefined : onSave}
          online={property === "serviceNumbersOnline"}
        />
      </TableCell>
    </TableRow>
  )
}
