import { ReactElement, useState } from "react"

import { GmtWinner } from "server/model"
import { IFetchResult, IPagerInfo } from "server/logic"
import { fetchWinners, IFetchWinnersFilter, ISortWinnerSpec } from "server/logic/winners"
import ListTable, { IColumnSpec, useFetchSpec } from "../shared/ListTable"
import WinnerListRow from "./WinnerListRow"

import "./WinnersList.scss"
import { CSenderCellWidth } from "../sender/SenderImage"

export interface IWinnersListProps {
  readonly pager?: IPagerInfo
  readonly filter: IFetchWinnersFilter
  readonly sort?: ISortWinnerSpec
}

const CDefaultSort: ISortWinnerSpec = {
  key: "-field_ref_win.field_ref_competition.field_participation_end_date",
}

const CColumns: ReadonlyArray<IColumnSpec> = [
  {
    key: "details",
    title: "Details",
  },
  {
    sortKey: "field_ref_win.field_ref_competition.field_project_data.field_sender.name",
    title: "Sender",
    key: "field_sender",
    width: CSenderCellWidth,
    align: "center",
  },
  {
    key: "title",
    title: "Titel",
    sortKey: "title",
  },
  {
    sortKey: "field_winner_address.given_name",
    title: "Vorname",
    key: "given_name",
  },
  {
    sortKey: "field_winner_address.family_name",
    title: "Nachname",
    key: "family_name",
  },
  {
    key: "wins",
    title: "Gewinn(e)",
  },
  {
    key: "field_participation_end_date",
    sortKey: "field_ref_win.field_ref_competition.field_participation_end_date",
    title: "Teilnahmeschluss",
  },
  {
    key: "field_winner_status",
    sortKey: "field_winner_status",
    title: "Status",
  },
  {
    key: "field_project_manager",
    title: "Projektverantwortliche(r)",
  },
  {
    key: "notes",
    title: "Notizen",
  },
]

export default function WinnersList(props: IWinnersListProps): ReactElement {
  const { pager, filter, sort = CDefaultSort } = props
  const [fetchSpec, setFetchSpec] = useFetchSpec(filter, pager, sort)
  const [fetchResult, setFetchResult] = useState<IFetchResult<GmtWinner>>()

  return (
    <ListTable
      columns={CColumns}
      fetchSpec={fetchSpec}
      setFetchSpec={setFetchSpec}
      fetchResult={fetchResult}
      setFetchResult={setFetchResult}
      doFetch={fetchWinners}
      className="WinnersList"
      renderRow={(winner) => <WinnerListRow key={winner.getApiId()} winner={winner} />}
    />
  )
}
