import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import { ReactElement } from "react"
import { CGameStatus, GmtCompetition } from "../../../server/model"
import { useStore } from "../../../core"

export interface ICompetitionStatsProps {
  readonly comp: GmtCompetition
}

export default function CompetitionStats(props: ICompetitionStatsProps): ReactElement {
  const { comp } = props
  const { gameStatus } = useStore(comp.dataStore)
  const activeStep = (gameStatus && CGameStatus.indexOf(gameStatus)) || 0
  return (
    <Box>
      <Stepper activeStep={activeStep} alternativeLabel>
        {CGameStatus.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
