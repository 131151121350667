import { ReactElement, useCallback, useState } from "react"

import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import IconButton from "@mui/material/IconButton"

import DeleteIcon from "@mui/icons-material/Delete"
import NotesIcon from "@mui/icons-material/Notes"

import { useStore } from "core"
import { GmtCashPrice, GmtMaterialPrice, GmtPrice, GmtWin } from "server/model"
import { removePrice } from "server/logic/competitions"
import ListTable, { IColumnSpec } from "../shared/ListTable"
import { formatCurrency } from "../shared/formatCurrency"
import { useDrawer, useMessages } from "../LayoutContext"
import CashPricePanel from "./CashPricePanel"
import CustomPricePanel from "./CustomPricePanel"
import ProductPricePanel from "./ProductPricePanel"
import { format } from "date-fns"
import de from "date-fns/locale/de"

const CColumns: ReadonlyArray<IColumnSpec> = [
  { key: "actions", width: 100, className: "action" },
  { key: "qty", title: "Menge", width: 80 },
  { key: "typeName", title: "Art", width: 30 },
  { key: "displayName", title: "Bezeichnung" },
  { key: "sponsor", title: "Sponsor" },
  { key: "shipped", title: "Versand/Auszahlung" },
]

export default function PricesList({
  win,
  readOnly,
}: {
  readonly win: GmtWin
  readonly readOnly: boolean
}): ReactElement | null {
  const prices = useStore(win.dataStore, (data) => data.prices)
  const setMessage = useMessages()

  const doRemove = useCallback(
    (price: GmtPrice): void => {
      removePrice(win, price).then((success) => {
        if (!success) {
          setMessage({ message: "Unerwarteter Fehler beim löschen des Preises", severity: "error" })
        }
      })
    },
    [setMessage, win]
  )

  return prices?.length ? (
    <ListTable
      data={prices}
      columns={CColumns}
      className="marginBottom"
      renderRow={(price) => <PriceRow key={price.localId} price={price} doRemove={readOnly ? undefined : doRemove} />}
    />
  ) : null
}

function PriceRow({ price, doRemove }: IPriceRowProps): ReactElement {
  return price instanceof GmtCashPrice ? (
    <CashPriceRow price={price} doRemove={doRemove} />
  ) : price instanceof GmtMaterialPrice ? (
    <MaterialPriceRow price={price} doRemove={doRemove} />
  ) : (
    <TableRow>
      <TableCell>
        <PriceDeleteButton price={price} doRemove={doRemove} />
      </TableCell>
      <TableCell />
      <TableCell>Fehler</TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
    </TableRow>
  )
}

function CashPriceRow({ price, doRemove }: IPriceRowProps<GmtCashPrice>): ReactElement {
  const { winningAmount, sponsor, sepaDate } = useStore(price.dataStore)
  const [isSelected, setIsSelected] = useState(false)
  const drawer = useDrawer()

  const openDetailsDrawer = useCallback(() => {
    setIsSelected(true)
    drawer({
      element: <CashPricePanel price={price} readOnly={!doRemove} />,
      width: 620,
      title: "Geldpreis Details",
      onClose: () => {
        setIsSelected(false)
      },
    })
  }, [doRemove, drawer, price])

  return (
    <TableRow selected={isSelected}>
      <TableCell className={"action"} width={CColumns[0].width || "auto"}>
        <div style={{ whiteSpace: "nowrap" }}>
          <IconButton onClick={openDetailsDrawer}>
            <NotesIcon />
          </IconButton>
          <PriceDeleteButton price={price} doRemove={doRemove} />
        </div>
      </TableCell>
      <TableCell width={CColumns[1].width || "auto"}>1</TableCell>
      <TableCell width={CColumns[2].width || "auto"}>Geldpreis</TableCell>
      <TableCell>{formatCurrency(winningAmount)}</TableCell>
      <TableCell>{sponsor?.dataStore.state.title}</TableCell>
      <TableCell>{sepaDate ? format(sepaDate, "P, p", { locale: de }) : ""}</TableCell>
    </TableRow>
  )
}

function MaterialPriceRow({ price, doRemove }: IPriceRowProps<GmtMaterialPrice>): ReactElement {
  const { product, title, sponsor, qty, shippingDate } = useStore(price.baseStore)

  const [isSelected, setIsSelected] = useState(false)
  const drawer = useDrawer()

  const openDetailsDrawer = useCallback(() => {
    setIsSelected(true)
    drawer({
      element: product ? (
        <ProductPricePanel price={price} readOnly={!doRemove} />
      ) : (
        <CustomPricePanel price={price} readOnly={!doRemove} />
      ),
      width: 620,
      title: product ? "Shop-preis Details" : "Manuellerpreis Details",
      onClose: () => {
        setIsSelected(false)
      },
    })
  }, [doRemove, drawer, price, product])

  return (
    <TableRow selected={isSelected}>
      <TableCell className={"action"} width={CColumns[0].width || "auto"}>
        <div style={{ whiteSpace: "nowrap" }}>
          <IconButton onClick={openDetailsDrawer}>
            <NotesIcon />
          </IconButton>
          <PriceDeleteButton price={price} doRemove={doRemove} />
        </div>
      </TableCell>
      <TableCell width={CColumns[1].width || "auto"}>{qty}</TableCell>
      <TableCell width={CColumns[2].width || "auto"}>{product ? "Shop" : "Manueller Preis"}</TableCell>
      <TableCell>{product?.dataStore.state.title || title}</TableCell>
      <TableCell>{sponsor?.dataStore.state.title}</TableCell>
      <TableCell>{shippingDate ? format(shippingDate, "P, p", { locale: de }) : ""}</TableCell>
    </TableRow>
  )
}

interface IPriceRowProps<T extends GmtPrice = GmtPrice> {
  readonly price: T
  readonly doRemove?: (price: GmtPrice) => void
}

function PriceDeleteButton({ price, doRemove }: IPriceRowProps): ReactElement | null {
  const onDeleteClick = useCallback(() => doRemove?.(price), [doRemove, price])
  return doRemove ? (
    <IconButton onClick={onDeleteClick}>
      <DeleteIcon />
    </IconButton>
  ) : null
}
