import { ReactElement, useMemo } from "react"

import Stack from "@mui/material/Stack"
import IconButton from "@mui/material/IconButton"

import AddBoxIcon from "@mui/icons-material/AddBox"
import SearchIcon from "@mui/icons-material/Search"

import { Store, useStore } from "core"
import { GmtProject } from "server/model"
import { IFetchProjectsFilter } from "server/logic/projects"
import ProjectsList from "../projects/ProjectsList"
import { StoreDelayedTextField } from "../forms"

const CColumns = ["details", "title", "sender", "field_participation_route", "field_status"]

export interface IProjectsDrawerProps {
  readonly addProject: (project: GmtProject) => void
  readonly exclude: ReadonlyArray<GmtProject>
}

export default function ProjectsDrawer(props: IProjectsDrawerProps): ReactElement {
  const { addProject, exclude } = props
  const filterStore = useMemo(() => Store.create<IFetchProjectsFilter>({ exclude, text: "" }), [exclude])
  const filter = useStore(filterStore)
  return (
    <Stack spacing={2}>
      <StoreDelayedTextField label="Suche" store={filterStore} property="text" icon={<SearchIcon />} />
      <ProjectsList
        columns={CColumns}
        filter={filter}
        renderDetails={(project) => (
          <IconButton onClick={() => addProject(project)} disabled={project.dataStore.state.state !== "2"}>
            <AddBoxIcon />
          </IconButton>
        )}
      />
    </Stack>
  )
}
