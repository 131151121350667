import { Dispatch, Fragment, ReactElement, SetStateAction, useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useConfirm } from "material-ui-confirm"

import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import LoadingButton from "@mui/lab/LoadingButton"

import PublishIcon from "@mui/icons-material/Publish"
import UnpublishedIcon from "@mui/icons-material/Unpublished"
import LockIcon from "@mui/icons-material/Lock"
import LockOpenIcon from "@mui/icons-material/LockOpen"
import DeleteIcon from "@mui/icons-material/Delete"

import { IErrors, useStore } from "core"
import { GmtProject, IProjectData } from "server/model"
import {
  changeProjectState,
  deleteProject,
  validateProjectDataForActivation,
  validateProjectDataForReservation,
  validateProjectForActivation,
  validateProjectForReservation,
} from "server/logic/projects"
import { useMessages } from "../../../components/LayoutContext"
import { CRouteProjects } from "../../../routes"
import ErrorsDialog, { useErrorDialog } from "../../../components/shared/ErrorsDialog"

export default function ProjectButtons({
  project,
  setErrors,
}: {
  readonly project: GmtProject
  readonly setErrors: Dispatch<SetStateAction<IErrors<IProjectData> | undefined>>
}): ReactElement {
  const state = useStore(project.dataStore, (state) => state.state)
  const [deleting, setDeleting] = useState(false)
  const [activating, setActivating] = useState(false)
  const [deactivating, setDeactivating] = useState(false)

  const { handleOpen, dialogProps, setDialogErrors } = useErrorDialog()

  const setMessage = useMessages()
  const navigate = useNavigate()
  const confirm = useConfirm()

  const onDelete = useCallback(async () => {
    setDeleting(true)
    if (
      await confirm({
        title: "Projekt löschen?",
        description: "Soll das Projekt '" + project.baseStore.state.title + "' wirklich gelöscht werden?",
      }).then(
        () => true,
        () => false
      )
    ) {
      try {
        await deleteProject(project)
        setMessage({
          message: "Projekt wurde erfolgreich gelöscht.",
          severity: "success",
        })
        navigate("/" + CRouteProjects)
      } catch (error) {
        setMessage({
          message: "Beim Löschen des Projektes ist ein Fehler aufgetreten",
          severity: "error",
        })
        console.error(error)
      }
    }
    setDeleting(false)
  }, [confirm, navigate, project, setMessage])

  // this action does one step up
  const onActivate = useCallback(async () => {
    setActivating(true)
    try {
      const validation = await (state === "0" ? validateProjectForReservation : validateProjectForActivation)(project)
      setDialogErrors(validation)
      if (validation.length) {
        handleOpen()
        setActivating(false)
        setErrors(
          (state === "0" ? validateProjectDataForReservation : validateProjectDataForActivation)(
            project.dataStore.state
          )
        )
      } else {
        setErrors(undefined)
        await changeProjectState(project, state === "0" ? "1" : "2")
        setMessage({
          message: "Projekt wurde erfolgreich " + (state === "0" ? "reserviert" : "aktiviert"),
          severity: "success",
        })
        setActivating(false)
      }
    } catch (error) {
      setActivating(false)
      setMessage({
        message: "Beim " + (state === "0" ? "Reservieren" : "Aktivieren") + " des Projektes ist ein Fehler aufgetreten",
        severity: "error",
      })
      console.error(error)
    }
  }, [handleOpen, project, setDialogErrors, setErrors, setMessage, state])
  // this action does one step down
  const onDeactivate = useCallback(async () => {
    setDeactivating(true)
    try {
      await changeProjectState(project, state === "2" ? "1" : "0")
      setMessage({
        message: "Projekt wurde erfolgreich " + (state === "2" ? "reserviert" : "deaktiviert"),
        severity: "success",
      })
      setDeactivating(false)
    } catch (error) {
      setDeactivating(false)
      setMessage({
        message:
          "Beim " + (state === "2" ? "Reservieren" : "Deaktivieren") + " des Projektes ist ein Fehler aufgetreten",
        severity: "error",
      })
      console.error(error)
    }
  }, [project, setMessage, state])

  return (
    <Fragment>
      <Stack direction="row" spacing={3}>
        <Box sx={{ flexGrow: 1 }} />
        {state === "0" && (
          <LoadingButton
            variant="outlined"
            onClick={onDelete}
            loading={deleting}
            loadingPosition="start"
            color="error"
            startIcon={<DeleteIcon />}
          >
            Löschen
          </LoadingButton>
        )}
        {state !== "0" && (
          <LoadingButton
            variant="outlined"
            onClick={onDeactivate}
            loading={deactivating}
            loadingPosition="start"
            color="warning"
            startIcon={state === "2" ? <UnpublishedIcon /> : <LockOpenIcon />}
          >
            {state === "2" ? "Deaktivieren" : "Freigeben"}
          </LoadingButton>
        )}
        {state !== "2" && (
          <LoadingButton
            variant="contained"
            onClick={onActivate}
            loading={activating}
            loadingPosition="start"
            startIcon={state === "0" ? <LockIcon /> : <PublishIcon />}
          >
            {state === "0" ? "Reservieren" : "Aktivieren"}
          </LoadingButton>
        )}
      </Stack>
      <ErrorsDialog
        {...dialogProps}
        title={(state === "0" ? "Reservierung" : "Aktivierung") + " nicht möglich"}
        description={
          "Die " + (state === "0" ? "Reservierung" : "Aktivierung") + " ist aus folgenden Gründen nicht möglich:"
        }
      />
    </Fragment>
  )
}
