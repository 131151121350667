import { Fragment, ReactElement, useCallback, useMemo } from "react"

import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import ListItemText from "@mui/material/ListItemText"

import ListItemIcon from "@mui/material/ListItemIcon"

import { useStore } from "core"
import { GmtCompetition, GmtKeyword } from "server/model"
import { concatSets } from "server/logic"
import KeywordsChipList from "../../../components/shared/KeywordsChipList"
import { getKeywordIcon } from "../../../components/shared/KeywordChip"
import { arraysNotEqual } from "../../../components/forms/core"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import DoneIcon from "@mui/icons-material/Done"
import CloseIcon from "@mui/icons-material/Close"

const CLabel = "SMS Kennwörter"
const CLabelID = "SelectKeywordsLabel"

export interface ISelectKeywordsProps {
  readonly competition: GmtCompetition
  readonly doSave?: () => Promise<unknown>
  readonly disabled?: boolean
}

export default function SelectKeywords(props: ISelectKeywordsProps): ReactElement {
  const { competition, doSave, disabled } = props
  const { dataStore, baseStore } = competition
  const project = useStore(dataStore, (data) => data.project)
  const projects = useStore(dataStore, (data) => data.additionalProjects)
  const { keywordsOnline, keywordsTv } = useStore(project?.dataStore) || {}
  const allKeywords = useMemo(() => {
    let rv = concatSets(keywordsTv, keywordsOnline)
    projects?.forEach((p) => {
      const { keywordsOnline, keywordsTv } = p.dataStore.state
      rv = concatSets(rv, concatSets(keywordsTv, keywordsOnline))
    })
    return rv
  }, [keywordsOnline, keywordsTv, projects])
  const keywords = useStore(dataStore, (data) => data.smsKeywords)
  const baseKeywords = useStore(baseStore, (data) => data.smsKeywords)
  const value = useMemo(() => keywords?.map((entry) => entry.getApiId() || "") || [], [keywords])

  const stopEditing = useCallback(() => {
    dataStore.update({ smsKeywords: { $set: baseStore.state.smsKeywords } })
  }, [baseStore, dataStore])

  const saveChanges = useCallback(() => {
    if (doSave) void doSave()
  }, [doSave])

  const hasChanges = useMemo(() => {
    return !!doSave && arraysNotEqual(keywords, baseKeywords)
  }, [baseKeywords, doSave, keywords])

  const endAdornment = hasChanges ? (
    <Fragment>
      <InputAdornment position="end">
        <IconButton edge="end" onClick={saveChanges} color="success">
          <DoneIcon />
        </IconButton>
      </InputAdornment>
      <InputAdornment position="end">
        <IconButton edge="end" onClick={stopEditing} color="error">
          <CloseIcon />
        </IconButton>
      </InputAdornment>
    </Fragment>
  ) : undefined

  const onChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const value = event.target.value
      if (typeof value === "string") return
      dataStore.update({
        smsKeywords: {
          $set: value
            .map((id) => allKeywords.find((entry) => entry.getApiId() === id))
            .filter((entry): entry is GmtKeyword => !!entry),
        },
      })
    },
    [allKeywords, dataStore]
  )
  const renderValue = useCallback(
    (selected: string[]) => (
      <KeywordsChipList
        keywords={selected
          .map((value) => allKeywords.find((entry) => entry.getApiId() === value))
          .filter((value): value is GmtKeyword => !!value)}
        tv={keywordsTv}
        online={keywordsOnline}
      />
    ),
    [allKeywords, keywordsOnline, keywordsTv]
  )
  return (
    <FormControl fullWidth>
      <InputLabel id={CLabelID} required shrink className={doSave ? "inlineMode" : undefined}>
        {CLabel}
      </InputLabel>
      <Select
        label={CLabel}
        labelId={CLabelID}
        multiple
        value={value}
        onChange={onChange}
        disabled={disabled}
        required
        notched
        renderValue={renderValue}
        variant={doSave ? "filled" : undefined}
        endAdornment={endAdornment}
      >
        {allKeywords.map((option) => {
          const online = !!keywordsOnline?.find((entry) => entry.getApiId() === option.getApiId())
          const tv = !!keywordsTv?.find((entry) => entry.getApiId() === option.getApiId())
          return (
            <MenuItem key={option.getApiId()} value={option.getApiId()}>
              <ListItemIcon>{getKeywordIcon(online, tv)}</ListItemIcon>
              <ListItemText>{option.dataStore.state.name}</ListItemText>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
