import { ReactElement, useCallback } from "react"
import { useNavigate } from "react-router-dom"

import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"

import { useStateStore } from "core"
import { IFetchCompetitionsFilter } from "server/logic/competitions"
import { CRouteCompetitionsCreate } from "../routes"
import CompetitionsList from "ui/components/competitions/CompetitionsList"
import CompetitionsFilter from "./CompetitionsFilter"

export default function CompetitionsSearch(): ReactElement {
  const navigate = useNavigate()
  const [filter, filterStore] = useStateStore<IFetchCompetitionsFilter>({
    text: "",
    minStart: null,
    maxStart: null,
    minEnd: null,
    maxEnd: null,
  })
  const createNew = useCallback(() => navigate(CRouteCompetitionsCreate), [navigate])
  return (
    <Paper id="CompetitionsSearch">
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Alle Gewinnspiele
        </Typography>
        <Button variant="contained" onClick={createNew}>
          Gewinnspiel anlegen
        </Button>
      </Toolbar>
      <CompetitionsFilter filterStore={filterStore} />
      <CompetitionsList filter={filter} />
    </Paper>
  )
}
