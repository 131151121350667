import axios from "axios"
import { GmtModel, GmtUser, IJsonApiResponse } from "../model"
import { CBaseUrl } from "../constants"

export async function loadSessionUser(): Promise<GmtUser | null> {
  axios.defaults.withCredentials = true
  try {
    const response = await axios.get<IJsonApiResponse>(GmtModel.jsonApiBaseUrl)
    const userId = response.data.meta?.links?.me?.meta?.id
    if (!userId) return null
    const userResponse = await GmtUser.find(userId)
    return userResponse.getData()
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function loadCsrfToken(): Promise<string> {
  try {
    const sessionTokenResponse = await axios.get(CBaseUrl + "/session/token")
    return sessionTokenResponse.data || ""
  } catch (error) {
    console.error()
    return ""
  }
}
