import { fetchModel, IFetchResult, IFetchSpec } from "./core"
import { ConditionFilterSpec } from "../../coloquent/filter/ConditionFilterSpec"
import { GmtSponsor } from "server/model/GmtSponsor"
import { GmtWps } from "../model"

export async function fetchSponsors(spec: IFetchSpec<IFetchSponsorsFilter>): Promise<IFetchResult<GmtSponsor>> {
  const { pager, filter, sort } = spec
  GmtSponsor.setPageSize(pager.pageSize)
  let query = GmtSponsor.with("")
  if (filter.text) {
    query = query.filter(new ConditionFilterSpec("title", "CONTAINS", filter.text))
  }
  return await fetchModel(query, pager, sort)
}

export interface IFetchSponsorsFilter {
  readonly text?: string
}

export async function fetchWps(spec: IFetchSpec<IFetchWpsFilter>): Promise<IFetchResult<GmtWps>> {
  const { pager, filter, sort } = spec
  GmtSponsor.setPageSize(pager.pageSize)
  let query = GmtWps.with("")
  if (filter.text) {
    query = query.filter(new ConditionFilterSpec("field_wps_number", "CONTAINS", filter.text))
  }
  return await fetchModel(query, pager, sort)
}

export interface IFetchWpsFilter {
  readonly text?: string
}
