/* eslint-disable  @typescript-eslint/no-explicit-any */
import { JsonApiResponseBody } from "../JsonApiResponseBody"
import { Resource } from "../Resource"
import { Model } from "../Model"
import { ToManyRelation } from "../relation/ToManyRelation"
import { ResourceStub } from "../ResourceStub"
import { ToOneRelation } from "../relation/ToOneRelation"
import { Map } from "../util/Map"
import { Response } from "./Response"
import { HttpClientResponse } from "../httpclient/HttpClientResponse"
import { Query } from "../Query"

export abstract class RetrievalResponse<M extends Model, D> extends Response {
  protected data: D
  protected modelType: typeof Model

  protected resourceIndex: Map<Map<Resource>>

  protected modelIndex: Map<Map<M>>

  protected included: M[] = []

  protected constructor(
    query: Query,
    httpClientResponse: HttpClientResponse,
    modelType: typeof Model,
    responseBody: JsonApiResponseBody
  ) {
    super(query, httpClientResponse)
    this.modelType = modelType
    this.resourceIndex = new Map<Map<Resource>>()
    this.modelIndex = new Map<Map<M>>()

    // Index the JsonApiDocs
    this.indexIncludedDocs(responseBody.included)
    this.indexRequestedResources(responseBody.data)

    // Build Models from the JsonApiDocs, for which the previously built indexes come in handy
    this.makeModelIndex(responseBody.data)

    // Prepare arrays for immediate access through this.getData() and this.getIncluded()
    // Does not work within constructor!
    this.data = this.makeDataArray(responseBody.data)
    this.makeIncludedArray(responseBody.included)
  }

  protected abstract makeDataArray(requestedDocs: Resource | Resource[] | null | undefined): D

  public getIncluded(): Model[] {
    return this.included
  }

  protected abstract makeModelIndex(requested: Resource | Resource[] | null | undefined): void

  private indexIncludedDocs(includedDocs: Resource[] = []): void {
    for (const doc of includedDocs) {
      this.indexDoc(doc)
    }
  }

  protected abstract indexRequestedResources(requested: Resource | Resource[] | null | undefined): any

  protected indexDoc(doc: Resource): void {
    const type = doc.type
    const id = doc.id
    let resourceIndexMap = this.resourceIndex.get(type)
    if (!resourceIndexMap) {
      resourceIndexMap = new Map<Resource>()
      this.resourceIndex.set(type, resourceIndexMap)
    }
    resourceIndexMap.set(id, doc)
  }

  protected indexAsModel(
    doc: Resource,
    modelType: typeof Model | ReadonlyArray<typeof Model>,
    includeTree: any
  ): Model {
    const type = doc.type
    const id = doc.id
    let modelIndexMap = this.modelIndex.get(type)
    if (!modelIndexMap) {
      modelIndexMap = new Map<M>()
      this.modelIndex.set(type, modelIndexMap)
    }
    const currentModelType = Array.isArray(modelType)
      ? modelType.find((subType) => subType.effectiveJsonApiType === type)
      : modelType
    if (!currentModelType) {
      throw new Error("Unknown subtype of Relation encountered: " + type)
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const model: M = new (currentModelType as any)()
    model.populateFromResource(doc)
    modelIndexMap.set(id, model)
    for (const resourceRelationName in { ...includeTree, ...doc.relationships }) {
      const modelRelationName = this.convertRelationNameToCamelCase(resourceRelationName)

      if (model[modelRelationName as keyof M] === undefined) {
        continue
      }

      const includeSubtree = includeTree ? includeTree[resourceRelationName] : {}
      const relation = (model[modelRelationName as keyof M] as unknown as () => any)()
      if (relation instanceof ToManyRelation) {
        const relatedStubs: ResourceStub[] | ResourceStub =
          doc.relationships !== undefined && doc.relationships[resourceRelationName] !== undefined
            ? doc.relationships[resourceRelationName].data
            : undefined
        const r: Model[] = []
        if (relatedStubs) {
          const stubsArray = Array.isArray(relatedStubs) ? relatedStubs : [relatedStubs]
          for (const stub of stubsArray) {
            const relatedModel = this.modelIndex.get(stub.type)?.get(stub.id)
            if (relatedModel) {
              r.push(relatedModel)
            } else {
              const relatedDoc = this.resourceIndex.get(stub.type)?.get(stub.id)
              if (relatedDoc) {
                r.push(this.indexAsModel(relatedDoc, relation.getType(), includeSubtree))
              }
            }
          }
        }
        model.setRelation(modelRelationName, r)
      } else if (relation instanceof ToOneRelation) {
        let stub =
          doc.relationships !== undefined && doc.relationships[resourceRelationName] !== undefined
            ? (doc.relationships[resourceRelationName].data as ResourceStub | ResourceStub[])
            : undefined
        let relatedModel: Model | null = null
        stub = Array.isArray(stub) ? stub[0] : stub
        if (stub) {
          relatedModel = this.modelIndex.get(stub.type)?.get(stub.id) || null
          if (!relatedModel) {
            const relatedDoc = this.resourceIndex.get(stub.type)?.get(stub.id)
            if (relatedDoc) {
              relatedModel = this.indexAsModel(relatedDoc, relation.getType(), includeSubtree)
            }
          }
        }
        model.setRelation(modelRelationName, relatedModel)
      } else {
        throw new Error("Unknown type of Relation encountered: " + typeof relation)
      }
    }
    return model
  }

  protected makeIncludedArray(includedDocs: Resource[] = []): void {
    this.included = []
    for (const doc of includedDocs) {
      const model = this.modelIndex.get(doc.type)?.get(doc.id)
      if (model) {
        this.included.push(model)
      }
    }
  }

  protected convertRelationNameToCamelCase(relationName: string): string {
    return relationName.replace(/-\w/g, (m) => m[1].toUpperCase())
  }

  protected static coalesceUndefinedIntoNull<T>(value: T | undefined | null): T | null {
    return value !== undefined ? value : null
  }
}
