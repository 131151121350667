import { GmtKeyword } from "../../model"
import { fetchModel, IFetchResult, IFetchSpec } from "../core"
import { ConditionFilterSpec } from "../../../coloquent/filter/ConditionFilterSpec"

const CAllKeywords: Record<string, GmtKeyword | undefined> = {}

export function getSharedKeywordInstances(list: ReadonlyArray<GmtKeyword> | undefined): ReadonlyArray<GmtKeyword> {
  if (!list?.length) return list || []
  return list.map((entry) => {
    const kw = entry.dataStore.state.name?.trim().toUpperCase()
    if (!kw) throw "Keyword with no name"
    const existing = CAllKeywords[kw]
    if (entry.getApiId()) entry.resetBaseStore()
    if (existing && entry.getApiId() === existing.getApiId()) {
      if (entry.baseStore.state.locked !== existing.baseStore.state.locked) {
        existing.dataStore.update({ locked: { $set: entry.baseStore.state.locked } })
        existing.baseStore.set(existing.dataStore.state)
      }
      return existing
    } else {
      return (CAllKeywords[kw] = entry)
    }
  })
}

export function createKeyword(name: string): GmtKeyword {
  const kw = name.trim().toUpperCase()
  if (!kw) throw "Missing name to create keyword for"
  const existing = CAllKeywords[kw]
  if (existing) return existing
  return (CAllKeywords[kw] = new GmtKeyword().initialize({ name: kw }))
}

export async function fetchKeywords(spec: IFetchSpec<IFetchKeywordsFilter>): Promise<IFetchResult<GmtKeyword>> {
  const { pager, filter, sort } = spec
  GmtKeyword.setPageSize(pager.pageSize)
  let query = GmtKeyword.with([])
  if (filter.text) {
    query = query.filter(new ConditionFilterSpec("name", "CONTAINS", filter.text))
  }
  if (filter.include?.length) {
    query = query.filter(
      new ConditionFilterSpec(
        "id",
        "IN",
        filter.include.map((entry) => (typeof entry === "string" ? entry : entry.getApiId() || ""))
      )
    )
  }
  const rv: IFetchResult<GmtKeyword> = await fetchModel(query, pager, sort)
  return { ...rv, data: getSharedKeywordInstances(rv.data) }
}

export interface IFetchKeywordsFilter {
  readonly text?: string
  readonly include?: ReadonlyArray<GmtKeyword | string>
}
