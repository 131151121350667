export class SortSpec {
  private readonly attribute: string

  private readonly positiveDirection: boolean

  constructor(attribute: string, positiveDirection = true) {
    this.attribute = attribute
    this.positiveDirection = positiveDirection
  }

  getAttribute(): string {
    return this.attribute
  }

  getPositiveDirection(): boolean {
    return this.positiveDirection
  }
}
