import { Fragment, SyntheticEvent, useCallback, useEffect, useState } from "react"

import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"

import { createPromiseTerminator, useStore } from "core"
import { GmtModel, GmtWps } from "server/model"
import { entitiesEqual, fetchWps } from "server/logic"
import { useInlineEditing } from "../forms"

export default function WpsAutoComplete({
  model,
  doSave,
  readOnly,
  required,
}: {
  readonly model: GmtModel<{ readonly wpsNumber: GmtWps | null }>
  readonly doSave?: () => Promise<unknown>
  readonly readOnly?: boolean
  readonly required?: boolean
}): JSX.Element {
  const value = useStore(model.dataStore, (state) => state.wpsNumber)

  const [inputValue, setInputValue] = useState("")
  const [loading, setLoading] = useState(false)

  const { endAdornment, isInline } = useInlineEditing(
    "object",
    model.dataStore,
    "wpsNumber",
    required,
    model.baseStore,
    doSave
  )

  const [sponsors, setSponsors] = useState<ReadonlyArray<GmtWps>>(
    model.dataStore.state.wpsNumber ? [model.dataStore.state.wpsNumber] : []
  )
  useEffect(() => {
    setLoading(true)
    const textValue = inputValue?.startsWith("Neu: ") ? inputValue.substring(5).trim() : inputValue?.trim()
    return createPromiseTerminator(
      fetchWps({ filter: { text: textValue }, sort: { key: "field_wps_number" }, pager: { pageSize: 10, page: 0 } }),
      (result) => {
        const { wpsNumber } = model.dataStore.state
        setSponsors([
          ...(wpsNumber ? [wpsNumber] : []),
          ...((!wpsNumber || wpsNumber.dataStore.state.wpsNummer !== "Neu: " + textValue) &&
          !result.data.length &&
          textValue
            ? [new GmtWps().initialize({ wpsNummer: "Neu: " + textValue })]
            : []),
          ...result.data.filter((entry) => entry.getApiId() !== wpsNumber?.getApiId()),
        ])
        setLoading(false)
      },
      () => {
        setLoading(false)
      }
    )
  }, [inputValue, model.dataStore])

  const onInputChange = useCallback((event: SyntheticEvent, value: string) => {
    setInputValue(value)
  }, [])
  const getOptionLabel = useCallback(
    (option: GmtWps | string) => (typeof option === "string" ? option : option.dataStore.state.wpsNummer) || "k.A.",
    []
  )
  const onChange = useCallback(
    (event: SyntheticEvent, value: GmtWps | null | string) => {
      if (typeof value !== "string") {
        model.dataStore.update({ wpsNumber: { $set: value } })
      }
    },
    [model.dataStore]
  )

  return (
    <Autocomplete
      filterSelectedOptions
      disableClearable={required}
      openOnFocus
      freeSolo
      options={sponsors}
      loading={loading}
      getOptionLabel={getOptionLabel}
      onInputChange={onInputChange}
      isOptionEqualToValue={entitiesEqual}
      value={value || null}
      onChange={onChange}
      disabled={readOnly}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={isInline ? "filled" : "outlined"}
          label="WPS-Nummer"
          InputLabelProps={{ shrink: true }}
          size="small"
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {params.InputProps.endAdornment}
                {endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  )
}
