import { Fragment, ReactElement, useEffect, useMemo } from "react"

import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"

import { IErrors, useStore } from "core"
import { GmtProject, IProjectData, isLineGame, isSCGame } from "server/model"
import { IInlineEditingProps, StoreCheckbox, StoreTextField } from "../forms"

export default function ProjectTechPaper({
  project,
  doSave,
  errors,
}: {
  readonly project: GmtProject
  readonly doSave?: () => Promise<unknown>
  readonly errors?: IErrors<IProjectData>
}): ReactElement {
  const { baseStore, dataStore } = project
  const lineGame = useStore(dataStore, ({ gameMechanics }) => isLineGame(gameMechanics))
  const scGame = useStore(dataStore, ({ gameMechanics }) => isSCGame(gameMechanics))

  // adjust gameMechanics property on change of list of possible game mechanics
  useEffect(() => {
    if (scGame) {
      dataStore.update({ notes: { $set: "Zeitfenster müssen bei Telekom beauftragt werden (Exceltabelle)" } })
    }
  }, [dataStore, scGame])

  const inlineMode = typeof doSave === "function"
  const inlineProps = useMemo<IInlineEditingProps<Partial<IProjectData>>>(
    () => (inlineMode ? { baseStore, doSave } : {}),
    [inlineMode, doSave, baseStore]
  )

  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Tech
        </Typography>
      </Toolbar>
      <Grid container spacing={2} paddingBottom={2}>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            label="Teilnahmewahrscheinlichkeit"
            store={dataStore}
            errors={errors}
            property="probability"
            fullWidth
            required={inlineMode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            label="Dienstkürzel"
            store={dataStore}
            property="serviceCode"
            errors={errors}
            fullWidth
          />
        </Grid>
        {lineGame && (
          <Fragment>
            <Grid item xs={12} md={6}>
              <StoreTextField
                {...inlineProps}
                label="Anzahl Leitungen"
                store={dataStore}
                property="numberOfLines"
                errors={errors}
                type="number"
                fullWidth
                required={inlineMode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StoreTextField
                {...inlineProps}
                label="Gewinnerleitung"
                store={dataStore}
                property="winningLine"
                errors={errors}
                type="number"
                fullWidth
                required={inlineMode}
              />
            </Grid>
          </Fragment>
        )}
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            label="Statistik"
            store={dataStore}
            errors={errors}
            property="statistics"
            fullWidth
            required={inlineMode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            label="Hochrechnungsversand"
            store={dataStore}
            errors={errors}
            property="extrapolation"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <StoreTextField
              {...inlineProps}
              label="Sendefenster"
              store={dataStore}
              property="broadcastWindow"
              errors={errors}
              fullWidth
              required={inlineMode}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            label="Anmerkung"
            store={dataStore}
            property="notes"
            errors={errors}
            fullWidth
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreCheckbox {...inlineProps} errors={errors} label="Voucher" store={dataStore} property="voucher" />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreCheckbox
            {...inlineProps}
            errors={errors}
            label="Cross Selling"
            store={dataStore}
            property="crossSelling"
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
