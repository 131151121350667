import { ReactElement, useState } from "react"

import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"

import NotesIcon from "@mui/icons-material/Notes"

import { GmtPartner } from "server/model"
import IconButton from "@mui/material/IconButton"

export interface IPartnerListRowProps {
  readonly partner: GmtPartner
}

export default function PartnerListRow(props: IPartnerListRowProps): ReactElement {
  const { partner } = props
  const [selected] = useState(false)

  return (
    <TableRow className={selected ? "selected" : undefined}>
      <TableCell>{partner.title}</TableCell>
      <TableCell>
        <IconButton>
          <NotesIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
