import { getObjectProps } from "./utils"

export type IErrors<T extends object> = {
  -readonly [K in keyof T]?: string
} & {
  _?: string
}

export function stripErrors<T extends object>(errors: IErrors<T> | undefined): IErrors<T> | undefined {
  if (!errors) return undefined
  const errorProps = getObjectProps(errors).filter((key) => errors[key])
  if (!errorProps.length) return undefined
  const rv: IErrors<T> = {}
  errorProps.forEach((key) => {
    rv[key] = errors[key]
  })
  return rv
}
