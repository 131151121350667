import { Fragment, ReactElement, useCallback, useState } from "react"

import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"

import { GmtCompetition } from "server/model"
import LoadingButton from "@mui/lab/LoadingButton"
import { useDrawer } from "../../../components/LayoutContext"
import CompetitionWins from "../../../components/competitions/CompetitionWins"

export interface IWinsStepProps {
  readonly onSave: (b?: boolean) => Promise<void>
  readonly goBack: () => void
  readonly competition: GmtCompetition
}

export default function StepThree(props: IWinsStepProps): ReactElement {
  const { onSave, goBack, competition } = props
  const setDrawer = useDrawer()
  const [loading, setLoading] = useState(false)

  const onClick = useCallback(() => {
    setLoading(true)
    setDrawer()
    onSave(false).finally(() => {
      setLoading(false)
    })
  }, [onSave, setDrawer])

  const createCompetition = useCallback(() => {
    setLoading(true)
    onSave(true).finally(() => {
      setLoading(false)
    })
  }, [onSave])

  return (
    <Fragment>
      <CompetitionWins competition={competition} />
      <Stack direction="row" spacing={3}>
        <Button variant="outlined" onClick={goBack}>
          Zurück
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <LoadingButton loading={loading} variant="contained" onClick={onClick}>
          Speichern
        </LoadingButton>
        <LoadingButton variant="contained" loading={loading} onClick={createCompetition}>
          Speichern und weiteres anlegen
        </LoadingButton>
      </Stack>
    </Fragment>
  )
}
