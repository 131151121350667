import { ReactElement, ReactNode, useCallback, useMemo } from "react"

import Grid from "@mui/material/Grid"

import { GmtCashPrice, ICashPriceData } from "server/model"
import { moneyValueTransformer } from "../../utils"
import { IInlineEditingProps, StoreCheckbox, StoreTextField } from "../forms"
import SponsorAutoComplete from "../sponsors/SponsorAutoComplete"

export default function CashPricePanel({
  price,
  readOnly,
  children,
}: {
  readonly price: GmtCashPrice
  readonly readOnly?: boolean
  readonly children?: ReactNode
}): ReactElement {
  const { baseStore, dataStore } = price

  const doSave = useCallback((): Promise<unknown> => {
    return price.save().catch((error) => {
      console.error(error)
    })
  }, [price])

  const inlineMode = !!price.getApiId()
  const inlineProps = useMemo<IInlineEditingProps<Partial<ICashPriceData>>>(
    () => (inlineMode ? { baseStore, doSave } : {}),
    [inlineMode, doSave, baseStore]
  )

  return (
    <Grid container spacing={2} marginTop={children ? 2 : undefined}>
      <Grid item xs={6}>
        <StoreTextField
          {...inlineProps}
          label="Betrag"
          store={dataStore}
          property="winningAmount"
          fullWidth
          required
          disabled={readOnly}
          inputTransformer={moneyValueTransformer}
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <StoreCheckbox
          {...inlineProps}
          label="Blitzüberweisung"
          store={dataStore}
          property="lightningTransfer"
          disabled={readOnly}
          fullWidth
          small
        />
      </Grid>
      <Grid item xs={12}>
        <SponsorAutoComplete model={price} doSave={inlineMode ? doSave : undefined} readOnly={readOnly} />
      </Grid>
      {children}
    </Grid>
  )
}
