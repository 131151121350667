import { ReactElement } from "react"

import Tooltip from "@mui/material/Tooltip"
import Chip from "@mui/material/Chip"

import { useStore } from "core"
import { GmtProject } from "server/model"
import SenderImage from "../sender/SenderImage"

export interface IProjectTooltipProps {
  readonly project: GmtProject
}

export default function ProjectLabel(props: IProjectTooltipProps): ReactElement {
  const { project } = props
  const { sender, title } = useStore(project.dataStore)
  return sender ? (
    <SenderImage sender={sender} tooltip />
  ) : (
    <Tooltip title={title || ""}>
      <Chip label={title || ""} />
    </Tooltip>
  )
}
