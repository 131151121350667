import { ReactElement, useCallback } from "react"
import { FORM_ERROR, ValidationErrors } from "final-form"

import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import CircularProgress from "@mui/material/CircularProgress"

import LoginIcon from "@mui/icons-material/Login"

import { useStore } from "core"
import { ILoginRequest, login } from "server/logic"
import { CSessionState, initSessionState } from "../state"
import { GmtForm, GmtFormError, FormSubmitButton, FormTextField } from "../components/forms"

import "./LoginPage.scss"
import { CBaseUrl } from "../../server/constants"

export default function LoginPage(): ReactElement {
  const sessionState = useStore(CSessionState, (state) => state.state)
  const onSubmit = useCallback(async (data: ILoginRequest): Promise<ValidationErrors | void> => {
    try {
      await login(data)
      await initSessionState()
    } catch (error) {
      return { [FORM_ERROR]: error instanceof Error ? error.message : (error as object).toString() }
    }
  }, [])
  const validate = useCallback((data: ILoginRequest): ValidationErrors => {
    return {
      name: data.name?.trim() ? undefined : "missing",
      pass: data.pass ? undefined : "missing",
    }
  }, [])
  return (
    <Grid id="LoginPage" container direction="column" alignItems="center" justifyContent="center">
      <Grid item xs={3}>
        <Paper>
          {sessionState === "initializing" ? (
            <CircularProgress className="InitLoader" />
          ) : (
            <GmtForm useHtmlForm onSubmit={onSubmit} validate={validate}>
              <Stack spacing={2} padding={2}>
                <Typography variant="h6" component="h6">
                  Login
                </Typography>
                <GmtFormError />
                <FormTextField fieldName="name" label="user" />
                <FormTextField fieldName="pass" label="password" type="password" />
                <FormSubmitButton variant="contained" loadingPosition="start" startIcon={<LoginIcon />}>
                  Login
                </FormSubmitButton>
                <a href={CBaseUrl + "/user/password"}>Passwort vergessen</a>
              </Stack>
            </GmtForm>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}
