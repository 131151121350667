/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { IFilterSpec } from "./IFilterSpec"
import { SortSpec } from "./SortSpec"
import { Option } from "./Option"
import { PaginationSpec } from "./paginationspec/PaginationSpec"
import { QueryParam } from "./QueryParam"

export class Query {
  protected jsonApiType: string

  protected jsonApiId: string | undefined

  protected queriedRelationName: string | undefined

  protected idToFind: string | number = 0

  protected paginationSpec: PaginationSpec | undefined

  protected include: string[]

  protected filters: IFilterSpec[]

  protected options: Option[]

  protected sort: SortSpec[]

  protected limit: number | undefined

  constructor(
    jsonApiType: string,
    queriedRelationName: string | undefined = undefined,
    jsonApiId: string | undefined = undefined
  ) {
    this.jsonApiType = jsonApiType
    this.jsonApiId = jsonApiId
    this.queriedRelationName = queriedRelationName
    this.include = []
    this.filters = []
    this.options = []
    this.sort = []
  }

  protected addFilterParameters(searchParams: QueryParam[]): void {
    this.filters
      .map((filter, index) => filter.getQueryParams((index + 1).toString()))
      .flat()
      .forEach((query) => searchParams.push(query))
  }

  protected addIncludeParameters(searchParams: QueryParam[]): void {
    if (this.include.length > 0) {
      let p = ""
      for (const incl of this.include) {
        if (p !== "") {
          p += ","
        }
        p += incl
      }
      searchParams.push(new QueryParam("include", p))
    }
  }

  protected addOptionsParameters(searchParams: QueryParam[]): void {
    for (const option of this.options) {
      searchParams.push(new QueryParam(option.getParameter(), option.getValue()))
    }
  }

  protected addPaginationParameters(searchParams: QueryParam[]): void {
    for (const param of this.paginationSpec!.getPaginationParameters()) {
      searchParams.push(new QueryParam(param.name, param.value))
    }
  }

  protected addSortParameters(searchParams: QueryParam[]): void {
    if (this.sort.length > 0) {
      let p = ""
      for (const sortSpec of this.sort) {
        if (p !== "") {
          p += ","
        }
        if (!sortSpec.getPositiveDirection()) {
          p += "-"
        }
        p += sortSpec.getAttribute()
      }
      searchParams.push(new QueryParam("sort", p))
    }
  }

  public toString(): string {
    const relationToFind = !this.jsonApiId
      ? this.queriedRelationName
        ? "/" + this.queriedRelationName
        : ""
      : this.queriedRelationName
      ? "/" + this.jsonApiId + "/" + this.queriedRelationName
      : ""

    const idToFind: string = this.idToFind ? "/" + this.idToFind : ""

    const searchParams: QueryParam[] = []
    this.addFilterParameters(searchParams)
    this.addIncludeParameters(searchParams)
    this.addOptionsParameters(searchParams)
    this.addPaginationParameters(searchParams)
    this.addSortParameters(searchParams)
    let paramString = ""
    for (const searchParam of searchParams) {
      if (paramString === "") {
        paramString += "?"
      } else {
        paramString += "&"
      }
      paramString += encodeURIComponent(searchParam.name) + "=" + encodeURIComponent(searchParam.value)
    }

    return this.jsonApiType + relationToFind + idToFind + paramString
  }

  public getJsonApiType(): string {
    return this.jsonApiType
  }

  public getJsonApiId(): string | undefined {
    return this.jsonApiId
  }

  public getQueriedRelationName(): string | undefined {
    return this.queriedRelationName
  }

  public setIdToFind(idToFind: string | number): void {
    this.idToFind = idToFind
  }

  public getPaginationSpec(): PaginationSpec {
    return this.paginationSpec!
  }

  public setPaginationSpec(paginationSpec: PaginationSpec): void {
    this.paginationSpec = paginationSpec
  }

  public addInclude(includeSpec: string): void {
    this.include.push(includeSpec)
  }

  getInclude(): string[] {
    return this.include
  }

  public addFilter(filter: IFilterSpec): void {
    this.filters.push(filter)
  }

  public getFilters(): IFilterSpec[] {
    return this.filters
  }

  public addSort(sort: SortSpec): void {
    this.sort.push(sort)
  }

  public getSort(): SortSpec[] {
    return this.sort
  }

  public addOption(option: Option): void {
    this.options.push(option)
  }

  public getOptions(): Option[] {
    return this.options
  }

  public setLimit(limit: number): void {
    this.limit = limit
  }

  public getLimit(): number | undefined {
    return this.limit
  }

  /**
   * Example: When including 'foo.bar, goo', then the include paths are [[foo, bar], [goo]].
   */
  private get includePaths(): string[][] {
    return this.include.map((includePath) => includePath.split("."))
  }

  /**
   * Example: When including 'foo.bar, goo', then the include tree is {foo: {bar: true}, goo: true}.
   */
  public get includeTree(): any {
    const tree = {}
    for (const path of this.includePaths) {
      this.includeTreeRecurse(tree, path)
    }
    return tree
  }

  private includeTreeRecurse(tree: any, path: string[]): void {
    if (path.length === 1) {
      tree[path[0]] = {}
    } else if (path.length > 1) {
      const subtree = {}
      tree[path[0]] = subtree
      this.includeTreeRecurse(subtree, path.slice(1))
    }
  }
}
