import { ReactElement, useEffect, useState } from "react"
import format from "date-fns/format"
import de from "date-fns/locale/de"

import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"

import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"

import { createPromiseTerminator, useStore } from "core"
import { CParticipationTypeNames, GmtProject, isLineGame } from "server/model"
import { fetchProject } from "server/logic/projects"
import SenderInfoBox from "../sender/SenderInfoBox"
import UserChip from "../shared/UserChip"
import DetailsTable from "../shared/DetailsTable"

export default function ProjectDetailsDrawer({ project }: { readonly project: GmtProject }): ReactElement {
  const [reloadedProject, setReloadedProject] = useState(project)
  const data = useStore(reloadedProject.dataStore)

  useEffect(
    () =>
      createPromiseTerminator(fetchProject(project.getApiId() || ""), (result) =>
        setReloadedProject(result || project)
      ),
    [project]
  )

  return (
    <Stack spacing={2}>
      <TableContainer component={Paper}>
        <DetailsTable
          data={[
            { title: "Title", value: data.title },
            { title: "Sender", value: data.sender && <SenderInfoBox sender={data.sender} /> },
            { title: "Teilnahmeweg", value: data.participationType && CParticipationTypeNames[data.participationType] },
            { title: "Spielmechanik", value: data.gameMechanics?.name },
            { title: "Service-ID", value: data.sendingId },
            { title: "Promams ID", value: data.promamsId },
            { title: "Promams Titel", value: data.promamsTitle },
            { title: "Kreditorenschlüssel", value: data.creditorKey },
            {
              title: "Projekt inaktiv am",
              value: data.projectInactiveOn && format(data.projectInactiveOn, "P, p", { locale: de }),
            },
            {
              title: "Projektverantwortliche",
              value: data.projectManager?.map((user) => <UserChip key={user.getApiId()} user={user} />),
            },
          ]}
        />
      </TableContainer>
      <TableContainer component={Paper}>
        <DetailsTable
          data={[
            { title: "Teilnahmewahrscheinlichkeit", value: data.probability },
            ...(isLineGame(data.gameMechanics)
              ? [
                  { title: "Anzahl Leitungen", value: data.numberOfLines },
                  { title: "Gewinnerleitung", value: data.winningLine },
                ]
              : []),
            { title: "Statistik", value: data.statistics },
            { title: "Sendefenster", value: data.broadcastWindow },
            { title: "Dienstkürzel", value: data.serviceCode },
            { title: "Hochrechnungsversand", value: data.extrapolation },
            {
              title: "Voucher",
              value: data.voucher ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
            },
            {
              title: "Cross Selling",
              value: data.crossSelling ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
            },
            { title: "Anmerkung", value: data.notes },
          ]}
        />
      </TableContainer>
    </Stack>
  )
}
