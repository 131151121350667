import { Fragment, ReactElement, useMemo } from "react"

import { concatArrays } from "core"
import { GmtKeyword } from "server/model"
import { entitiesEqual } from "server/logic"
import KeywordChip from "./KeywordChip"

export interface IKeywordsChipListProps {
  readonly online: ReadonlyArray<GmtKeyword> | undefined
  readonly tv: ReadonlyArray<GmtKeyword> | undefined
  readonly keywords?: ReadonlyArray<GmtKeyword>
}

export default function KeywordsChipList(props: IKeywordsChipListProps): ReactElement {
  const { tv, online, keywords } = props
  const allEntries = useMemo(() => keywords || concatArrays(tv, online, entitiesEqual), [keywords, tv, online])
  return (
    <Fragment>
      {allEntries.map((entry) => (
        <KeywordChip
          key={entry.getApiId()}
          value={entry}
          online={!!online?.includes(entry)}
          tv={!!tv?.includes(entry)}
        />
      ))}
    </Fragment>
  )
}
