import { Fragment, ReactElement } from "react"
import format from "date-fns/format"
import de from "date-fns/locale/de"

import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"

import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"

import { useStore } from "core"
import { GmtWinner } from "server/model"

export interface IWinnerEventsProps {
  readonly winner: GmtWinner
}

export default function WinnerEvents(props: IWinnerEventsProps): ReactElement {
  const { winner } = props
  const { notified, published, effortRecorded, accountClosureRecorded } = useStore(winner.dataStore)

  const events = [
    {
      title: "Gewinner benachrichtigt",
      date: notified,
    },
    {
      title: "Gewinner veröffentlicht",
      date: published,
    },
    {
      title: "Preis im Aufwandsreport",
      date: effortRecorded,
    },
    {
      title: "Preis im Kontoabschluss-Report",
      date: accountClosureRecorded,
    },
  ]

  return (
    <Stack direction="row" spacing={3}>
      {events.map((event, idx) => (
        <Paper key={idx} elevation={3} sx={{ flexGrow: 1, flexBasis: "25%" }}>
          <Typography variant="caption" mt={1} display="block" gutterBottom>
            {event.title}
          </Typography>
          <Typography color={"green"} variant="inherit" gutterBottom component="div" width={"100%"}>
            {event.date ? (
              <Fragment>
                {format(event.date, "P, p", { locale: de })}{" "}
                <CheckCircleIcon
                  sx={{ verticalAlign: "text-top", width: "0.875rem", height: "0.875rem", fontSize: "0.875rem" }}
                />
              </Fragment>
            ) : (
              <CancelIcon
                color={"warning"}
                sx={{ verticalAlign: "text-top", width: "0.875rem", height: "0.875rem", fontSize: "0.875rem" }}
              />
            )}
          </Typography>
        </Paper>
      ))}
    </Stack>
  )
}
