import { ReactElement, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"

import { createPromiseTerminator, IErrors, useStore } from "core"
import { GmtCompetition, ICompetitionData } from "server/model"
import { fetchCompetition, saveCompetition } from "server/logic/competitions"
import CompetitionStats from "../../../components/competitions/CompetitionStats"
import CompetitionEvents from "../../../components/competitions/CompetitionEvents"
import CompetitionWins from "../../../components/competitions/CompetitionWins"
import CompetitionDetailsPaper from "../../../components/competitions/CompetitionDetailsPaper"
import LoadingIndicator from "../../../components/shared/LoadingIndicator"
import ProjectsListPaper from "../../../components/competitions/ProjectsListPaper"
import CompetitionHistory from "./CompetitionHistory"
import CompetitionButtons from "./CompetitionButtons"
import { useMessages } from "../../../components/LayoutContext"
import Typography from "@mui/material/Typography"
import DocumentsPanel from "../../../components/shared/DocumentsPanel"

export default function CompetitionDetails(): ReactElement {
  const { compId } = useParams()
  const setMessage = useMessages()

  const [errors, setErrors] = useState<IErrors<ICompetitionData>>()
  const [comp, setComp] = useState<GmtCompetition | null>()

  const dataStore = useStore(comp?.dataStore) || {}

  useEffect(() => {
    if (!compId || typeof comp !== "undefined") return
    return createPromiseTerminator(fetchCompetition(compId), setComp)
  }, [comp, compId])

  const doSave = useCallback((): Promise<unknown> => {
    setErrors(undefined)
    if (!comp) return Promise.reject("Internal state error: Missing competition!")
    return saveCompetition(comp).catch((errors) => {
      setErrors(errors)
      if (errors._) {
        setMessage({
          message: errors._,
          severity: "error",
        })
      }
    })
  }, [comp, setMessage])

  const doReload = useCallback(() => {
    setComp(undefined)
  }, [])

  return (
    <Container maxWidth={false} className="marginBottom">
      {comp ? (
        <Stack spacing={3}>
          <CompetitionStats comp={comp} />
          <Stack direction="row" my={3} spacing={0}>
            <Typography variant="h5" component="h1">
              {dataStore.title}
            </Typography>
          </Stack>
          <CompetitionEvents comp={comp} />
          <CompetitionDetailsPaper competition={comp} doSave={doSave} errors={errors} />
          <ProjectsListPaper competition={comp} doSave={doSave} />
          <CompetitionWins competition={comp} showWinner={true} />
          <CompetitionButtons comp={comp} setErrors={setErrors} />
          <DocumentsPanel comp={comp} doReload={doReload} endpoint={GmtCompetition.endpoint} />
          <CompetitionHistory comp={comp} />
        </Stack>
      ) : (
        <LoadingIndicator />
      )}
    </Container>
  )
}
