import { IFilterSpec } from "../IFilterSpec"
import { QueryParam } from "../QueryParam"

export class SimpleFilterSpec implements IFilterSpec {
  readonly attribute: string

  readonly value: string | boolean

  constructor(attribute: string, value: string | boolean) {
    this.attribute = attribute
    this.value = value
  }

  getQueryParams(): ReadonlyArray<QueryParam> {
    return [new QueryParam(`filter[${this.attribute}]`, this.value)]
  }
}
