import { ReactElement } from "react"

import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"

import CompetitionsSearch from "./CompetitionsSearch"

export default function CompetitionsOverview(): ReactElement {
  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CompetitionsSearch />
        </Grid>
      </Grid>
    </Container>
  )
}
