import { ReactElement, useCallback } from "react"

import Tooltip from "@mui/material/Tooltip"
import Chip from "@mui/material/Chip"

import LiveTvIcon from "@mui/icons-material/LiveTv"
import LanguageIcon from "@mui/icons-material/Language"

import { GmtServiceNumber } from "server/model"
import { useStore } from "core"

export default function ServiceNumberChip({
  value,
  online,
  onDelete,
}: {
  readonly value: GmtServiceNumber
  readonly online: boolean
  readonly onDelete?: (value: GmtServiceNumber) => void
}): ReactElement {
  const { name, locked } = useStore(value.dataStore)
  const handleDelete = useCallback(() => {
    onDelete && onDelete(value)
  }, [onDelete, value])

  return (
    <Tooltip title={name || "k.A."}>
      <Chip
        label={name || "k.A."}
        className={locked ? "red" : "green"}
        icon={online ? <LanguageIcon fontSize="small" /> : <LiveTvIcon fontSize="small" />}
        onDelete={onDelete && handleDelete}
      />
    </Tooltip>
  )
}
