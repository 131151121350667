import { ReactElement } from "react"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"

export default function LoadingIndicator(): ReactElement {
  return (
    <Box height={100} padding={3}>
      <Box width={60} height={60} m="auto">
        <CircularProgress />
      </Box>
    </Box>
  )
}
