import { Fragment, ReactElement, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import Drawer from "@mui/material/Drawer"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Collapse from "@mui/material/Collapse"
import ListItem from "@mui/material/ListItem"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import { SvgIconTypeMap } from "@mui/material/SvgIcon"

import DashboardIcon from "@mui/icons-material/Dashboard"
import TocIcon from "@mui/icons-material/Toc"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import WinnerMenuIcon from "ui/components/icons/WinnerIcon"
import CompetitionMenuIcon from "ui/components/icons/CompetitionIcon"
import LogoIcon from "ui/components/icons/LogoIcon"

import { CRouteCoreData, CRouteCompetitions, CRouteDashboard, CRouteWinners, CRouteProjects } from "../../routes"
import { CRouteCompetitionsCreate } from "../../pages/competitions/routes"
import { CRoutePartners, CRouteSponsors } from "../../pages/projects/routes"
import { CRouteWinnerDrawing } from "../../pages/winners/routes"
import { useLayoutContextAndDispatch } from "../LayoutContext"

import "./SideBar.scss"

const CMenu: ReadonlyArray<IMainMenuEntry> = [
  {
    id: CRouteDashboard,
    title: "Dashboard",
    route: CRouteDashboard,
    icon: DashboardIcon,
  },
  {
    id: CRouteCompetitions,
    title: "Gewinnspiele",
    route: CRouteCompetitions,
    icon: CompetitionMenuIcon,
    subs: [
      { id: "all", title: "Alle Gewinnspiele", route: CRouteCompetitions },
      { id: "create", title: "Gewinnspiel anlegen", route: CRouteCompetitions + "/" + CRouteCompetitionsCreate },
    ],
  },
  {
    id: CRouteWinners,
    title: "Gewinner",
    route: CRouteWinners,
    icon: WinnerMenuIcon,
    subs: [
      { id: "all", title: "Alle Gewinner", route: CRouteWinners },
      { id: "drawing", title: "Gewinnerziehung", route: CRouteWinners + "/" + CRouteWinnerDrawing },
    ],
  },
  {
    id: CRouteCoreData,
    title: "Stammdaten",
    route: CRouteProjects,
    icon: TocIcon,
    subs: [
      { id: "projects", title: "Projekte", route: CRouteProjects },
      { id: "sponsors", title: "Sponsoren", route: CRouteCoreData + "/" + CRouteSponsors },
      { id: "partner", title: "Partner", route: CRouteCoreData + "/" + CRoutePartners },
    ],
  },
]

export default function SideBar(): ReactElement {
  const navigate = useNavigate()
  const location = useLocation()
  const [openEntry, setOpenEntry] = useState<IMainMenuEntry>()
  const [context, setContext] = useLayoutContextAndDispatch()
  return (
    <Drawer id="SideBar" variant="permanent" className={context.sideBarOpen ? "SideBarOpen" : undefined}>
      <Toolbar>
        <List>
          <ListItem>
            <ListItemIcon>
              <LogoIcon sx={{ fontSize: 32 }} className="siteLogo" />
            </ListItemIcon>
            <ListItemText primary="GMT" />
          </ListItem>
        </List>
      </Toolbar>
      <List component="nav">
        {CMenu.map((menu) => {
          const isSelected = location.pathname.startsWith("/" + menu.route)
          return (
            <Fragment key={menu.id}>
              <ListItemButton
                sx={{ minHeight: 56 }}
                onClick={() => {
                  if (menu.subs && context.sideBarOpen) setOpenEntry(openEntry === menu ? undefined : menu)
                  else navigate("/" + menu.route)
                }}
                selected={isSelected}
                className={menu.subs && openEntry === menu ? " collapseOpen" : ""}
              >
                <ListItemIcon>
                  <menu.icon className={"menuIcon" + (isSelected ? " selected" : "")} />
                </ListItemIcon>
                <ListItemText primary={menu.title} />
                {menu.subs && context.sideBarOpen && (openEntry === menu ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
              {menu.subs && context.sideBarOpen && (
                <Collapse in={openEntry === menu} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {menu.subs.map((subEntry) => (
                      <ListItemButton
                        key={subEntry.id}
                        className="SubEntry"
                        onClick={() => {
                          setContext((prevState) => ({ ...prevState, sideBarOpen: false }))
                          navigate("/" + subEntry.route)
                        }}
                      >
                        <ListItemText primary={subEntry.title} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </Fragment>
          )
        })}
      </List>
    </Drawer>
  )
}

interface IMainMenuEntry extends IMenuEntry {
  readonly icon: OverridableComponent<SvgIconTypeMap>
  readonly subs?: ReadonlyArray<IMenuEntry>
}

interface IMenuEntry {
  readonly id: string
  readonly title: string
  readonly route: string
}
