import { IErrors, stripErrors } from "core"
import { GmtCashPrice, GmtMaterialPrice, GmtUser, GmtWinner, IGmtWinnerData } from "../../model"

export function validateWinnerData(data: Partial<IGmtWinnerData>): IErrors<IGmtWinnerData> | undefined {
  return stripErrors<IGmtWinnerData>({
    title: data.title?.trim() ? "" : "missing",
  })
}

export async function createWinner(winner: GmtWinner): Promise<GmtWinner | null> {
  const { win } = winner.dataStore.state
  if (!win) throw "Missing win in winner"
  const response = await winner.save()
  win.dataStore.update({ winner: { $set: winner } })
  await win.save()
  return response.getModel()
}

export async function setWinnerComplete(user: GmtUser, winner: GmtWinner): Promise<ReadonlyArray<string>> {
  const { winnerStatus, bankData, win, address, salutation, mail, mobileNumber, phoneNumber } = winner.dataStore.state

  if (!win) throw "Internal Error: missing win property on winner"
  if (winnerStatus !== "Benachrichtigt" && winnerStatus !== "Gezogen") {
    throw "Internal Error: winner is not in state 'Benachrichtigt'"
  }

  const rv: Array<string> = []
  if (!winner.getApiId() || winner.hasChanges() || bankData?.hasChanges()) {
    rv.push("Es gibt ungespeicherte Änderungen")
    return rv // quick exit
  }

  if (!mail?.trim() && !mobileNumber?.trim() && !phoneNumber?.trim()) {
    rv.push("Entweder Email oder Telefonnummer müssen angegeben sein")
  }

  const isCashPrice = !!win.dataStore.state.prices?.find((price) => price instanceof GmtCashPrice)
  const isProductPrice = !!win.dataStore.state.prices?.find(
    (price) => price instanceof GmtMaterialPrice && price.dataStore.state.product
  )

  if (isCashPrice) {
    if (!bankData) rv.push("Bankdaten fehlen")
    else if (!bankData.dataStore.state.validated) rv.push("Bankdaten sind nicht geprüft")
  }
  if (isProductPrice || isCashPrice) {
    if (!address) rv.push("Adressdaten fehlen")
    else {
      const { given_name, family_name, address_line1, locality, postal_code } = address
      if (!salutation?.trim()) rv.push("Anrede fehlt")
      if (!given_name?.trim()) rv.push("Vorname fehlt")
      if (!family_name?.trim()) rv.push("Nachname fehlt")
      if (!address_line1?.trim()) rv.push("Straße/Hausnummer fehlt")
      if (!postal_code?.trim()) rv.push("Postleitzahl fehlt")
      if (!locality?.trim()) rv.push("Ort fehlt")
    }
  }

  // TODO other validations

  if (!rv.length) {
    winner.dataStore.update({ winnerStatus: { $set: "Vollständig" }, completedBy: { $set: user } })
    try {
      await winner.save()
    } catch (error) {
      winner.dataStore.update({ winnerStatus: { $set: "Benachrichtigt" }, completedBy: { $set: undefined } })
      throw error
    }
  }

  return rv
}

export async function setWinnerBackComplete(user: GmtUser, winner: GmtWinner): Promise<ReadonlyArray<string>> {
  const { winnerStatus, bankData, win } = winner.dataStore.state

  if (!win) throw "Internal Error: missing win property on winner"
  if (winnerStatus !== "Geprüft") {
    throw "Internal Error: winner is not in state 'Geprüft'"
  }

  const rv: Array<string> = []
  if (!winner.getApiId() || winner.hasChanges() || bankData?.hasChanges()) {
    rv.push("Es gibt ungespeicherte Änderungen")
    return rv // quick exit
  }

  if (!rv.length) {
    winner.dataStore.update({
      winnerStatus: { $set: "Benachrichtigt" },
      completedBy: { $set: user },
      materialPriceConversion: { $set: true },
    })
    try {
      await winner.save()
    } catch (error) {
      winner.dataStore.update({ winnerStatus: { $set: "Geprüft" }, completedBy: { $set: undefined } })
      throw error
    }
  }

  return rv
}
