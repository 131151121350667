import { ReactElement } from "react"

import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Chip from "@mui/material/Chip"

import { Store, useStore } from "core"
import { GmtCompetition, GmtWin } from "server/model"
import ListTable, { IColumnSpec } from "../../../components/shared/ListTable"
import { getPriceLabel } from "../../../components/shared/WinChip"

const CColumns: ReadonlyArray<IColumnSpec> = [
  {
    key: "title",
    title: "Bezeichnung",
  },
  {
    key: "prices",
    title: "Preis(e)",
  },
  {
    key: "actions",
    title: "Ziehung",
    align: "right",
  },
]

export interface IWinsPanelProps {
  readonly competition: GmtCompetition
  readonly actionCell: (win: GmtWin) => ReactElement
  readonly selectedStore: Store<{ readonly selected?: GmtWin }>
}

export default function WinsPanel(props: IWinsPanelProps): ReactElement {
  const { competition, ...rest } = props
  const { wins } = useStore(competition.dataStore)
  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Gewinne
        </Typography>
      </Toolbar>
      <ListTable
        data={wins}
        columns={CColumns}
        className="marginBottom"
        renderRow={(entry) => <WinListRow {...rest} key={entry.getApiId()} win={entry} />}
      />
    </Paper>
  )
}

function WinListRow(props: {
  readonly win: GmtWin
  readonly actionCell?: (win: GmtWin) => ReactElement
  readonly selectedStore: Store<{ readonly selected?: GmtWin }>
}): ReactElement {
  const { win, actionCell, selectedStore } = props
  const { title, prices } = useStore(win.dataStore)
  const selected = useStore(selectedStore, (state) => state.selected === win, [win])
  return (
    <TableRow className={selected ? "selected" : undefined}>
      <TableCell>{title}</TableCell>
      <TableCell>
        {prices?.map((entry) => (
          <Chip key={entry.getApiId()} label={getPriceLabel(entry)} />
        ))}
      </TableCell>
      {actionCell ? <TableCell align="right">{actionCell(win)}</TableCell> : null}
    </TableRow>
  )
}
