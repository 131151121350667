import { ReactElement, useEffect } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"

import { CRouteWinnerDetailsID, CRouteWinnerDrawing, CRouteWinnerDrawingID } from "./routes"
import WinnersOverview from "./WinnersOverview"
import WinnerDetails from "./WinnerDetails"
import EndedCompetitions from "./EndedCompetitions"
import DrawWinner from "./DrawWinner"

export default function WinnersRouter(): ReactElement {
  return (
    <Routes>
      <Route index element={<WinnersOverview />} />
      <Route path={CRouteWinnerDrawing} element={<EndedCompetitions />} />
      <Route path={CRouteWinnerDrawingID} element={<DrawWinner />} />
      <Route path={CRouteWinnerDetailsID} element={<WinnerDetails />} />
      <Route path="*" element={<RedirectAll />} />
    </Routes>
  )

  function RedirectAll(): null {
    const navigate = useNavigate()
    useEffect(() => {
      navigate("", { replace: true })
    })
    return null
  }
}
