import {ToManyRelation, ToOneRelation} from "../../coloquent"
import {GmtModel} from "./core"
import {TParticipationType} from "./shared"
import {GmtSender} from "./GmtSender"
import {GmtUser} from "./GmtUser"
import {GmtGameMechanics} from "./GmtGameMechanics"
import {GmtServiceNumber} from "./GmtServiceNumber"
import {GmtKeyword} from "./GmtKeyword"
import {GmtFile} from "./GmtFile"
import {RaasClient} from "./RaasClient";

export const CProjectStates = ["0", "1", "2"] as const

export type TProjectState = (typeof CProjectStates)[number]

export const CProjectStateNames: Readonly<Record<TProjectState, string>> = {
  "0": "Entwurf", "1": "Reserviert", "2": "Aktiv",
}

export interface IProjectData {
  readonly broadcastWindow: string | null
  readonly creditorKey: string | null
  readonly crossSelling: boolean
  readonly extrapolation: string | null
  readonly notes: string | null
  readonly numberOfLines: number | null
  readonly participationType: TParticipationType
  readonly probability: string | null
  readonly projectInactiveOn: Date | null
  readonly promamsId: string | null
  readonly promamsTitle: string | null
  readonly sendingId: number | null
  readonly serviceCode: string | null
  readonly state: TProjectState
  readonly statistics: string | null
  readonly title: string
  readonly voucher: boolean
  readonly winningLine: number | null
  readonly clientTitle:string | null
  // to-one-relations
  readonly gameMechanics: GmtGameMechanics | null
  readonly sender: GmtSender | null
  readonly client: ReadonlyArray<RaasClient>
  // to-many-relations
  readonly keywordsOnline: ReadonlyArray<GmtKeyword>
  readonly keywordsTv: ReadonlyArray<GmtKeyword>
  readonly projectManager: ReadonlyArray<GmtUser>
  readonly serviceNumbersOnline: ReadonlyArray<GmtServiceNumber>
  readonly serviceNumbersTv: ReadonlyArray<GmtServiceNumber>
  readonly documents: ReadonlyArray<GmtFile>
  readonly nid: number
}

export class GmtProject extends GmtModel<IProjectData> {
  static jsonApiType = "node--gmt_project"
  static endpoint = "node/gmt_project"

  static dates = ["field_project_inactive_on"]

  static attMapping: Readonly<Record<string, keyof IProjectData>> = {
    title: "title",
    field_status: "state",
    field_participation_route: "participationType",
    field_creditor_key: "creditorKey",
    field_sendungs_id: "sendingId",
    field_promams_id: "promamsId",
    field_promams_title: "promamsTitle",
    field_project_inactive_on: "projectInactiveOn",
    field_broadcast_window: "broadcastWindow",
    field_participation_probability: "probability",
    field_note: "notes",
    field_statistik: "statistics",
    field_extrapolation_shipping: "extrapolation",
    field_voucher: "voucher",
    field_cross_selling: "crossSelling",
    field_number_of_lines: "numberOfLines",
    field_winning_line: "winningLine",
    field_service_code: "serviceCode",
    drupal_internal__nid: "nid",
  }
  static relMapping: Readonly<Record<string, keyof IProjectData>> = {
    field_project_manager: "projectManager",
    field_sender: "sender",
    field_game_mechanics: "gameMechanics",
    field_service_number_online: "serviceNumbersOnline",
    field_service_number_tv: "serviceNumbersTv",
    field_sms_keywords_tv: "keywordsTv",
    field_sms_keywords_online: "keywordsOnline",
    field_documents: "documents",
    field_client_ref: "client",
  }

  nid(): number {
    return this.getAttribute("drupal_internal__nid")
  }

  field_sender(): ToOneRelation<GmtSender, this> {
    return this.hasOne(GmtSender)
  }

  field_project_manager(): ToManyRelation<GmtUser, this> {
    return this.hasMany(GmtUser)
  }

  field_game_mechanics(): ToOneRelation<GmtGameMechanics, this> {
    return this.hasOne(GmtGameMechanics)
  }

  field_service_number_online(): ToManyRelation<GmtServiceNumber, this> {
    return this.hasMany(GmtServiceNumber)
  }

  field_service_number_tv(): ToManyRelation<GmtServiceNumber, this> {
    return this.hasMany(GmtServiceNumber)
  }

  field_sms_keywords_tv(): ToManyRelation<GmtKeyword, this> {
    return this.hasMany(GmtKeyword)
  }

  field_sms_keywords_online(): ToManyRelation<GmtKeyword, this> {
    return this.hasMany(GmtKeyword)
  }

  field_documents(): ToManyRelation<GmtFile, this> {
    return this.hasMany(GmtFile)
  }

  field_client_ref(): ToOneRelation<RaasClient, this> {
    return this.hasOne(RaasClient)
  }
}
