import { ReactElement } from "react"
import format from "date-fns/format"
import de from "date-fns/locale/de"

export interface IFormattedDateProps {
  readonly value: Date | null | undefined
  readonly format: string
}

export default function FormattedDate(props: IFormattedDateProps): ReactElement {
  const { value, format: formatString } = props
  return <span>{value ? format(value, formatString, { locale: de }) : ""}</span>
}
