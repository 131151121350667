import { ReactElement } from "react"
import { GmtWinner } from "../../../server/model"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import Stack from "@mui/material/Stack"
import { useStore } from "../../../core"

export interface IWinnerDetailsProps {
  winner: GmtWinner
}

export default function WinnerNoticeBar(props: IWinnerDetailsProps): ReactElement {
  const { winner } = props
  const { notes } = useStore(winner.dataStore)
  return (
    <Stack>
      <TableContainer key={winner.getApiId() + "_notice_bar"} component={Paper}>
        <Table>
          <TableRow>
            <TableBody>
              <TableRow>
                <TableCell>{notes}</TableCell>
              </TableRow>
            </TableBody>
          </TableRow>
        </Table>
      </TableContainer>
    </Stack>
  )
}
