import { Fragment, useMemo } from "react"

import Grid from "@mui/material/Grid"

import { useStore } from "core"
import { CShippingOptions, CShippingOptionsLabels, GmtMaterialPrice, IMaterialPriceData } from "server/model"
import { IInlineEditingProps, StoreSimpleSelect } from "../forms"
import SponsorAutoComplete from "../sponsors/SponsorAutoComplete"

export default function SponsorAndShipping({
  price,
  inlineProps,
  doSave,
  readOnly,
}: {
  readonly price: GmtMaterialPrice
  readonly inlineProps: IInlineEditingProps<Partial<IMaterialPriceData>>
  readonly doSave?: () => Promise<unknown>
  readonly readOnly?: boolean
}): JSX.Element {
  const { dataStore } = price
  const isSponsor = useStore(dataStore, (data) => !!data.sponsor)
  const isSponsorShipping = useStore(
    dataStore,
    ({ shippingOptions }) => shippingOptions === "3" || shippingOptions === "4"
  )
  const disabledShipping = useMemo(() => (isSponsor ? undefined : ["3", "4"]), [isSponsor])

  return (
    <Fragment>
      <Grid item xs={12}>
        <StoreSimpleSelect
          {...inlineProps}
          label="Versand"
          store={dataStore}
          property="shippingOptions"
          list={CShippingOptions}
          disabledOptions={disabledShipping}
          labels={CShippingOptionsLabels}
          fullWidth
          size="small"
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <SponsorAutoComplete model={price} doSave={doSave} readOnly={readOnly} required={isSponsorShipping} />
      </Grid>
    </Fragment>
  )
}
